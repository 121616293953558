import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import NotificationsApi from 'API/modules/Notifications'
// import { AppActions } from 'store/App'
import { InternalErrors } from 'store/types'

import NotificationActions from './actions'

function* fetch() {
  try {
    const notifications = yield call(NotificationsApi.fetch)

    if (notifications.error) {
      yield put(
        NotificationActions.fetchFail({
          error: notifications.error,
        }),
      )

      // yield put(
      //   AppActions.dispatchMessageForUser({
      //     type: notifications.error,
      //     additionalInfo: 'get notifications',
      //   }),
      // )
    } else {
      yield put(NotificationActions.fetchSuccess(notifications))
    }
  } catch (error) {
    yield put(
      NotificationActions.fetchFail({
        error: InternalErrors.SAGA_ERROR,
        meta: { error },
      }),
    )
  }
}

function* markAsRead({ payload }) {
  try {
    const { uuids } = payload
    yield call(NotificationsApi.markAsRead, uuids)
    yield put(NotificationActions.markAsReadSuccess({ uuids }))
  } catch (error) {
    yield put(NotificationActions.markAsReadFail())
  }
}

function* rootSaga() {
  yield takeLatest(NotificationActions.fetch, fetch)
  yield takeEvery(NotificationActions.markAsRead, markAsRead)
}

export default rootSaga
