import { createSelector } from '@reduxjs/toolkit'

import UserSelectors from 'store/User/selectors'

import type { DeviceState } from 'store/Device/types'

const select = (state: any) => state.devices as DeviceState

const selectRegisteredUUID = createSelector(select, (state) => state.registeredUUID)

const selectDevice = createSelector(select, (state) => state.device)

const selectScreen = createSelector(selectDevice, (device) => device?.screen)

const selectScreenCategories = createSelector(selectScreen, (screen) =>
  screen ? screen.default_categories : [],
)

const selectScreenOrderKinds = createSelector(
  [selectScreen, UserSelectors.selectFeatureFlags],
  (screen, featureFlags) =>
    screen
      ? featureFlags.kds_displayed_order_kinds
        ? screen.default_order_kinds.filter(
            (orderKind) => featureFlags.kds_displayed_order_kinds[orderKind],
          )
        : screen.default_order_kinds
      : [],
)

const selectSeenScreenSelection = createSelector(select, (state) => state.seenScreenSelection)

export const DeviceSelectors = {
  selectRegisteredUUID,
  selectSeenScreenSelection,
  selectDevice,
  selectScreen,
  selectScreenCategories,
  selectScreenOrderKinds,
}
