import { createSelector } from '@reduxjs/toolkit'

export const dissectActionType = (type: string) => {
  const [root, name, status] = type.split('/')
  return { actionId: `${root}/${name}`, status }
}

const selectSlice = (state) => state.requestStatuses
const selectFetching = createSelector(selectSlice, (slice) => slice.fetching)
const selectErrors = createSelector(selectSlice, (slice) => slice.errors)

export const selectIsFetching = (state, action) =>
  !!selectFetching(state)[dissectActionType(action.type).actionId]

export const selectIsFetchingById = (state, action, id) => {
  const actionGroup =
    selectFetching(state)[dissectActionType(action.type).actionId]
  return actionGroup ? actionGroup.includes(id) : false
}

export const selectError = (state, action) =>
  selectErrors(state)[dissectActionType(action.type).actionId]
