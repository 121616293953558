import { StyleSheet, View } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import { Text } from 'components'

import Addons from '../Addons'

const TextLine = (props) => {
  const { text, backgroundColor, iconName, style } = props

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          backgroundColor,
          padding: 5,
          borderRadius: 5,
          flexWrap: 'wrap',
        },
        style,
      ]}
    >
      {Boolean(iconName) && (
        <MaterialIcons name={iconName} size={16} style={{ marginRight: 5 }} />
      )}
      <Text numberOfLines={1}>{text}</Text>
    </View>
  )
}

const Items = (props) => {
  const { name, quantity, category, addons, addonsType, specialInstructions } =
    props

  return (
    <View style={styles.container}>
      <View>
        <Text>
          <Text color={colors.text.dark} font={'bold'} size={14}>
            {quantity} - {name}
          </Text>{' '}
          {!!category && (
            <Text color={'#ccc'} font={'bold'} size={12}>
              {category}
            </Text>
          )}
        </Text>
        {addons && <Addons addonsType={addonsType} addons={addons} />}
      </View>
      {!!specialInstructions && (
        <View style={{ marginTop: 12, flexShrink: 1 }}>
          <TextLine backgroundColor={'#ffbec0'} text={specialInstructions} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
    flex: 1,
    justifyContent: 'space-between',
  },
})

export default Items
