import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Snackbar } from 'react-native-paper'
import useMount from 'react-use/lib/useMount'
import { reloadAsync } from 'expo-updates'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import AsyncStorage from '@react-native-async-storage/async-storage'

import Colors from 'config/colors'
import { Button } from 'components/Button'
import Text from 'components/Text'
import { store } from 'store'
import { AuthActions } from 'store/Auth'
import { AnalyticsEvents } from 'util/analytics'
import { track } from 'util/analytics/amplitude'
import { isWeb } from 'util/helpers'
import { useCopyToClipboard } from 'util/hooks'

type Props = {
  errorUUID: string
  resetError?: () => void
}

const CrashScreen = ({ errorUUID, resetError }: Props) => {
  const { t } = useTranslation()
  const { onCopy, hasCopied, setHasCopied } = useCopyToClipboard(errorUUID)

  useMount(() => {
    const eventPayload = AnalyticsEvents.crashScreenEntered()

    if (!eventPayload) return

    track(eventPayload.eventName, eventPayload.eventPayload)
  })

  const reloadApp = async () => {
    isWeb ? window.location.reload() : await reloadAsync()
    resetError()
  }

  const onPressReload = async () => {
    const { eventName, eventPayload } = AnalyticsEvents.fixAppReload()

    track(eventName, eventPayload)

    await reloadApp()
  }

  const onPressClearCacheAndRestart = async () => {
    const { eventName, eventPayload } = AnalyticsEvents.fixAppPressed()

    track(eventName, eventPayload)

    await AsyncStorage.clear()
    store.dispatch(AuthActions.signOut())
    await reloadApp()
  }

  return (
    <>
      <ScrollView
        style={{
          backgroundColor: Colors.main,
        }}
        contentContainerStyle={styles.center}
      >
        <View style={styles.container}>
          <View style={styles.row}>
            <Text style={styles.titleText} id={'crashScreen.title'} />
            <Text style={styles.subTitleText} id={'crashScreen.subtitle'} />
          </View>
          <MaterialCommunityIcons
            name={'robot-dead'}
            size={200}
            color={'white'}
            style={[styles.row, { alignSelf: 'center' }]}
          />
          <View
            style={[
              styles.row,
              { alignSelf: 'stretch', alignItems: 'stretch' },
            ]}
          >
            <Text style={styles.commonText} id={'crashScreen.message1'} />
            <Text style={styles.commonText} id={'crashScreen.message2'} />
            <View style={{ flexDirection: 'row' }}>
              <Button
                onPress={onCopy}
                title={errorUUID}
                iconName={'content-copy'}
                innerColor={Colors.main}
                style={[styles.button, { flex: 1 }]}
                innerStyle={styles.buttonInner}
              />
            </View>
            <Text
              style={[styles.commonText, styles.text2]}
              id={'crashScreen.message3'}
            />
            <View style={styles.buttonContainer}>
              <Button
                onPress={onPressReload}
                style={styles.button}
                innerStyle={styles.buttonInner}
                innerColor={Colors.main}
                iconName={'power'}
                titleId={'crashScreen.buttonRestartLabel'}
              />
              <Button
                onPress={onPressClearCacheAndRestart}
                style={[styles.button, { backgroundColor: Colors.main }]}
                color={Colors.main}
                variant="outline"
                iconName={'wrench-outline'}
                titleId={'crashScreen.buttonClearDataLabel'}
              />
            </View>
          </View>
        </View>
      </ScrollView>
      <Snackbar
        visible={hasCopied}
        duration={Infinity}
        onDismiss={() => setHasCopied(false)}
        style={isWeb ? styles.snackbar : {}}
      >
        {t('crashScreen.alertTextCopied')}
      </Snackbar>
    </>
  )
}

const styles = StyleSheet.create({
  center: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flexGrow: 1,
    justifyContent: 'center',
    maxWidth: 1000,
  },
  row: {
    paddingTop: 20,
    paddingBottom: 40,
    alignItems: 'center',
  },
  titleText: {
    fontSize: 50,
    fontWeight: 'bold',
    color: 'white',
  },
  subTitleText: {
    fontSize: 20,
    color: 'white',
  },
  commonText: {
    fontSize: 18,
    color: 'white',
  },
  text2: {
    marginTop: 15,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: 'white',
    marginTop: 20,
    marginBottom: 40,
  },
  buttonInner: {
    paddingHorizontal: 60,
    paddingVertical: 8,
  },
  snackbar: {
    alignSelf: 'center',
  },
})

export default CrashScreen
