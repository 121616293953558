import { StyleSheet, Switch, View } from 'react-native'

import colors from 'config/colors'
import { HorizontalDivider, Text } from 'components'

type SectionProps = {
  onPressCheckbox?: (value: boolean) => void
  isChecked?: boolean
  isDisabled?: boolean
  subtitle?: string
  subtitleKey?: string
  children?: React.ReactNode
  switchComponent?: React.ReactNode
} & RequireAtLeastOne<{
  title: string
  titleKey: string
}>

export const Section = ({
  title,
  titleKey,
  subtitle,
  subtitleKey,
  onPressCheckbox,
  isChecked,
  isDisabled,
  children,
  switchComponent,
}: SectionProps) => {
  return (
    <View
      style={
        isDisabled ? [styles.section, styles.disabledSection] : styles.section
      }
    >
      <View style={styles.sectionTitleContainer}>
        <Text size={18} font="bold" id={titleKey}>
          {title}
        </Text>
        {switchComponent
          ? switchComponent
          : onPressCheckbox && (
              <Switch
                disabled={isDisabled}
                value={isChecked}
                onValueChange={onPressCheckbox}
              />
            )}
      </View>
      {(subtitle || subtitleKey || children) && (
        <View style={styles.sectionContentContainer}>
          {!!(subtitle || subtitleKey) && (
            <Text style={{ flexWrap: 'wrap', flexShrink: 1 }} id={subtitleKey}>
              {subtitle}
            </Text>
          )}
          {children && <View style={styles.childrenContainer}>{children}</View>}
        </View>
      )}
    </View>
  )
}

export type SectionContainerProps = {
  children?: React.ReactNode
  isDisabled?: boolean
  title?: string
  titleKey?: string
}

export const SectionContainer = ({
  children,
  isDisabled,
  title,
  titleKey,
}: SectionContainerProps) => {
  return (
    <View
      style={
        isDisabled
          ? [styles.sectionContainer, styles.disabledSection]
          : styles.sectionContainer
      }
    >
      {!!(title || titleKey) && (
        <Text size={20} font="bold" id={titleKey} style={styles.sectionTitle}>
          {title}
        </Text>
      )}
      <View style={styles.sectionContent}>{children}</View>
    </View>
  )
}

type SwitchRowProps = {
  onChange: () => void
  isChecked: boolean
} & RequireAtLeastOne<{
  title: string
  titleKey: string
}>

export const SwitchRow = ({
  title,
  titleKey,
  isChecked,
  onChange,
}: SwitchRowProps) => {
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Text
        size={16}
        font={'light'}
        color={colors.text.dark}
        style={{ marginLeft: 5, flexShrink: 1, flexWrap: 'wrap' }}
        id={titleKey}
      >
        {title}
      </Text>
      <Switch value={isChecked} onValueChange={onChange} />
    </View>
  )
}

const styles = StyleSheet.create({
  sectionContainer: {
    marginBottom: 16,
    marginHorizontal: 20,
  },
  sectionContent: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
  },
  section: {},
  sectionTitle: {
    marginBottom: 8,
  },
  disabledSection: {
    opacity: 0.4,
  },
  sectionTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionContentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  childrenContainer: {
    marginLeft: 16,
  },
})
