import { useCallback, useState } from 'react'

export function useDisclosure(defaultIsOpen = false) {
  const [isOpen, setOpen] = useState(defaultIsOpen)

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onToggle = useCallback(() => {
    setOpen((curr) => !curr)
  }, [])

  return {
    isOpen,
    onClose,
    onOpen,
    onToggle,
  }
}

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>
