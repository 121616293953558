import { useSelector } from 'react-redux'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { Manager, ScreenSelection } from 'modules/Device'
import AuthenticatedNavigator from 'navigation/AuthenticatedNavigator'
import { RouteNames } from 'navigation/linkingConfig'
import { DeviceSelectors } from 'store/Device'
import { UserSelectors } from 'store/User'

const DeviceStack = createNativeStackNavigator()

export function DeviceNavigator() {
  const selectedDeviceUUID = useSelector(DeviceSelectors.selectRegisteredUUID)
  const device = useSelector(DeviceSelectors.selectDevice)
  const seenScreenSelection = useSelector(
    DeviceSelectors.selectSeenScreenSelection,
  )
  const hasAvailableScreens = useSelector(
    UserSelectors.selectHasAvailableScreens,
  )

  return (
    <DeviceStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      {!selectedDeviceUUID || !device ? (
        <DeviceStack.Screen
          name={RouteNames.Device.Manager}
          component={Manager}
        />
      ) : !seenScreenSelection && hasAvailableScreens ? (
        <DeviceStack.Screen
          name={RouteNames.Device.SelectScreen}
          component={ScreenSelection}
        />
      ) : (
        <DeviceStack.Screen
          name={RouteNames.Device._}
          component={AuthenticatedNavigator}
        />
      )}
    </DeviceStack.Navigator>
  )
}
