import { isEmpty, sortBy } from 'lodash'
import { createReducer } from '@reduxjs/toolkit'

import { AuthThunks } from 'store/Auth'
import * as AuthActions from 'store/Auth/actions'
import { UserActions } from 'store/User/actions'

import type { Account, UserState } from 'store/User/types'

const initialState: UserState = {
  token: null,
  email: null,
  selectedAccountUUID: null,
  accounts: {},
  _token: null,
  _email: null,
  _accounts: {},
}

export const UserReducer = createReducer(initialState, {
  [UserActions.fetchAccountSuccess.type]: (state, action) => {
    state.accounts[action.payload.uuid] = action.payload
  },
  [UserActions.updateAccount.type]: (state, action) => {
    const { uuid, changes } = action.payload

    if (uuid === state.selectedAccountUUID) {
      for (const key in changes) {
        if (changes.hasOwnProperty(key)) {
          state.accounts[uuid][key] = changes[key]
        }
      }
    }
  },
  [UserActions.selectAccount.type]: (state, action) => {
    if (!action.payload) {
      state.selectedAccountUUID = null
    } else {
      const { uuid } = action.payload as Account

      state.selectedAccountUUID = uuid
      if (!isEmpty({ ...state._accounts })) state.accounts = state._accounts
      if (state._token) state.token = state._token
      if (state._email) state.email = state._email
    }
  },
  // [UserThunks.fetch.fulfilled.type]: () => {},
  [AuthThunks.login.fulfilled.type]: (state, action) => {
    const { response } = action.payload
    const { token, email, accounts } = response

    state._token = token
    state._email = email
    state._accounts = Object.fromEntries(
      sortBy(accounts, (account) => account.name.toLowerCase()).map(
        (account: Account) => [account.uuid, account],
      ),
    )
  },
  [AuthActions.signOut.type]: () => initialState,
})
