import { PureComponent } from 'react'
import { AppState, AppStateStatus } from 'react-native'
import { connect } from 'react-redux'

import { OrderActions } from 'store/Orders'
import { SettingActions } from 'store/Settings'
import { UserActions } from 'store/User'

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

interface State {
  appState: AppStateStatus
}

class StoreManager extends PureComponent<Props, State> {
  state = {
    appState: AppState.currentState,
  }

  changeEventSubscription = null

  componentDidMount() {
    this.changeEventSubscription = AppState.addEventListener(
      'change',
      this.handleAppStateChange,
    )
  }

  componentWillUnmount() {
    if (this.changeEventSubscription) {
      this.changeEventSubscription.remove()
    }
  }

  handleAppStateChange = (nextAppState) => {
    if (
      this.state.appState.match(/inactive|background/) &&
      nextAppState === 'active'
    ) {
      this.updateData()
    }

    this.setState({ appState: nextAppState })
  }

  updateData() {
    this.props.fetchCategories()
    this.props.fetchOrders()
    this.props.fetchUser()
  }

  render() {
    return null
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = {
  fetchCategories: SettingActions.fetch,
  fetchOrders: OrderActions.fetch,
  fetchUser: UserActions.fetchAccount,
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreManager)
