import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'
import { useDispatch } from 'react-redux'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { useNavigationState } from '@react-navigation/native'

import colors from 'config/colors'
import { DEFAULT_SPRING_CONFIG } from 'config/constants'
import { Drawer as CustomDrawer } from 'modules/OverviewScene/Drawer'
import { RouteNames } from 'navigation/linkingConfig'
import { AppActions } from 'store/App'
import { TAB_BARS_LIST } from 'store/App/constants'
import { getRouteName } from 'util/helpers'

import OrderDetails from './OrderDetails'
import {
  OverviewNavigationContextProvider,
  useOverviewNavigationContext,
} from './OverviewNavigationContext'

const Drawer = createDrawerNavigator()

const screenOptions = {
  drawerType: 'permanent' as 'permanent',
  headerShown: false,
  drawerStyle: { width: '30%', minWidth: 330, borderRightWidth: 0 },
  sceneContainerStyle: { backgroundColor: colors.darkBlue },
}

const OverviewNavigatorComponent = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { navigationState } = useOverviewNavigationContext()

  const onIndexChange = (index: number) => {
    dispatch(AppActions.setTabBar(TAB_BARS_LIST[index]))
  }

  return (
    <Drawer.Navigator
      screenOptions={screenOptions}
      drawerContent={(props) => (
        <CustomDrawer
          {...props}
          navigationState={navigationState}
          onIndexChange={onIndexChange}
        />
      )}
    >
      <Drawer.Screen
        name={RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails}
        options={() => ({ title: t('overview.title') })}
        component={OrderDetails}
      />
    </Drawer.Navigator>
  )
}

const OverviewNavigator = () => {
  const routeName = useNavigationState(getRouteName)

  const sharedOpacity = useSharedValue(0)

  useEffect(() => {
    if (routeName === 'overview') {
      sharedOpacity.value = withSpring(1, DEFAULT_SPRING_CONFIG)
    } else {
      sharedOpacity.value = withSpring(0, DEFAULT_SPRING_CONFIG)
    }
  }, [routeName])

  const animatedContainerStyle = useAnimatedStyle(() => {
    return {
      opacity: sharedOpacity.value,
    }
  }, [])

  return (
    <Animated.View style={[animatedContainerStyle, styles.container]}>
      <OverviewNavigationContextProvider>
        <OverviewNavigatorComponent />
      </OverviewNavigationContextProvider>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightBlue2,
    flex: 1,
  },
})

export default OverviewNavigator
