import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import { DEFAULT_ACTIVE_OPACITY } from 'config/constants'
import { Spacer, Text } from 'components'
import { useGetTimeLabel } from 'modules/Notifications/helpers'

import { PriorityConfig, PriorityIcon } from '../PriorityIcon'
import type { CompanyNotification } from 'store/Notifications/types'

export type NotificationProps = {
  notification: CompanyNotification
  onPressFile?: (file: string) => void
  isReadOnly?: boolean
}

export function Notification({ onPressFile, notification }: NotificationProps) {
  const {
    priority,
    created_at: createdAt,
    file_url: file,
    subject,
    message,
  } = notification

  const getTimeLabel = useGetTimeLabel()

  const backgroundColor = PriorityConfig[priority]?.color

  return (
    <View
      style={{
        marginHorizontal: 12,
        marginBottom: 12,
        alignItems: 'stretch',
        borderRadius: 8,
        overflow: 'hidden',
      }}
    >
      <View
        style={{
          gap: 4,
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor,
          padding: 8,
        }}
      >
        <PriorityIcon priority={priority} isSelected />
        <Text font={'medium'} color={colors.white} size={14}>
          {subject}
        </Text>
        <Spacer />
        <Text font={'medium'} color={colors.white} size={14}>
          {getTimeLabel(createdAt)}
        </Text>
      </View>
      <View
        style={{
          borderColor: colors.lightGray,
          borderWidth: StyleSheet.hairlineWidth,
          backgroundColor: colors.white,
          padding: 12,
          gap: 8,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
      >
        <Text size={14} color={colors.text.dark}>
          {message}
        </Text>
        {!!file && (
          <TouchableOpacity
            activeOpacity={DEFAULT_ACTIVE_OPACITY}
            style={{
              borderRadius: 8,
              backgroundColor: colors.veryLightGray,
              padding: 2,
              paddingBottom: 8,
              alignSelf: 'flex-start',
            }}
            accessibilityRole={'link'}
            onPress={() => onPressFile(file)}
          >
            <MaterialCommunityIcons name={'file-pdf-box'} size={72} />
            <Text id={'notifications.open'} align="center" />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}
