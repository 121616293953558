import _ from 'lodash'
import { combineReducers } from 'redux'
import { createSlice } from '@reduxjs/toolkit'

import * as AuthActions from '../Auth/actions'
import OrderActions from './actions'
import { orderItemsAdapter, ordersAdapter } from './entities'
import OrderThunks from './thunks'

const LocalOrderSelectors = ordersAdapter.getSelectors()

const ordersSlice = createSlice({
  name: 'list',
  initialState: ordersAdapter.getInitialState(),
  reducers: {
    addOrders: (state, action) => {
      // fallback for missing entities in normalizr
      const { orders = [] } = action.payload
      ordersAdapter.upsertMany(state, orders)
    },
    updateOrder: (state, action) => {
      const { id, changes } = action.payload
      ordersAdapter.updateOne(state, {
        id,
        changes,
      })
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(OrderActions.fetchSuccess, (state, action) => {
        const { orders = [] } = action.payload
        ordersAdapter.setAll(state, orders)
      })
      .addCase(OrderActions.fetchByIdSuccess, (state, action) => {
        ordersAdapter.upsertOne(state, action.payload.order)
      })
      .addCase(AuthActions.signOut, ordersAdapter.removeAll)
      .addCase(orderItemsSlice.actions.addItems, (state, action) => {
        const { orderUUID, items } = action.payload
        const itemUUIDs = items.map((item) => item.uuid)
        const order = LocalOrderSelectors.selectById(state, orderUUID)

        if (!order) return

        ordersAdapter.updateOne(state, {
          id: orderUUID,
          changes: {
            item_uuids: _.uniq([...order.item_uuids, ...itemUUIDs]),
          },
        })
      })
      .addCase(orderItemsSlice.actions.removeItems, (state, action) => {
        const { orderUUID, itemUUIDs } = action.payload
        const order = LocalOrderSelectors.selectById(state, orderUUID)

        if (!order) return

        ordersAdapter.updateOne(state, {
          id: orderUUID,
          changes: {
            item_uuids: _.without(order.item_uuids, ...itemUUIDs),
          },
        })
      })
      .addCase(OrderThunks.updateItemStatus.fulfilled, (state, action) => {
        if (!action.payload) return

        const { item, uuid: orderUUID, ...orderChanges } = action.payload

        ordersAdapter.updateOne(state, {
          id: orderUUID,
          changes: orderChanges,
        })
      })
      .addCase(OrderThunks.updateItemStatuses.fulfilled, (state, action) => {
        if (!action.payload) return

        const { items, uuid: orderUUID, ...orderChanges } = action.payload

        ordersAdapter.updateOne(state, {
          id: orderUUID,
          changes: orderChanges,
        })
      }),
})

const orderItemsSlice = createSlice({
  name: 'items',
  initialState: orderItemsAdapter.getInitialState(),
  reducers: {
    addItems: (state, action) => {
      const {
        items,
        orderUUID,
      }: { items: PayloadOrderItem[]; orderUUID: PayloadOrder['uuid'] } =
        action.payload

      const enrichedItems: OrderItem[] = items.map((item) => ({
        ...item,
        order_uuid: orderUUID,
      }))
      orderItemsAdapter.addMany(state, enrichedItems)
    },
    removeItems: (state, action) => {
      const { itemUUIDs } = action.payload
      orderItemsAdapter.removeMany(state, itemUUIDs)
    },
    updateItems: (state, action) => {
      const changes = action.payload

      orderItemsAdapter.updateMany(state, changes)
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(OrderActions.fetchSuccess, (state, action) => {
        const { orderItems = [] } = action.payload
        orderItemsAdapter.setAll(state, orderItems)
      })
      .addCase(OrderActions.fetchByIdSuccess, (state, action) => {
        const { orderItems = [] } = action.payload
        orderItemsAdapter.upsertMany(state, orderItems)
      })
      .addCase(ordersSlice.actions.addOrders, (state, action) => {
        const { orderItems = [] } = action.payload
        orderItemsAdapter.addMany(state, orderItems)
      })
      .addCase(OrderThunks.updateItemStatuses.fulfilled, (state, action) => {
        if (!action?.payload?.items) return

        const { items } = action.payload

        orderItemsAdapter.updateMany(
          state,
          items.map(({ uuid, status }) => {
            return {
              id: uuid,
              changes: { status },
            }
          }),
        )
      })
      .addCase(OrderThunks.printItem.fulfilled, (state, action) => {
        const { id } = action.meta.arg
        orderItemsAdapter.updateOne(state, { id, changes: { printed: true } })
      })
      .addCase(OrderThunks.printOrder.fulfilled, (state, action) => {
        const { itemUUIDs } = action.meta.arg
        orderItemsAdapter.updateMany(
          state,
          itemUUIDs.map((id) => ({ id, changes: { printed: true } })),
        )
      })
      .addCase(AuthActions.signOut, orderItemsAdapter.removeAll),
})

const reducer = combineReducers({
  [ordersSlice.name]: ordersSlice.reducer,
  [orderItemsSlice.name]: orderItemsSlice.reducer,
})

export const sliceActions = {
  ...ordersSlice.actions,
  ...orderItemsSlice.actions,
}

export default reducer
