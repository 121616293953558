import { AxiosRequestConfig } from 'axios'

import { executeRequest } from 'API/util/functions'
import { Account } from 'store/User/types'

export type LoginPayload = {
  email?: string
  password?: string
}

export type LoginResponse = {
  accounts: Account[]
  email: string
  token: string
  account?: Account
}

export function login(
  { email, password }: LoginPayload,
  config?: AxiosRequestConfig,
): ApiReturn<LoginResponse> {
  return executeRequest(
    (api) => api.post('/v1/login', { email, password }, config),
    (data) => {
      const { auth_token, accounts } = data

      const returnObject: LoginResponse = {
        accounts,
        email,
        token: auth_token,
      }

      if (accounts.length === 1) {
        returnObject.account = accounts[0]
      }

      return returnObject
    },
  )
}

export type ResetPasswordPayload = {
  email: string
}

export function resetPassword(
  { email }: ResetPasswordPayload,
  config?: AxiosRequestConfig,
) {
  return executeRequest(
    (api) => api.post('/v1/reset_password', { email }, config),
    (data) => {
      return data
    },
  )
}

export type FetchEnvironmentsPayload = RequireAtLeastOne<{
  email: string
  authentication_token: string
}> & {
  account_uuid?: string
}

export async function fetchEnvironments(
  payload?: FetchEnvironmentsPayload,
  config?: AxiosRequestConfig,
) {
  return executeRequest(
    (api) =>
      api.get('https://identity.captain.ai/users', {
        params: payload,
        'axios-retry': {
          retries: 3,
        },
        ...config,
      }),
    (data) => {
      return data
    },
  )
}
