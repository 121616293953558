import { StyleSheet, View } from 'react-native'

import colors from 'config/colors'
import { Button, Modal, ModalCloseButton, Text } from 'components'
import { isWeb } from 'util/helpers'

export type EnvironmentServer = {
  id: string
  name: string
  hostname: string
}

type Props = {
  isVisible?: boolean
  onClose?: () => void
  onConfirm?: (environmentServer: EnvironmentServer) => void
  data?: EnvironmentServer[]
}

export const SelectEnvironmentModal = ({
  isVisible,
  onClose,
  onConfirm = () => {},
  data = [],
}: Props) => {
  return (
    <Modal
      onRequestClose={onClose}
      visible={isVisible}
      contentContainerStyle={
        isWeb
          ? {
              alignSelf: 'center',
            }
          : null
      }
    >
      <View style={styles.container}>
        <Text
          id="authScreen.selectEnvironment.modal.title"
          size={24}
          align="center"
          font="bold"
        />
        <Text
          id="authScreen.selectEnvironment.modal.description"
          size={18}
          color={colors.gray}
          align="center"
          style={{ marginTop: 8 }}
        />
        <View style={styles.content}>
          {data.map((environment) => {
            return (
              <Button
                key={environment.id}
                title={environment.name}
                style={styles.button}
                color={colors.main}
                onPress={() => onConfirm(environment)}
              />
            )
          })}
          {onClose && (
            <ModalCloseButton onPress={onClose} style={styles.closeButton} />
          )}
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  content: {
    marginVertical: 20,
    paddingHorizontal: '10%',
  },
  scroll: {},
  scrollContent: {},
  button: {
    marginBottom: 12,
  },
  closeButton: {
    marginTop: 0,
    alignSelf: 'stretch',
  },
})
