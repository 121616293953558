import { Controller, ControllerProps, FieldValues } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'

import colors from 'config/colors'

import Text from '../Text'

type Props<T extends FieldValues> = {
  label?: string
  labelKey?: string
  errorText?: string
  errorKey?: string
  hintText?: string
} & ControllerProps<T>

export const FormControl = <T extends FieldValues>({
  label,
  labelKey,
  errorText,
  errorKey,
  ...restProps
}: Props<T>) => {
  return (
    <View style={styles.container}>
      {Boolean(label || labelKey) ? (
        <Text
          id={labelKey}
          font="bold"
          color={colors.lightBlue}
          style={styles.label}
          size={16}
        >
          {label}
        </Text>
      ) : null}
      <Controller {...restProps} />
      {errorText || errorKey ? (
        <Text id={errorKey} style={styles.errorText} color={colors.error}>
          {errorText}
        </Text>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'stretch',
    marginVertical: 8,
  },
  label: {
    marginBottom: 8,
  },
  errorText: {
    marginTop: 8,
  },
})
