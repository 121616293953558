import { createAction } from '@reduxjs/toolkit'

import type { Error, Payload } from './types'

const init = createAction('ANALYTICS/INIT/REQUEST')
const initSuccess = createAction('ANALYTICS/INIT/SUCCESS')
const initFail = createAction<Error>('ANALYTICS/INIT/FAIL')

const setUser = createAction('ANALYTICS/SET_USER/REQUEST')
const setUserSuccess = createAction('ANALYTICS/SET_USER/SUCCESS')
const setUserFail = createAction('ANALYTICS/SET_USER/FAIL')

const logEvent = createAction<Payload>('ANALYTICS/LOG_EVENT/REQUEST')
const logEventSuccess = createAction('ANALYTICS/LOG_EVENT/SUCCESS')
const logEventFail = createAction<Error>('ANALYTICS/LOG_EVENT/FAIL')

export default {
  init,
  initSuccess,
  initFail,

  setUser,
  setUserSuccess,
  setUserFail,

  logEvent,
  logEventSuccess,
  logEventFail,
}
