import { ReactNode } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import { Modal as PaperModal, Portal } from 'react-native-paper'

import { CloseButton } from 'components/IconButtons'

export interface ModalProps {
  visible: boolean
  children: ReactNode
  onRequestClose?: () => void
  contentContainerStyle?: ViewStyle
  style?: ViewStyle
  withCloseButton?: boolean
  closeButtonColor?: string
}

export const Modal = ({
  visible,
  children,
  onRequestClose,
  contentContainerStyle,
  style,
  withCloseButton = false,
  closeButtonColor,
}: ModalProps) => {
  return (
    <Portal>
      <PaperModal
        dismissable={Boolean(onRequestClose)}
        visible={visible}
        onDismiss={onRequestClose}
        style={style}
        contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
      >
        {children}
        {withCloseButton && onRequestClose && (
          <CloseButton
            onPress={onRequestClose}
            style={styles.closeButton}
            color={closeButtonColor}
          />
        )}
      </PaperModal>
    </Portal>
  )
}

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  contentContainer: {
    backgroundColor: 'white',
    marginHorizontal: '20%',
    marginVertical: '10%',
    borderRadius: 5,
  },
})
