import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import { normalize } from 'normalizr'

import { orderEntity } from 'API/modules/Orders'
import { useAlertControls } from 'modules/Alerts'
import { AlertTypes } from 'modules/Alerts/constants'
import { AppActions } from 'store/App'
import { useAppDispatch } from 'store/hooks'
import { NotificationActions } from 'store/Notifications'
import { AppNotification } from 'store/Notifications/types'
import { OrderActions } from 'store/Orders'
import { Order, OrderItem } from 'store/Orders/types'
import { UserActions } from 'store/User'
import { Account } from 'store/User/types'

import SoundManager from './SoundManager'

export function useEventHandlers(
  soundManagerRef?: React.MutableRefObject<SoundManager>,
) {
  const dispatch = useAppDispatch()

  const { add: addAlert } = useAlertControls()

  const onOrderChanged = useCallback((data: Order) => {
    const { uuid: id, ...changes } = data

    dispatch(
      OrderActions.updateOrder({
        id,
        changes,
      }),
    )
  }, [])

  const onItemsAdded = useCallback(
    (data: { order_uuid: Order['uuid']; items: Partial<OrderItem>[] }) => {
      const { order_uuid: orderUUID, items } = data

      dispatch(OrderActions.addItems({ orderUUID, items }))
    },
    [],
  )

  const onItemsRemoved = useCallback(
    (data: { order_uuid: Order['uuid']; item_uuids: OrderItem['uuid'][] }) => {
      const { order_uuid: orderUUID, item_uuids: itemUUIDs } = data

      dispatch(OrderActions.removeItems({ orderUUID, itemUUIDs }))
    },
    [],
  )

  const onItemsChanged = useCallback(
    (data: { items: Partial<OrderItem>[] }) => {
      const { items } = data

      const changes = items.map(({ uuid, ...rest }) => ({
        id: uuid,
        changes: rest,
      }))

      dispatch(OrderActions.updateItems(changes))
    },
    [],
  )

  const onAccountChanged = useCallback((data: Partial<Account>) => {
    const { uuid, ...changes } = data

    dispatch(UserActions.updateAccount({ uuid, changes }))
  }, [])

  const onNotificationCreated = useCallback(
    (data: { notification_data: AppNotification }) => {
      const { notification_data } = data

      if (!isEmpty(notification_data.alert_data)) {
        addAlert({
          type: AlertTypes.NOTIFICATION,
          meta: { notification: notification_data },
          options: {
            ...notification_data.alert_data,
          },
        })
      }
      dispatch(NotificationActions.addNotifications([notification_data]))
    },
    [],
  )

  const onNotificationChanged = useCallback(
    (
      data: (Partial<AppNotification> & {
        notification_uuid: AppNotification['uuid']
      })[],
    ) => {
      const updates = data.map((updateObject) => {
        const { notification_uuid, read_at, accepted_at, ...changes } =
          updateObject
        return {
          id: notification_uuid,
          changes: {
            ...changes,
            ...(read_at ? { read_at } : {}),
            ...(accepted_at ? { accepted_at } : {}),
          },
        }
      })
      dispatch(NotificationActions.updateNotifications(updates))
    },
    [],
  )

  const onOrderCreated = useCallback((data: { order_data: string }) => {
    try {
      const orderData = JSON.parse(data.order_data)
      const normalizedData = normalize(orderData, orderEntity)
      const { orders, orderItems } = normalizedData.entities as unknown as {
        orders: Record<string, Order>
        orderItems: Record<string, OrderItem>
      }

      if (soundManagerRef?.current) {
        soundManagerRef.current.showNotification(
          Object.values(orders)[0].description,
        )
      }

      dispatch(OrderActions.addOrders({ orders, orderItems }))
    } catch (e) {
      console.error('pusher:onOrderCreated ', e)
    }
  }, [])

  const handleEvent = useCallback(
    (event: string, data: any) => {
      console.log(event, data)
      dispatch(AppActions.setUpdatedAt())

      switch (event) {
        case 'order_created':
          onOrderCreated(data)
          break
        case 'order_changed':
          onOrderChanged(data)
          break
        case 'items_added':
          onItemsAdded(data)
          break
        case 'items_removed':
          onItemsRemoved(data)
          break
        case 'items_changed':
          onItemsChanged(data)
          break
        case 'account_changed':
          onAccountChanged(data)
          break
        case 'notification_created':
          onNotificationCreated(data)
          break
        case 'notification_changed':
          onNotificationChanged(data)
          break
      }
    },
    [dispatch],
  )

  return handleEvent
}
