import { View } from 'react-native'

import Text from 'components/Text'

type Props = {
  titleKey?: string
}

const CancelledLabel = ({
  titleKey = 'overview.orderDetails.cancelled',
}: Props) => {
  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
      }}
      pointerEvents={'box-none'}
    >
      <Text
        style={{
          transform: [{ rotate: '30deg' }],
        }}
        font={'bold'}
        size={80}
        color={'rgba(199,81,81,0.70)'}
        id={titleKey}
      />
    </View>
  )
}

export default CancelledLabel
