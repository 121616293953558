import { createSlice } from '@reduxjs/toolkit'

import { EnvironmentServer } from 'modules/Auth/components/SelectEnvironmentModal'
import * as AuthThunks from 'store/Auth/thunks'

import * as AuthActions from '../Auth/actions'
import { TAB_BARS_LIST } from './constants'

export interface AppState {
  updatedAt: Date | null
  selectedTabBar: (typeof TAB_BARS_LIST)[number]['value']
  environment: EnvironmentServer | null
  isDrawerMinimized: boolean
  locale: string | null
  errors: string[]
}

const initialState: AppState = {
  updatedAt: null,
  selectedTabBar: TAB_BARS_LIST[0].value,
  environment: null,
  isDrawerMinimized: false,
  locale: null,
  errors: [],
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUpdatedAt: (state) => {
      state.updatedAt = new Date()
    },
    setTabBar: (state, action) => {
      const tabBar = action.payload

      state.selectedTabBar = tabBar.value
    },
    setDrawerSize: (state, action) => {
      state.isDrawerMinimized = action.payload
    },
    setEnvironment: (state, action) => {
      state.environment = action.payload
    },
    setLocale: (state, action) => {
      state.locale = action.payload
    },
    enqueueError: (state, action) => {
      const newErrors = [...state.errors, action.payload]

      state.errors = newErrors.filter(Boolean)
    },
    dequeueError: (state) => {
      state.errors = state.errors.slice(1)
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(AuthThunks.login.fulfilled, (state, action) => {
        const { environment } = action.payload

        state.environment = environment ?? null
      })
      .addCase(AuthActions.signOut, (state, action) => {
        return { ...initialState, locale: state.locale }
      }),
})

export const sliceActions = slice.actions
const reducer = slice.reducer
export default reducer
