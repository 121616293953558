import { AxiosResponse } from 'axios'

import { apiInstance } from 'API/init'

import type { ExtendedAxiosRequestConfig } from 'API/types'
import type { Device } from 'store/Device/types'

async function register(config: ExtendedAxiosRequestConfig): Promise<Device> {
  const response = await apiInstance.post<Device, AxiosResponse<Device>>(
    '/v1/devices',
    null,
    {
      enableErrorHandling: true,
      'axios-retry': {
        retries: 3,
      },
      ...config,
    },
  )

  return response.data
}

async function fetchByUUID(
  uuid: string,
  config: ExtendedAxiosRequestConfig,
): Promise<Device> {
  const response = await apiInstance.get<Device, AxiosResponse<Device>>(
    `/v1/devices/${uuid}`,
    {
      enableErrorHandling: true,
      'axios-retry': {
        retries: 3,
      },
      ...config,
    },
  )

  return response.data
}

async function setScreen(
  deviceUUID: string,
  screenUUID: string,
  config: ExtendedAxiosRequestConfig,
): Promise<Device> {
  const response = await apiInstance.patch<Device, AxiosResponse<Device>>(
    `/v1/devices/${deviceUUID}`,
    { screen_uuid: screenUUID },
    {
      enableErrorHandling: true,
      enableToastHandler: true,
      ...config,
    },
  )
  return response.data
}

export const DevicesAPI = { fetchByUUID, register, setScreen }
