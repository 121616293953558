import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Pusher, { Options as PusherOptions } from 'pusher-js/react-native'

import { SettingSelectors } from 'store/Settings'
import { UserSelectors } from 'store/User'

import SoundManager from './SoundManager'
import { useEventHandlers } from './useEventHandlers'

const PUSHER_OPTIONS: Partial<PusherOptions> = {
  forceTLS: true,
  activityTimeout: 30000,
}

type PusherConfig = {
  key: string
  channel: string
  cluster: string
}

export function PusherManager() {
  const pusherConfig: PusherConfig = useSelector(
    UserSelectors.selectPusherConfig,
  )
  const shouldLoopSound = useSelector(SettingSelectors.selectShouldLoopSound)

  const connectedPusher = useRef<Pusher>(null)
  const soundManager = useRef(null)

  const handleEvent = useEventHandlers(soundManager)

  useEffect(() => {
    if (!pusherConfig) {
      connectedPusher.current?.disconnect()
      connectedPusher.current = null

      return
    }

    const { key, cluster, channel } = pusherConfig
    const previouslyConnectedPusher = connectedPusher.current

    if (
      !previouslyConnectedPusher ||
      previouslyConnectedPusher.key !== key ||
      previouslyConnectedPusher.config.cluster !== cluster
    ) {
      previouslyConnectedPusher?.disconnect()

      connectedPusher.current = new Pusher(key, {
        ...PUSHER_OPTIONS,
        cluster: cluster,
      })
    }

    const currentPusher = connectedPusher.current

    if (!currentPusher.channel(channel)) {
      const channels = currentPusher.allChannels()

      channels.forEach((channel) => {
        currentPusher.unsubscribe(channel.name)
        channel.unbind()
      })

      const pusherChannel = currentPusher.subscribe(channel)
      pusherChannel.bind_global(handleEvent)
    }
  }, [pusherConfig])

  useEffect(() => {
    return () => {
      connectedPusher.current?.disconnect()
    }
  }, [])

  return (
    <SoundManager
      ref={(ref) => (soundManager.current = ref)}
      shouldRepeatSound={shouldLoopSound}
    />
  )
}
