import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Switch, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import useHarmonicIntervalFn from 'react-use/lib/useHarmonicIntervalFn'
import { DateTime } from 'luxon'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useLinkProps, useNavigationState } from '@react-navigation/native'

import colors from 'config/colors'
import { DEFAULT_ACTIVE_OPACITY } from 'config/constants'
import { Text } from 'components'
import { RouteNames } from 'navigation/linkingConfig'
import { DeviceSelectors } from 'store/Device'
import { UserSelectors } from 'store/User'
import { getRouteName, useFormatDateTime } from 'util/helpers'

import { NotificationButton } from './NotificationButton'
import { commonStyles } from './styles'

function MenuRow() {
  const navigateToSettingsProps = useLinkProps({
    to: {
      screen: RouteNames.Home.Settings._,
    },
  })

  const [currentTime, setTime] = useState(new Date())

  const formatDateTime = useFormatDateTime()

  useHarmonicIntervalFn(() => {
    setTime(new Date())
  }, 1000 * 60)

  return (
    <View style={styles.menuRowContainer}>
      <TouchableOpacity
        activeOpacity={DEFAULT_ACTIVE_OPACITY}
        hitSlop={8}
        {...navigateToSettingsProps}
      >
        <MaterialCommunityIcons
          name="cog"
          color="white"
          size={24}
          style={{ padding: 4 }}
        />
      </TouchableOpacity>
      <Text color="white" size={20} font="bold">
        {formatDateTime(currentTime, DateTime.TIME_SIMPLE)}
      </Text>
      <NotificationButton />
    </View>
  )
}

type StoreInfoRowProps = {
  isMinimized?: boolean
}

export function StoreInfoRow({ isMinimized }: StoreInfoRowProps) {
  const hubName = useSelector(UserSelectors.selectHubName)

  return (
    <View style={{ paddingTop: 8 }}>
      <Text
        color={colors.veryLightBlue}
        size={isMinimized ? 16 : 18}
        align="center"
        font="medium"
        numberOfLines={1}
      >
        {hubName}
      </Text>
    </View>
  )
}

type ScreenInfoRowProps = {
  isMinimized?: boolean
}

export function ScreenInfoRow({ isMinimized }: ScreenInfoRowProps) {
  const hasAvailableScreens = useSelector(
    UserSelectors.selectHasAvailableScreens,
  )
  const screen = useSelector(DeviceSelectors.selectScreen)
  const { t } = useTranslation()

  if (!hasAvailableScreens) {
    return null
  }

  return (
    <View style={{ paddingTop: 8 }}>
      <Text
        color={colors.veryLightBlue}
        size={isMinimized ? 12 : 14}
        align="center"
        font="medium"
        numberOfLines={1}
      >
        {screen ? screen.name : t('sideBar.screen.noScreen')}
      </Text>
    </View>
  )
}

function SwitchRow() {
  const routeName = useNavigationState(({ index, routes }) => {
    return getRouteName({ index, routes }, 2)
  })

  const [isOnOverview, setIsOnOverview] = useState(
    routeName !== RouteNames.Home.Main.Drawer.Tabs.Chef._,
  )

  const navigateToChefProps = useLinkProps({
    to: {
      screen: RouteNames.Home.Main.Drawer.Tabs.Chef._,
    },
  })

  const navigateToOverviewProps = useLinkProps({
    to: {
      screen: RouteNames.Home.Main.Drawer.Tabs.Overview._,
    },
  })

  useEffect(() => {
    if (
      routeName === RouteNames.Home.Main.Drawer.Tabs.Overview._ ||
      routeName === RouteNames.Home.Main.Drawer.Tabs.Chef._
    ) {
      setIsOnOverview(routeName === RouteNames.Home.Main.Drawer.Tabs.Overview._)
    }
  }, [routeName])

  return (
    <View style={styles.screenSwitchButton}>
      <MaterialCommunityIcons
        name="view-list"
        color={isOnOverview ? colors.green : colors.blue2}
        size={22}
      />
      <Switch
        value={!isOnOverview}
        onValueChange={(value) =>
          value
            ? navigateToChefProps.onPress()
            : navigateToOverviewProps.onPress()
        }
        trackColor={{
          true: colors.darkBlue,
          false: colors.darkBlue,
        }}
        ios_backgroundColor={colors.darkBlue}
        style={{ marginHorizontal: 5 }}
      />
      <MaterialCommunityIcons
        name="chef-hat"
        color={isOnOverview ? colors.blue2 : colors.green}
        size={22}
      />
    </View>
  )
}

export function MenuSection() {
  return (
    <View style={commonStyles.container}>
      <MenuRow />
      <StoreInfoRow />
      <ScreenInfoRow />
      <SwitchRow />
    </View>
  )
}

const styles = StyleSheet.create({
  menuRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  screenSwitchButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: colors.lightBlue2,
    borderRadius: 999,
    paddingHorizontal: 12,
    height: 40,
    marginTop: 12,
  },
})
