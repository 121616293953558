import * as Sentry from '@sentry/react-native'

import UserSelectors from 'store/User/selectors'

const actionTransformer = (action) => {
  const { type, payload } = action
  return { type }
}

const configureScopeWithState = (scope, state) => {
  const userUUID = UserSelectors.selectAccountUUID(state)

  scope.setUser({ id: userUUID })
}

const stateTransformer = (state) => state

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState,
  actionTransformer,
  stateTransformer,
})

const beforeBreadcrumb = (breadcrumb) => {
  if (breadcrumb.level !== 'warning') {
    return breadcrumb
  }
}

export const captureException = Sentry.captureException
export const captureMessage = Sentry.captureMessage

export const init = () =>
  Sentry.init({
    dsn: 'https://58aec4e644f049c7ab3c5b8f31e75bc1@o104145.ingest.sentry.io/5246552',
    enabled: process.env.NODE_ENV !== 'development',
    beforeBreadcrumb,
    normalizeDepth: 10,
  })
