import { initReactI18next } from 'react-i18next'
import { getLocales } from 'expo-localization'
import i18n from 'i18next'

import 'intl-pluralrules'

import en_US from 'translations/en-US.json'
import fr_CA from 'translations/fr-CA.json'

import { DEFUALT_LOCALE, SUPPORTED_LOCALES, SupportedLocale } from './constants'
import { getSelectedLocale } from './util'

const resources: Record<SupportedLocale, any> = {
  'en-US': en_US,
  'fr-CA': fr_CA,
}

i18n
  .use({
    type: 'languageDetector',
    init: () => {},
    detect: () => {
      return getLocales()[0]?.languageTag ?? DEFUALT_LOCALE
    },
    cacheUserLanguage: () => {},
  })
  .use(initReactI18next)
  .init({
    supportedLngs: SUPPORTED_LOCALES,
    resources: Object.fromEntries(
      Object.entries(resources).map(([key, value]) => {
        return [key, { app: value }]
      }),
    ),
    fallbackLng: (code) => {
      if (!code) return [DEFUALT_LOCALE]

      const foundLocale = getSelectedLocale(code)

      if (foundLocale) {
        return [foundLocale, DEFUALT_LOCALE]
      }

      return [DEFUALT_LOCALE]
    },
    defaultNS: 'app',
    fallbackNS: 'app',
    debug: false,
  })

export default i18n
