import { View } from 'react-native'

import colors from 'config/colors'
import { Spacer, Text } from 'components'

type ListHeaderProps = {
  title: string
  color: string
  children?: React.ReactNode
}

export function ListHeader({ title, color, children }: ListHeaderProps) {
  return (
    <View
      style={{
        paddingHorizontal: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 4,
        minHeight: 72,
      }}
    >
      <Text size={14} color={color ?? colors.text.dark}>
        {title}
      </Text>
      <Spacer />
      {children}
    </View>
  )
}
