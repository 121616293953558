import { StyleSheet, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { Text } from 'components'
import { AddonTypes } from 'store/Orders/enums'
import { isWeb } from 'util/helpers'

import type { OrderItem, PizzaAddon } from 'store/Orders/types'

type CommonProps = {
  fontSize?: number
}

type ColumnProps = {
  labelKey?: string
  labelLeft?: React.ReactNode
  items: string[]
} & CommonProps

const Column = (props: ColumnProps) => {
  const { labelKey, items, labelLeft, fontSize = 14 } = props
  return (
    <View style={styles.container}>
      <View>{labelLeft}</View>
      <View
        style={{
          flexShrink: 1,
        }}
      >
        {!!labelKey && (
          <Text
            size={fontSize * 0.9}
            color={'#dd8c33'}
            font={'bold'}
            id={labelKey}
          />
        )}
        {items.length > 0 ? (
          items.map((item) => (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flexShrink: 1,
              }}
              key={item}
            >
              <MaterialCommunityIcons
                color={'#dd8c33'}
                name={'circle-medium'}
                style={{ marginRight: 5, flexGrow: 0, flexShrink: 0 }}
                size={fontSize}
              />
              <Text
                color={'#6e6d6d'}
                size={fontSize}
                font={'medium'}
                style={{ flexWrap: 'wrap', flexShrink: 1 }}
              >
                {item}
              </Text>
            </View>
          ))
        ) : (
          <MaterialCommunityIcons name={'close'} size={18} color={'#dd8c33'} />
        )}
      </View>
    </View>
  )
}

const PlainAddons = (props: { addons: string[] } & CommonProps) => {
  const { addons, fontSize } = props
  if (addons.length === 0) {
    return null
  }
  return <Column items={addons} fontSize={fontSize} />
}

const Circle = (props) => {
  const { children, color } = props
  return (
    <View
      style={[
        styles.circle,
        {
          backgroundColor: color,
        },
      ]}
    >
      {children}
    </View>
  )
}

const CircleFill = (props: { position: 'left' | 'right' }) => {
  const { position } = props

  return (
    <View
      style={[
        styles.circleFill,
        {
          left: position === 'right' ? 12 : -13,
        },
      ]}
    />
  )
}

const PizzaAddons = (props: { addons: OrderItem['addons'] } & CommonProps) => {
  const { addons, fontSize } = props

  if (Array.isArray(addons) && typeof addons[0] === 'string') {
    return <PlainAddons addons={addons} fontSize={fontSize} />
  }

  const pizzaAddons = addons as PizzaAddon

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 12,
        flexGrow: 1,
        flexShrink: 1,
      }}
    >
      <View style={styles.column}>
        {!!pizzaAddons.whole?.length && (
          <Column
            items={pizzaAddons.whole}
            labelKey={'chef.item.addons.whole'}
            labelLeft={<Circle color={'#d8d8d8'} />}
            fontSize={fontSize}
          />
        )}
      </View>
      <View style={styles.column}>
        {!!pizzaAddons.left?.length && (
          <Column
            items={pizzaAddons.left}
            labelKey={'chef.item.addons.left'}
            labelLeft={
              <Circle color={'#fff'}>
                <CircleFill position={'left'} />
              </Circle>
            }
            fontSize={fontSize}
          />
        )}
      </View>
      <View style={styles.column}>
        {!!pizzaAddons.right?.length && (
          <Column
            items={pizzaAddons.right}
            labelKey={'chef.item.addons.right'}
            labelLeft={
              <Circle color={'#fff'}>
                <CircleFill position={'right'} />
              </Circle>
            }
            fontSize={fontSize}
          />
        )}
      </View>
    </View>
  )
}

// const TEMP_examplePizzaAddon = {
//   whole: ['garlic', 'pineapples', 'mussels'],
//   left: ['mayonnaise'],
//   right: ['tomatoes', 'spinach'],
// }

interface Props extends Pick<OrderItem, 'addons'> {
  addonsType?: OrderItem['addons_type']
  fontSize?: number
}

const Addons = (props: Props) => {
  const { addonsType, addons, fontSize } = props

  switch (addonsType) {
    case AddonTypes.PIZZA: {
      return <PizzaAddons addons={addons} fontSize={fontSize} />
    }
    default: {
      return <PlainAddons addons={addons as string[]} fontSize={fontSize} />
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexGrow: 1,
    marginTop: 8,
    minWidth: 150,
  },
  column: {
    minWidth: 150,
    ...(isWeb
      ? {
          flex: 1,
        }
      : {
          flexGrow: 1,
          flexShrink: 1,
        }),
  },
  circle: {
    borderColor: '#a6a6a6',
    borderWidth: 1,
    width: 25,
    height: 25,
    borderRadius: 13,
    marginRight: 5,
    overflow: 'hidden',
  },
  circleFill: {
    borderColor: '#a6a6a6',
    borderRightWidth: 1,
    borderLeftWidth: 1,
    width: 25,
    height: 25,
    backgroundColor: '#d8d8d8',
  },
})

export default Addons
