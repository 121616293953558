import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'

import { Text } from 'components'
import { AppSelectors } from 'store/App'
import { useFormatDateTime } from 'util/helpers'

export const LastUpdatedIndicator = () => {
  const { t } = useTranslation()

  const updatedAt = useSelector(AppSelectors.selectUpdatedAt)
  const isDrawerMinimized = useSelector(AppSelectors.selectIsDrawerMinimized)

  const formatDateTime = useFormatDateTime()

  return (
    <View style={styles.container}>
      <Text color="white" align="center">
        {t(
          !isDrawerMinimized
            ? 'sideBar.infoSection.updatedAt'
            : 'sideBar.infoSection.updatedAt_short',
          {
            dateTime: updatedAt
              ? formatDateTime(updatedAt, DateTime.TIME_SIMPLE)
              : 'N/A',
          },
        )}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    alignItems: 'center',
  },
})
