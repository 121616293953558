import { ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Constants from 'expo-constants'

import { CaptainLogo, DoubleSpacer, Spacer, Text } from 'components'
import { LanguageSelector } from 'components/LanguageSelector'

import { AuthBackground } from '../AuthBackground'

type Props = {
  children: ReactNode
  maxWidth?: number
}

export const AuthLayout = ({ children, maxWidth = 480 }: Props) => {
  return (
    <AuthBackground>
      <KeyboardAwareScrollView
        enableOnAndroid
        contentContainerStyle={styles.scrollView}
        extraScrollHeight={100}
      >
        <Spacer />
        <CaptainLogo />
        <View style={[styles.formContainer, { maxWidth }]}>{children}</View>
        <View style={styles.languageSelectorContainer}>
          <LanguageSelector style={styles.languageSelector} />
        </View>
        <DoubleSpacer />
        <Text align="center" color="white" style={styles.versionText}>
          {Constants.expoConfig.name} - {Constants.expoConfig?.version}
        </Text>
      </KeyboardAwareScrollView>
    </AuthBackground>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    alignItems: 'center',
    flexGrow: 1,
  },
  versionText: {
    marginTop: 20,
  },
  formContainer: {
    width: '100%',
    backgroundColor: 'white',
    padding: 32,
    borderRadius: 8,
  },
  languageSelectorContainer: {
    marginVertical: 8,
    minWidth: 200,
    flexGrow: 0,
    flexShrink: 0,
  },
  languageSelector: {
    backgroundColor: 'white',
  },
})
