import { ReactNode } from 'react'
import { View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import { Text } from 'components'

export const NoContentPlaceholder = () => {
  return (
    <Text size={14} font={'bold'} color={colors.text.dark}>
      ...
    </Text>
  )
}

export const Arrow = () => {
  return (
    <MaterialCommunityIcons
      name={'arrow-right'}
      size={24}
      color={colors.text.dark}
      style={{ marginHorizontal: 10 }}
    />
  )
}

interface ChangeProps {
  titleKey: string
  isRenderingChange?: boolean
  current?: any
  previous?: any
  children?: ReactNode
  renderItem?: (value: any) => ReactNode
}

export const Change = (props: ChangeProps) => {
  const {
    titleKey,
    previous,
    current,
    renderItem,
    children,
    isRenderingChange = true,
  } = props

  return (
    <View
      style={{
        marginBottom: 8,
        paddingVertical: 8,
      }}
    >
      <Text
        id={titleKey}
        size={12}
        font={'bold'}
        // style={{ textDecorationLine: 'underline' }}
        color={colors.text.dark}
      />
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 5,
          marginLeft: 20,
          flexWrap: 'wrap',
        }}
      >
        {isRenderingChange ? (
          <>
            {previous ? renderItem(previous) : <NoContentPlaceholder />}
            <Arrow />
            {current ? renderItem(current) : <NoContentPlaceholder />}
          </>
        ) : (
          children
        )}
      </View>
    </View>
  )
}
