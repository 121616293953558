import i18n from 'i18next'
import {
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
} from '@redux-saga/core/effects'

import {
  convertHostNameToBaseUrl,
  setAuthenticationHeaders,
  setBaseURL,
  setDeviceHeaders,
} from 'API/util/functions'
import AnalyticsActions from 'store/Analytics/actions'
import AppActions from 'store/App/actions'
import { setLocale } from 'store/App/sagas'
import AppSelectors from 'store/App/selectors'
import * as AuthActions from 'store/Auth/actions'
import { DeviceSelectors, DeviceThunks } from 'store/Device'
import NotificationActions from 'store/Notifications/actions'
import OrderActions from 'store/Orders/actions'
import SettingActions from 'store/Settings/actions'
import { UserActions } from 'store/User'
import UserSelectors from 'store/User/selectors'
// import * as UserThunks from 'store/User/thunks'
import { reset as amplitudeReset } from 'util/analytics/amplitude'
import { SUPPORTED_LOCALES } from 'util/localization'

export function* onAuthenticated() {
  const [email, token, accountUUID, deviceUUID] = yield all([
    select(UserSelectors.selectEmail),
    select(UserSelectors.selectToken),
    select(UserSelectors.selectAccountUUID),
    select(DeviceSelectors.selectRegisteredUUID),
  ])

  if (deviceUUID) {
    setDeviceHeaders(deviceUUID)
  }

  if (email && token && accountUUID) {
    setAuthenticationHeaders(email, token, accountUUID)
    yield put(UserActions.fetchAccount())
    // yield put(yield call(UserThunks.fetch))
    yield put(SettingActions.fetch())
    yield put(AnalyticsActions.init())

    yield userTask()
  }
}

export function* userTask() {
  yield take([
    DeviceThunks.fetchCurrent.fulfilled.type,
    DeviceThunks.register.fulfilled.type,
  ])

  const pollingTask = yield fork(pollingSaga)
  yield take([AuthActions.signOut, UserActions.selectAccount])

  yield cancel(pollingTask)
  amplitudeReset()
}

function* pollingSaga() {
  try {
    while (true) {
      yield put(OrderActions.fetch())
      yield put(NotificationActions.fetch())
      yield delay(1000 * 60)
    }
  } finally {
    if (yield cancelled()) {
      // polling stopped
    }
  }
}

export function* onStart() {
  const [isAuthenticated, account, locale] = yield all([
    select(UserSelectors.selectIsAuthenticated),
    select(UserSelectors.selectAccount),
    select(AppSelectors.selectLocale),
  ])

  if (SUPPORTED_LOCALES.includes(locale)) {
    yield call(setLocale)
  } else {
    yield put(AppActions.setLocale(i18n.resolvedLanguage))
  }

  if (isAuthenticated && account) {
    const environment = yield select(AppSelectors.selectEnvironment)

    if (environment) {
      setBaseURL(convertHostNameToBaseUrl(environment.hostname))
    }

    yield all([onAuthenticated()])
  }
}

function* onError({ payload }) {
  const { error } = payload
}
// 'Error while processing your request...'
export function* ErrorSaga() {
  const isFailedAction = (action) => {
    return /.*\/FAILURE$/.test(action.type)
  }
  yield takeEvery(isFailedAction, onError)
}
