import { StyleSheet } from 'react-native'

import Colors from 'config/colors'
import { FontFamilies } from 'components'

export const styles = StyleSheet.create({
  labelContainer: {
    overflow: 'hidden',
    flex: 1,
  },
  titleLabel: {
    fontSize: 14,
    fontFamily: FontFamilies.medium,
  },
  descLabel: {
    color: Colors.text.dark,
    fontFamily: FontFamilies.normal,
  },
})
