import { useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Snackbar } from 'react-native-paper'
import Color from 'color'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import { Text } from 'components'
import { useAppDispatch } from 'store/hooks'
import { OrderThunks } from 'store/Orders'

import { styles } from './styles'
import type { Order, OrderItem } from 'store/Orders/types'

interface ReprintButtonProps {
  error?: string | null
  setError?: React.Dispatch<React.SetStateAction<ReprintButtonProps['error']>>
  status?: boolean
  setStatus?: React.Dispatch<React.SetStateAction<ReprintButtonProps['status']>>
  onPress?: () => void | Promise<void>
}

function ReprintButton({
  onPress,
  error,
  setError,
  status,
  setStatus,
}: ReprintButtonProps) {
  useEffect(() => {
    if (status) {
      const timer = setTimeout(() => {
        setStatus(false)
      }, 2000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [status])

  const accentColor = Color(colors.ui.statuses.prepared).darken(0.3).hex()

  return (
    <>
      <TouchableOpacity
        onPress={(e) => {
          e.stopPropagation()
          e.preventDefault()

          if (!status) {
            onPress()
          }
        }}
        activeOpacity={status ? 0.4 : 0.75}
        style={[styles.container, status ? { opacity: 0.4 } : {}]}
      >
        {status ? (
          <MaterialCommunityIcons
            name="printer-check"
            color={accentColor}
            size={40}
          />
        ) : (
          <View>
            <MaterialCommunityIcons
              name="restore"
              color={accentColor}
              size={50}
            />
            <MaterialCommunityIcons
              name="printer"
              color={accentColor}
              size={20}
              style={{
                position: 'absolute',
                top: 15,
                left: 17,
              }}
            />
          </View>
        )}
        <Text
          font={'bold'}
          color={'#888888'}
          id={
            status
              ? 'common.actionButton.printing'
              : 'common.actionButton.reprint'
          }
          size={12}
          align="center"
        />
      </TouchableOpacity>
      <Snackbar
        visible={Boolean(error)}
        duration={5000}
        onDismiss={() => setError(null)}
        style={styles.snackbar}
      >
        <Text
          id={`errors.${error}`}
          numberOfLines={3}
          style={styles.snackbarText}
        />
      </Snackbar>
    </>
  )
}

type ReprintButtonItemProps = ReprintButtonProps & {
  itemUUID: OrderItem['uuid']
}

export const ReprintButtonItem = ({
  itemUUID,
  onPress: onPressProp,
}: ReprintButtonItemProps) => {
  const dispatch = useAppDispatch()

  const [status, setStatus] = useState(false)
  const [error, setError] = useState(null)

  const onPress = async () => {
    setStatus(true)

    const { payload } = await dispatch(
      OrderThunks.printItem({
        id: itemUUID,
      }),
    )

    if (payload.error) {
      setError(payload.error)
    }

    onPressProp && onPressProp()
  }

  return (
    <ReprintButton
      onPress={onPress}
      status={status}
      setStatus={setStatus}
      error={error}
      setError={setError}
    />
  )
}

type ReprintButtonOrderProps = ReprintButtonProps & {
  order: Order
  itemUUIDs: OrderItem['uuid'][]
}

export const ReprintButtonOrder = ({
  order,
  itemUUIDs,
  onPress: onPressProp,
}: ReprintButtonOrderProps) => {
  const dispatch = useAppDispatch()

  const [status, setStatus] = useState(false)
  const [error, setError] = useState(null)

  const onPress = async () => {
    setStatus(true)

    const { payload } = await dispatch(
      OrderThunks.printOrder({
        id: order.uuid,
        itemUUIDs,
      }),
    )

    if (payload.error) {
      setError(payload.error)
    }

    onPressProp && onPressProp()
  }

  return (
    <ReprintButton
      onPress={onPress}
      status={status}
      setStatus={setStatus}
      error={error}
      setError={setError}
    />
  )
}
