import { Platform, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { isWeb } from 'util/helpers'

import Text from './Text'

const PullToRefresh = (props) => {
  const { style } = props
  return (
    <View style={[{ alignItems: 'center', alignSelf: 'center' }, style]}>
      <MaterialCommunityIcons
        name={isWeb ? 'refresh' : 'gesture-swipe-down'}
        size={28}
        color="white"
      />
      <Text
        color="white"
        size={18}
        font="medium"
        align="center"
        style={{ marginTop: 8 }}
        id={
          isWeb
            ? 'components.pullToRefresh.messageWeb'
            : 'components.pullToRefresh.messageNative'
        }
      />
    </View>
  )
}

export default PullToRefresh
