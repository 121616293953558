import { useCallback } from 'react'
import i18n from 'i18next'
import { DateTime } from 'luxon'
import { useLinkProps } from '@react-navigation/native'

import { RouteNames } from 'navigation/linkingConfig'
import {
  NotificationPriority,
  NotificationTypes,
} from 'store/Notifications/enums'
import {
  AppNotification,
  ChangeableItemFields,
  ChangeableOrderFields,
} from 'store/Notifications/types'
import { useFormatDateTime } from 'util/helpers'

export const useGetTimeLabel = () => {
  const formatDateTime = useFormatDateTime()

  return useCallback(
    (createdAt: string) => {
      const dtCreatedAt = DateTime.fromISO(createdAt)
      const isSameDay =
        dtCreatedAt > DateTime.now().startOf('day') &&
        dtCreatedAt < DateTime.now().endOf('day')

      if (isSameDay) {
        const minutesSince = DateTime.now()
          .diff(dtCreatedAt)
          .shiftTo('minutes').minutes

        if (minutesSince < 60) {
          const displayValue = Math.trunc(minutesSince)
          return displayValue > 0
            ? i18n.t('notifications.item.minutesAgo', {
                value: displayValue,
              })
            : i18n.t('notifications.item.justNow')
        }
        return formatDateTime(createdAt, DateTime.TIME_SIMPLE)
      } else {
        return formatDateTime(createdAt, DateTime.DATETIME_MED)
      }
    },
    [formatDateTime],
  )
}

export const getLabelKeyForNotificationType = (notificationType) => {
  switch (notificationType) {
    case NotificationTypes.ORDER_CHANGED: {
      return 'notifications.labels.orderChanged'
    }
    case NotificationTypes.ORDER_CANCELLED: {
      return 'notifications.labels.orderCancelled'
    }
    case NotificationTypes.ITEM_CHANGED: {
      return 'notifications.labels.itemChanged'
    }
    case NotificationTypes.ITEM_ADDED: {
      return 'notifications.labels.itemAdded'
    }
    case NotificationTypes.ITEM_REMOVED: {
      return 'notifications.labels.itemRemoved'
    }
    case NotificationTypes.COMPANY: {
      return 'notifications.labels.storeMessage'
    }
  }
}

export function getLabelKeyForNotificationMetaKey(
  metaKey: ChangeableItemFields | ChangeableOrderFields,
): string {
  switch (metaKey) {
    case 'internal_notes': {
      return 'notifications.metaLabels.internalNotes'
    }
    case 'special_instructions': {
      return 'notifications.metaLabels.specialInstructions'
    }
    case 'kind': {
      return 'notifications.metaLabels.kind'
    }
    case 'addons': {
      return 'notifications.metaLabels.addons'
    }
    case 'scheduled_for': {
      return 'notifications.metaLabels.scheduledFor'
    }
    case 'quantity': {
      return 'notifications.metaLabels.quantity'
    }
  }
}

export function getClarificationStringForNotificationMeta(
  meta: AppNotification['meta'],
): string {
  const orderMetaKeys: ChangeableOrderFields[] = [
    'special_instructions',
    'internal_notes',
    'scheduled_for',
    'kind',
  ]
  const itemMetaKeys: ChangeableItemFields[] = [
    'special_instructions',
    'addons',
    'quantity',
  ]

  const metaKeys = Object.keys(meta).filter(
    (key) =>
      meta.hasOwnProperty(key) &&
      [...orderMetaKeys, ...itemMetaKeys].includes(key),
  )

  return metaKeys
    .map((metaKey) => i18n.t(getLabelKeyForNotificationMetaKey(metaKey)))
    .join(', ')
}

export const useNotificationLinkProps = (
  orderUUID: string,
  itemUUID: string,
  options: {
    onPressCallback?: () => void
  } = {},
) => {
  const { onPressCallback } = options

  const navigateToOrderProps = useLinkProps({
    to: {
      screen: RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails,
      params: {
        uuid: orderUUID,
      },
    },
  })

  const navigateToItemProps = useLinkProps({
    to: {
      screen: RouteNames.Home.Main.ItemDetails,
      params: {
        uuid: itemUUID,
      },
    },
  })

  const navigationProps = !itemUUID ? navigateToOrderProps : navigateToItemProps

  return {
    accessibilityRole: navigationProps.accessibilityRole,
    onPress: (event) => {
      onPressCallback && onPressCallback()
      navigationProps.onPress(event)
    },
  }
}
