import _ from 'lodash'
import { DateTime } from 'luxon'
import { createSelector } from '@reduxjs/toolkit'

// nasty require cycle
import { SortingOrders } from 'config/constants'
import {
  ItemPreparationStatuses,
  OrderInternalStatuses,
  OrderPreparationStatuses,
  OrderStatuses,
} from 'store/Orders/enums'
import SettingSelectors from 'store/Settings/selectors'

import { orderItemsAdapter, ordersAdapter } from './entities'
import { OrderItem } from './types'
import {
  checkIfScheduledOrder,
  getOrderInternalStatus,
  sortByOrderInternalStatus,
  sortByStartPreparationStatus,
} from './utils'

const orderEntitySelectors = ordersAdapter.getSelectors(
  // @ts-ignore
  (state) => state.orders.list,
)
const orderItemEntitySelectors = orderItemsAdapter.getSelectors(
  // @ts-ignore
  (state) => state.orders.items,
)

const { selectAll, selectIds, selectById, selectEntities } = orderEntitySelectors
const {
  selectAll: selectAllItems,
  selectEntities: selectItemEntities,
  selectById: selectItemById,
} = orderItemEntitySelectors

const selectAllEntities = createSelector(selectAll, (orders) => _.keyBy(orders, 'uuid'))

type selectItemsForOrder = (state: any, uuid: string) => OrderItem[]
const selectItemsForOrder: selectItemsForOrder = createSelector(
  [selectEntities, selectItemEntities, (state, orderId) => orderId],
  (orders, orderItems, orderId) => {
    return orders[orderId].item_uuids.map((itemId) => orderItems[itemId])
  },
)

const selectItemsForOrderWithCategories: selectItemsForOrder = createSelector(
  [
    selectItemsForOrder,
    SettingSelectors.selectSelectedCategories,
    SettingSelectors.selectIsSelectedAllCategories,
    (state, orderId) => orderId,
  ],
  (items, selectedCategories, isSelectedAllCategories) => {
    return isSelectedAllCategories
      ? items
      : items.filter((item) => selectedCategories.includes(item.category))
  },
)

const selectWithCategories = createSelector(
  [selectAll, selectItemEntities],
  (orders, itemsById) => {
    return orders.map((order) => ({
      ...order,
      categories: _.uniq(order.item_uuids.map((itemUUID) => itemsById[itemUUID]?.category)),
    }))
  },
)

const selectForCategories = createSelector(
  [
    selectWithCategories,
    SettingSelectors.selectSelectedCategories,
    SettingSelectors.selectIsSelectedAllCategories,
  ],
  (orders, selectedCategories, isSelectedAllCategories) =>
    isSelectedAllCategories
      ? orders
      : orders.filter((order) => _.intersection(order.categories, selectedCategories).length > 0),
)

const selectForOrderKinds = createSelector(
  [
    selectForCategories,
    SettingSelectors.selectSelectedOrderKinds,
    SettingSelectors.selectIsSelectedAllOrderKinds,
  ],
  (orders, selectedOrderKinds, isSelectedAllOrderKinds) => {
    return isSelectedAllOrderKinds
      ? orders
      : orders.filter((order) => selectedOrderKinds.includes(order.kind))
  },
)

const selectAllSorted = createSelector(
  [
    selectForOrderKinds,
    SettingSelectors.selectOrdersSortingOrder,
    (state) => (orderId) => selectItemsForOrderWithCategories(state, orderId),
  ],
  (orders, sortingOrder, orderItemsById) => {
    switch (sortingOrder) {
      case SortingOrders.BY_SCHEDULED_FOR_ASC: {
        return _.sortBy(orders, 'scheduled_for')
      }
      case SortingOrders.BY_PROMISED_TIME_AT_ASC: {
        return _.sortBy(orders, 'promised_time_at')
      }
      case SortingOrders.BY_START_PREPARATION_STATUS_DESC: {
        return _.sortBy(orders, 'latest_prep_time')
          .sort((orderA, orderB) =>
            sortByOrderInternalStatus(
              { order: orderA, items: orderItemsById(orderA.uuid) },
              { order: orderB, items: orderItemsById(orderB.uuid) },
            ),
          )
          .sort(sortByStartPreparationStatus)
      }
      case SortingOrders.BY_PLACED_AT_ASC: {
        return _.sortBy(orders, 'created_at')
      }
      default: {
        return orders
      }
    }
  },
)

const selectGrouped = createSelector(
  [selectAllSorted, (state) => (orderId) => selectItemsForOrderWithCategories(state, orderId)],
  (orders, orderItemsById) => {
    const ordersGrouped = orders.reduce(
      (result, order) => {
        const items = orderItemsById(order.uuid)
        const orderStatus = getOrderInternalStatus(order, items)

        if (orderStatus === OrderInternalStatuses.CANCELLED) {
          return {
            ...result,
            prepared: [...result.prepared, order],
          }
        }
        if (orderStatus === OrderInternalStatuses.PREPARED) {
          return {
            ...result,
            prepared: [...result.prepared, order],
          }
        }
        if (orderStatus === OrderInternalStatuses.BEING_PREPARED) {
          return {
            ...result,
            beingPrepared: [...result.beingPrepared, order],
          }
        }
        if (orderStatus === OrderInternalStatuses.SCHEDULED) {
          return {
            ...result,
            scheduled: [...result.scheduled, order],
          }
        }
        if (orderStatus === OrderInternalStatuses.NOT_BEING_PREPARED) {
          return {
            ...result,
            notBeingPrepared: [...result.notBeingPrepared, order],
          }
        }

        return result
      },
      {
        notBeingPrepared: [],
        beingPrepared: [],
        scheduled: [],
        prepared: [],
      },
    )
    return ordersGrouped
  },
)

const selectNotBeingPrepared = createSelector(selectGrouped, (grouped) => grouped.notBeingPrepared)
const selectNotBeingPreparedCount = createSelector(
  selectNotBeingPrepared,
  (notBeingPrepared) => notBeingPrepared.length,
)

const selectBeingPrepared = createSelector(selectGrouped, (grouped) => grouped.beingPrepared)
const selectBeingPreparedCount = createSelector(
  selectBeingPrepared,
  (beingPrepared) => beingPrepared.length,
)

const selectPrepared = createSelector(selectGrouped, (grouped) => grouped.prepared)
const selectPreparedCount = createSelector(selectPrepared, (prepared) => prepared.length)

const selectScheduled = createSelector(selectGrouped, (grouped) => grouped.scheduled)
const selectScheduledCount = createSelector(selectScheduled, (scheduled) => scheduled.length)

const selectScheduledGrouped = createSelector(selectScheduled, (orders) => {
  const todayKey = DateTime.now().toLocaleString(DateTime.DATE_SHORT)
  const grouped = _.groupBy(orders, (order) =>
    DateTime.fromISO(order.scheduled_for).toLocaleString(DateTime.DATE_SHORT),
  )

  let today = []
  const rest = []

  for (const key in grouped) {
    if (key === todayKey) {
      today = grouped[key]
    } else {
      rest.push({
        date: grouped[key][0].scheduled_for,
        items: grouped[key],
      })
    }
  }

  return [today, _.sortBy(rest, 'date')]
})

const selectItemsGrouped = createSelector(
  [
    selectAllSorted,
    selectItemEntities,
    SettingSelectors.selectSelectedCategories,
    SettingSelectors.selectIsSelectedAllCategories,
  ],
  (orders, itemEntities, selectedCategories, isSelectedAllCategories) => {
    return orders.reduce(
      (result, order) => {
        const isScheduledFutureOrder = checkIfScheduledOrder(order)

        const scheduledForValue = DateTime.fromISO(order.scheduled_for).toLocaleString(
          DateTime.DATE_SHORT,
        )

        const orderItems = order.item_uuids.map((itemId) => itemEntities[itemId])
        const filteredOrderItems = isSelectedAllCategories
          ? orderItems
          : orderItems.filter((item) => selectedCategories.includes(item.category))

        const itemsGrouped = filteredOrderItems.reduce(
          (resultItems, item) => {
            if (order.order_status === OrderStatuses.CANCELLED) {
              return { ...resultItems, prepared: [...resultItems.prepared, item] }
            }
            if (item.status === ItemPreparationStatuses.PREPARED) {
              return { ...resultItems, prepared: [...resultItems.prepared, item] }
            }
            if (item.status === ItemPreparationStatuses.BEING_PREPARED) {
              return { ...resultItems, beingPrepared: [...resultItems.beingPrepared, item] }
            }
            if (isScheduledFutureOrder) {
              return { ...resultItems, scheduled: [...resultItems.scheduled, item] }
            }
            return { ...resultItems, notBeingPrepared: [...resultItems.notBeingPrepared, item] }
          },
          {
            notBeingPrepared: [],
            beingPrepared: [],
            scheduled: [],
            prepared: [],
          },
        )

        // scheduledForValue !== null, but there's no items. todo
        return {
          notBeingPrepared: [...result.notBeingPrepared, ...itemsGrouped.notBeingPrepared],
          beingPrepared: [...result.beingPrepared, ...itemsGrouped.beingPrepared],
          scheduled: isScheduledFutureOrder
            ? {
                ...result.scheduled,
                [scheduledForValue]: [
                  ...(result.scheduled[scheduledForValue] || []),
                  ...itemsGrouped.scheduled,
                ],
              }
            : result.scheduled,
          prepared: [...result.prepared, ...itemsGrouped.prepared],
        }
      },
      {
        notBeingPrepared: [],
        beingPrepared: [],
        scheduled: {},
        prepared: [],
      },
    )
  },
)

const selectNotBeingPreparedItems = createSelector(
  selectItemsGrouped,
  (grouped) => grouped.notBeingPrepared,
)
const selectPreparedItems = createSelector(selectItemsGrouped, (grouped) => grouped.prepared)
const selectBeingPreparedItems = createSelector(
  selectItemsGrouped,
  (grouped) => grouped.beingPrepared,
)
const selectScheduledItems = createSelector(selectItemsGrouped, (grouped) => grouped.scheduled)

const selectScheduledItemsGrouped = createSelector(selectScheduledItems, (groupedItems) => {
  const todayKey = DateTime.now().toLocaleString(DateTime.DATE_SHORT)

  let today = []
  const rest = []

  for (const key in groupedItems) {
    if (key === todayKey) {
      today = groupedItems[key]
    } else {
      rest.push({
        date: key,
        items: groupedItems[key],
      })
    }
  }

  return [today, _.sortBy(rest, 'date')]
})

const selectScheduledItemsCount = createSelector(selectScheduledItems, (itemsByDate) => {
  return _.sumBy(_.flatten(Object.values(itemsByDate)), 'quantity')
})

const selectNotPreparedItemsCount = createSelector(selectNotBeingPreparedItems, (items) =>
  _.sumBy(items, 'quantity'),
)

const selectBeingPreparedItemsCount = createSelector(selectBeingPreparedItems, (items) =>
  _.sumBy(items, 'quantity'),
)

const selectPreparedItemsCount = createSelector(selectPreparedItems, (items) =>
  _.sumBy(items, 'quantity'),
)

const selectItemsCountByCategory = createSelector(
  [selectAll, selectItemEntities],
  (activeOrders, itemsById) => {
    const itemUUIDs = activeOrders.reduce(
      (result, order) =>
        [
          OrderPreparationStatuses.NOT_BEING_PREPARED,
          OrderPreparationStatuses.BEING_PREPARED,
        ].includes(order.status)
          ? [...result, ...order.item_uuids]
          : result,
      [],
    )

    const itemCountByCategories = itemUUIDs.reduce((result, uuid) => {
      const item = itemsById[uuid]

      const previousValue = result[item.category] ?? 0

      return item?.status === ItemPreparationStatuses.NOT_BEING_PREPARED
        ? { ...result, [item.category]: previousValue + item.quantity }
        : result
    }, {})

    return itemCountByCategories
  },
)

const selectItemsCountByOrderKind = createSelector(
  [selectAll, selectItemEntities, selectEntities],
  (activeOrders, itemsById, ordersById) => {
    const itemUUIDs = activeOrders.reduce(
      (result, order) =>
        [
          OrderPreparationStatuses.NOT_BEING_PREPARED,
          OrderPreparationStatuses.BEING_PREPARED,
        ].includes(order.status)
          ? [...result, ...order.item_uuids]
          : result,
      [],
    )

    const itemCountByCategories = itemUUIDs.reduce((result, uuid) => {
      const item = itemsById[uuid]
      const order = ordersById[item.order_uuid]

      const previousValue = result[order.kind] ?? 0

      return item?.status === ItemPreparationStatuses.NOT_BEING_PREPARED
        ? { ...result, [order.kind]: previousValue + item.quantity }
        : result
    }, {})

    return itemCountByCategories
  },
)

export default {
  selectIds,
  selectById,
  selectPrepared,
  selectItemById,
  selectScheduled,
  selectAllSorted,
  selectItemsForOrder,
  selectForCategories,
  selectItemsForOrderWithCategories,
  selectPreparedItems,
  selectBeingPrepared,
  selectPreparedCount,
  selectScheduledCount,
  selectScheduledGrouped,
  selectNotBeingPrepared,
  selectBeingPreparedCount,
  selectBeingPreparedItems,
  selectPreparedItemsCount,
  selectScheduledItemsCount,
  selectItemsCountByCategory,
  selectAllEntities,
  selectScheduledItemsGrouped,
  selectNotPreparedItemsCount,
  selectNotBeingPreparedItems,
  selectNotBeingPreparedCount,
  selectBeingPreparedItemsCount,
  selectItemsCountByOrderKind,
}
