import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { FlashList } from '@shopify/flash-list'

import colors from 'config/colors'
import { PullToRefresh } from 'components'
import { OrderSelectors } from 'store/Orders'
import { usePullToRefreshOrder } from 'util/hooks'

import { ItemSectionHeader } from './components'
import { EmptyList } from './components/EmptyList'
import Item from './components/Item'
import styles from './styles'
import type { ListProps } from './types'

const List = ({
  items,
  orders,
  labelKey,
  color,
  isFetching,
  onRefresh,
}: ListProps) => {
  const { t } = useTranslation()

  return (
    <View style={styles.flashListContainer}>
      <ItemSectionHeader label={t(labelKey)} color={color} />
      {!isEmpty(items) ? (
        <FlashList
          estimatedItemSize={100}
          data={items}
          ListEmptyComponent={
            <PullToRefresh style={{ marginTop: 10, marginBottom: 50 }} />
          }
          renderItem={({ item, index }) => {
            const prevItem = items[index - 1]

            return (
              <Item
                item={item}
                order={orders[item.order_uuid]}
                showCookButton={true}
                isSameOrder={prevItem?.order_uuid === item.order_uuid}
                isOdd={!(index % 2)}
              />
            )
          }}
          contentInset={{ bottom: 75 }}
          keyExtractor={(item) => item.uuid}
          onRefresh={onRefresh}
          refreshing={isFetching}
        />
      ) : (
        <EmptyList onRefresh={onRefresh} isFetching={isFetching} />
      )}
    </View>
  )
}

const Active = () => {
  const newItems = useSelector(OrderSelectors.selectNotBeingPreparedItems)
  const cookingItems = useSelector(OrderSelectors.selectBeingPreparedItems)
  const ordersById = useSelector(OrderSelectors.selectAllEntities)

  const [isPulled, onRefresh] = usePullToRefreshOrder()

  return (
    <View style={styles.container}>
      <List
        items={newItems}
        orders={ordersById}
        labelKey={'chef.tabs.new'}
        color={colors.ui.statuses.active}
        isFetching={isPulled}
        onRefresh={onRefresh}
      />
      <List
        items={cookingItems}
        orders={ordersById}
        labelKey={'chef.tabs.cooking'}
        color={colors.ui.statuses.cooking}
        isFetching={isPulled}
        onRefresh={onRefresh}
      />
    </View>
  )
}

export default Active
