import { ReactNode, useMemo, useState } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'

import { cache, Image } from './Image'
import {
  CircleLoadingPlaceholder,
  RectLoadingPlaceholder,
} from './LoadingPlaceholder'

interface WebImageProps {
  uri: string
  width: number
  height: number
  shape?: 'circle' | 'rect'
  borderRadius?: number
  style?: StyleProp<ImageStyle>
  containerStyle?: StyleProp<ViewStyle>
  children?: ReactNode
}

const WebImage = (props: WebImageProps) => {
  const {
    uri,
    height,
    width,
    style,
    containerStyle,
    borderRadius,
    children,
    shape = 'circle',
  } = props

  const [isLoading, setIsLoading] = useState(false)

  return (
    <View
      style={StyleSheet.compose(
        { width, height, borderRadius, overflow: 'hidden' },
        containerStyle,
      )}
    >
      {!isLoading ? null : shape === 'circle' ? (
        <CircleLoadingPlaceholder size={width} />
      ) : (
        <RectLoadingPlaceholder
          width={width}
          height={height}
          borderRadius={borderRadius}
        />
      )}
      {children}
      {useMemo(
        () =>
          uri ? (
            <Image
              style={StyleSheet.compose({ width, height, borderRadius }, style)}
              source={{
                uri,
                cache,
              }}
              onLoadStart={() => setIsLoading(true)}
              onLoadEnd={() => setIsLoading(false)}
            />
          ) : null,
        [width, height, borderRadius, style, uri, setIsLoading],
      )}
    </View>
  )
}

export default WebImage
