import { createSelector } from '@reduxjs/toolkit'

import { notificationsAdapter } from 'store/Notifications/entities'

import { NotificationTypes } from './enums'

// @ts-ignore
const selectors = notificationsAdapter.getSelectors((state) => state.notifications)

const { selectAll, selectById } = selectors

const selectCompanyNotifications = createSelector([selectAll], (notifications) => {
  return notifications.filter(
    (notification) => notification.notification_type === NotificationTypes.COMPANY,
  )
})

const selectUnreadCompanyNotificationCount = createSelector(
  selectCompanyNotifications,
  (notifications) => {
    return notifications.filter((notification) => !notification.read_at).length
  },
)

const selectOrderNotifications = createSelector([selectAll], (notifications) => {
  return notifications.filter((notification) =>
    [
      NotificationTypes.ITEM_REMOVED,
      NotificationTypes.ITEM_ADDED,
      NotificationTypes.ITEM_CHANGED,
      NotificationTypes.ORDER_CHANGED,
      NotificationTypes.ORDER_CANCELLED,
    ].includes(notification.notification_type),
  )
})

const selectUnreadOrderNotificationCount = createSelector(
  selectOrderNotifications,
  (notifications) => {
    return notifications.filter((notification) => !notification.read_at).length
  },
)

const selectUnattendedForOrder = createSelector(
  [selectOrderNotifications, (state, orderUUID) => orderUUID],
  (notifications, orderUUID) => {
    return notifications.filter(
      (notification) =>
        notification.order_uuid === orderUUID &&
        (!notification.accepted_at || !notification.read_at),
    )
  },
)

const selectAttendedForOrder = createSelector(
  [selectOrderNotifications, (state, orderUUID) => orderUUID],
  (notifications, orderUUID) => {
    return notifications.filter(
      (notification) =>
        notification.order_uuid === orderUUID && notification.accepted_at && notification.read_at,
    )
  },
)

const selectForOrderItem = createSelector(
  [selectOrderNotifications, (state, itemUUID) => itemUUID],
  (notifications, itemUUID) => {
    return notifications.filter((notification) => notification.item_uuid === itemUUID)
  },
)

const selectOrderItemHasUnattended = createSelector(
  [selectOrderNotifications, (state, itemUUID) => itemUUID],
  (notifications, itemUUID) => {
    return (
      notifications.filter(
        (notification) =>
          notification.item_uuid === itemUUID &&
          (!notification.accepted_at || !notification.read_at),
      ).length > 0
    )
  },
)

const selectOrderHasUnattended = createSelector(
  [selectOrderNotifications, (state, orderUUID) => orderUUID],
  (notifications, orderUUID) => {
    return (
      notifications.filter(
        (notification) =>
          notification.order_uuid === orderUUID &&
          (!notification.accepted_at || !notification.read_at),
      ).length > 0
    )
  },
)

const selectItemsHasUnattended = createSelector(
  [selectAll, (state, items) => items],
  (notifications, items) => {
    const itemsUUIDs = items.map(({ uuid }) => uuid)

    return (
      notifications.filter((notification) => {
        return (
          itemsUUIDs.includes(notification.item_uuid) &&
          (!notification.accepted_at || !notification.read_at)
        )
      }).length > 0
    )
  },
)

export default {
  selectAll,
  selectOrderNotifications,
  selectCompanyNotifications,
  selectById,
  selectAttendedForOrder,
  selectUnattendedForOrder,
  selectForOrderItem,
  selectUnreadOrderNotificationCount,
  selectUnreadCompanyNotificationCount,
  selectItemsHasUnattended,
  selectOrderItemHasUnattended,
  selectOrderHasUnattended,
}
