import Constants from 'expo-constants'
import {
  all,
  call,
  put,
  retry,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import SettingSelectors from 'store/Settings/selectors'
import UserSelectors from 'store/User/selectors'
import {
  init as amplitudeInit,
  identify,
  Identify,
  setGroup,
  track,
} from 'util/analytics/amplitude'

import Actions from './actions'

const API_KEY = '2dda5918db93c410fffd8162dc156119'

function* init() {
  try {
    const accountUUID = yield select(UserSelectors.selectAccountUUID)

    yield retry(10, 3000, amplitudeInit, API_KEY, accountUUID)
    const identifyObj = new Identify()

    const [
      hubName,
      business,
      company,
      isDelayBoforeCookEnabled,
      delayBeforeCookInSeconds,
    ] = yield all([
      select(UserSelectors.selectHubName),
      select(UserSelectors.selectBusiness),
      select(UserSelectors.selectCompany),
      select(SettingSelectors.selectItemCookDelayEnabled),
      select(SettingSelectors.selectItemCookDelayInSeconds),
    ])
    const appVersion = Constants.expoConfig.version

    yield retry(10, 3000, setGroup, 'business', business || '')
    yield retry(10, 3000, setGroup, 'company', company || '')
    yield retry(10, 3000, setGroup, 'store', hubName || '')

    identifyObj.set(
      'delay before cook',
      isDelayBoforeCookEnabled ? delayBeforeCookInSeconds : 0,
    )
    identifyObj.set('App Version', appVersion)

    yield retry(10, 3000, identify, identifyObj)
    yield put(Actions.initSuccess())
  } catch (error) {
    yield put(Actions.initFail({ error }))
  }
}

function* logEvent({ payload }) {
  try {
    const { eventName, eventPayload } = payload
    yield call(track, eventName, eventPayload)
    yield put(Actions.logEventSuccess())
  } catch (error) {
    yield put(Actions.logEventFail({ error }))
  }
}

export function* analyticsTask() {
  if (__DEV__) return

  yield takeLatest(Actions.init, init)
  yield takeEvery(Actions.logEvent, logEvent)
}

export default analyticsTask
