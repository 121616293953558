import { ComponentType, ReactNode, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePrevious from 'react-use/lib/usePrevious'
import { useNavigation, useRoute } from '@react-navigation/native'

import { useOverviewNavigationContext } from 'modules/OverviewScene/OverviewNavigationContext'
import { AppActions } from 'store/App'
import { TAB_BARS, TAB_BARS_LIST, TabBarKey } from 'store/App/constants'
import { OrderSelectors } from 'store/Orders'
import { OrderInternalStatuses } from 'store/Orders/enums'
import { getOrderInternalStatus } from 'store/Orders/utils'
import { SettingSelectors } from 'store/Settings'
import { createCtx } from 'util/createCtx'

import type { Order } from 'store/Orders/types'

const OrderInternalStatusToTabBarKeyMap = {
  [OrderInternalStatuses.NOT_BEING_PREPARED]: TAB_BARS.new.value,
  [OrderInternalStatuses.BEING_PREPARED]: TAB_BARS.cooking.value,
  [OrderInternalStatuses.SCHEDULED]: TAB_BARS.scheduled.value,
  [OrderInternalStatuses.PREPARED]: TAB_BARS.prepared.value,
  [OrderInternalStatuses.CANCELLED]: TAB_BARS.prepared.value,
}

function getCorrectTabForOrder(
  orderInternalStatus: OrderInternalStatuses,
): TabBarKey {
  return OrderInternalStatusToTabBarKeyMap[orderInternalStatus] as TabBarKey
}

export type OrderDetailsStateContext = {
  selectedUUID: string
  setSelectedUUID(uuid: string): void
  orderInternalStatus: OrderInternalStatuses
}

const [useOrderDetailsStateContext, Provider] =
  createCtx<OrderDetailsStateContext>()

/**
 * This hook has 2 functions:
 *  - Maintain parity between selected order & selected tab. Order details should only be available on a tab that lists it. Depending on user's action either a tab or the order needs to be adjusted.
 *  - Automatically select first order from current tab (if available) when currently selected order moved to a different tab due to preparation status change (not if it moved for other reasons - i.e. change of selected categories).
 *
 *
 * Example 1 (NEW [#123]): user selects order #123 in NEW tab; user switches to COOKING tab; order #123 should get deselected.
 * Example 2 (NEW [#123], COOKING [#456]): user selects order #123 in NEW tab; user presses on notification for order #456; order #456 should get selected; tab should change to COOKING.
 * Example 3 (NEW [#123, #456]): user selects order #123 in NEW tab; user presses START ALL; #123 moves to COOKING tab; order #456 should get selected.
 * Example 4 (NEW [#123]): user selects order #123 in NEW tab; user presses START ALL; #123 moves to COOKING tab; order #123 should get deselected.
 * Example 5 (NEW [#123, #456]): user selects order #123 in NEW tab; user updates category filters; #123 moves to COOKING tab (due to a different item composition); order #123 should get deselected.
 */
function useOrderDetailsBehaviour(
  selectedUUID: string,
  setSelectedUUID: (uuid: string) => void,
  orderInternalStatus: OrderInternalStatuses,
  orderUUIDToSelectNext: string,
  correctTabValue: TabBarKey,
  selectedTabValue: TabBarKey,
  selectedCategories: string[],
) {
  const dispatch = useDispatch()
  const selectedUUIDPrev = usePrevious(selectedUUID)
  const selectedTabPrev = usePrevious(selectedTabValue)
  const selectedCategoriesPrev = usePrevious(selectedCategories)

  const categoriesHasChanged = selectedCategoriesPrev !== selectedCategories
  const tabHasChanged = selectedTabPrev !== selectedTabValue
  const isSelectedOrderInCorrectTab = correctTabValue === selectedTabValue

  useEffect(() => {
    if (!isSelectedOrderInCorrectTab) {
      if (selectedUUID !== selectedUUIDPrev) {
        const tabToSelect = Object.values(TAB_BARS).find(
          (tab) => tab.value === correctTabValue,
        )
        dispatch(AppActions.setTabBar(tabToSelect))
      } else {
        setSelectedUUID(
          tabHasChanged || categoriesHasChanged ? null : orderUUIDToSelectNext,
        )
      }
    }
  }, [
    dispatch,
    setSelectedUUID,
    selectedUUID,
    selectedUUIDPrev,
    orderInternalStatus,
    isSelectedOrderInCorrectTab,
    orderUUIDToSelectNext,
    tabHasChanged,
    categoriesHasChanged,
  ])
}

function OrderDetailsBehavior() {
  const { selectedUUID, setSelectedUUID, orderInternalStatus } =
    useOrderDetailsStateContext()

  const { activeTabIndex } = useOverviewNavigationContext()
  const selectedTabValue = TAB_BARS_LIST[activeTabIndex].value as TabBarKey

  const newOrders = useSelector<unknown, Order[]>(
    OrderSelectors.selectNotBeingPrepared,
  )
  const cookingOrders = useSelector<unknown, Order[]>(
    OrderSelectors.selectBeingPrepared,
  )
  const selectedCategories = useSelector(
    SettingSelectors.selectSelectedCategories,
  )

  const orderUUIDToSelectNext: string | undefined = useMemo(() => {
    const ordersInTab =
      selectedTabValue === TAB_BARS.new.value
        ? newOrders
        : selectedTabValue === TAB_BARS.cooking.value
        ? cookingOrders
        : null

    return ordersInTab?.at(0)?.uuid
  }, [selectedTabValue, newOrders, cookingOrders])

  const correctTabValue: TabBarKey = getCorrectTabForOrder(orderInternalStatus)

  useOrderDetailsBehaviour(
    selectedUUID,
    setSelectedUUID,
    orderInternalStatus,
    orderUUIDToSelectNext,
    correctTabValue,
    selectedTabValue,
    selectedCategories,
  )

  return null
}

function OrderDetailsStateProvider(props: { children: ReactNode }) {
  const { children } = props

  const navigation = useNavigation()
  const params = useRoute().params as { uuid?: string }
  const { uuid: selectedUUID } = params || {}

  const navigationRef = useRef(navigation)

  useEffect(() => {
    navigationRef.current = navigation
  }, [navigation])

  const order = useSelector((state) =>
    selectedUUID ? OrderSelectors.selectById(state, selectedUUID) : null,
  )
  const items = useSelector((state) =>
    order
      ? OrderSelectors.selectItemsForOrderWithCategories(state, selectedUUID)
      : null,
  )

  const orderInternalStatus: OrderInternalStatuses | null = useMemo(() => {
    return order && items ? getOrderInternalStatus(order, items) : null
  }, [order, items])

  const contextValue = useMemo(() => {
    return {
      selectedUUID,
      orderInternalStatus,
      setSelectedUUID: (uuid: string) =>
        navigationRef.current.setParams({ uuid: uuid || '' }),
    }
  }, [selectedUUID, orderInternalStatus])

  return (
    <Provider value={contextValue}>
      {selectedUUID && orderInternalStatus && <OrderDetailsBehavior />}
      {children}
    </Provider>
  )
}

function withOrderDetailsStateProvider<T extends object>(
  WrappedComponent: ComponentType<T>,
) {
  const ComponentWithContext = (props: T) => {
    return (
      <OrderDetailsStateProvider>
        <WrappedComponent {...(props as T)} />
      </OrderDetailsStateProvider>
    )
  }

  return ComponentWithContext
}

export { withOrderDetailsStateProvider, useOrderDetailsStateContext }
