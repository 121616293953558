import { SceneMap } from 'react-native-tab-view'

import { TAB_BARS } from 'store/App/constants'

import { Cooking, New, Prepared, Scheduled } from './Lists'

export const renderScene = SceneMap({
  [TAB_BARS.new.value]: New,
  [TAB_BARS.cooking.value]: Cooking,
  [TAB_BARS.scheduled.value]: Scheduled,
  [TAB_BARS.prepared.value]: Prepared,
})
