import { call, put, takeLatest } from 'redux-saga/effects'

import API from 'API/modules/User'
import { UserActions } from 'store/User/actions'
import { onAuthenticated } from 'store/util/sagas'

import { InternalErrors } from '../types'

function* fetch() {
  try {
    const { error, ...data } = yield call(API.fetchAccount)

    if (error) {
      yield put(UserActions.fetchAccountFailure({ error }))
      // yield put(
      //   AppActions.dispatchMessageForUser({
      //     type: error,
      //     additionalInfo: 'get account data',
      //   }),
      // )
    } else {
      yield put(UserActions.fetchAccountSuccess(data))
    }
  } catch (error) {
    yield put(
      UserActions.fetchAccountFailure({
        error: InternalErrors.SAGA_ERROR,
        meta: { error },
      }),
    )
  }
}

function* updateEstimatedPreparationTime({ payload }) {
  try {
    const { error } = yield call(API.patchAccount, {
      estimated_preparation_time: payload,
    })
    if (error) {
      yield put(UserActions.updateEstimatedPreparationTimeFailure({ error }))
      // yield put(
      //   AppActions.dispatchMessageForUser({
      //     type: error,
      //     additionalInfo: 'update preparation time',
      //   }),
      // )
    } else {
      yield put(UserActions.updateEstimatedPreparationTimeSuccess())
    }
  } catch (error) {
    yield put(
      UserActions.updateEstimatedPreparationTimeFailure({
        error: InternalErrors.SAGA_ERROR,
        meta: { error },
      }),
    )
  }
}

function* rootSaga() {
  yield takeLatest(UserActions.fetchAccount, fetch)
  yield takeLatest(
    UserActions.updateEstimatedPreparationTime,
    updateEstimatedPreparationTime,
  )
  yield takeLatest(UserActions.selectAccount, onAuthenticated)
}

export default rootSaga
