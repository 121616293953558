import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigationState } from '@react-navigation/native'

import { RouteNames } from 'navigation/linkingConfig'
import { AppActions, AppSelectors } from 'store/App'
import { TAB_BARS, TAB_BARS_LIST } from 'store/App/constants'
import { OrderSelectors } from 'store/Orders'
import { getRouteName } from 'util/helpers'

import { NavigationButton } from './NavigationButtons'
import { commonStyles } from './styles'

export function TabBarSection() {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const selectedTabBar = useSelector(AppSelectors.selectCurrentTabBar)
  const isDrawerMinimized = useSelector(AppSelectors.selectIsDrawerMinimized)

  const onSelectTabBar = (tabBar: (typeof TAB_BARS_LIST)[number]) => {
    dispatch(AppActions.setTabBar(tabBar))
  }

  const newItemsCount = useSelector(OrderSelectors.selectNotPreparedItemsCount)
  const cookingItemsCount = useSelector(
    OrderSelectors.selectBeingPreparedItemsCount,
  )

  const newCount = useSelector(OrderSelectors.selectNotBeingPreparedCount)
  const cookingCount = useSelector(OrderSelectors.selectBeingPreparedCount)
  const preparedCount = useSelector(OrderSelectors.selectPreparedCount)
  const scheduleCount = useSelector(OrderSelectors.selectScheduledCount)

  const orderCountByStatus = useMemo(() => {
    return {
      [TAB_BARS.new.value]: [newCount],
      [TAB_BARS.cooking.value]: [cookingCount],
      [TAB_BARS.scheduled.value]: [scheduleCount],
      [TAB_BARS.prepared.value]: [preparedCount],
    }
  }, [newCount, cookingCount, preparedCount, scheduleCount])

  const routeName = useNavigationState(({ index, routes }) => {
    return getRouteName({ index, routes }, 2)
  })

  if (routeName !== RouteNames.Home.Main.Drawer.Tabs.Chef._) {
    return (
      <View style={[commonStyles.container, styles.container]}>
        {TAB_BARS_LIST.map((tabBar) => {
          return (
            <NavigationButton
              key={tabBar.value}
              label={t(
                !isDrawerMinimized
                  ? tabBar.labelKey
                  : `${tabBar.labelKey}_short`,
                {
                  count: orderCountByStatus[tabBar.value][0],
                },
              )}
              onPress={() => onSelectTabBar(tabBar)}
              isMinimized={isDrawerMinimized}
              iconName={tabBar.iconName}
              isSelected={tabBar.value === selectedTabBar}
              activeColor={tabBar.color}
              isShownBadge={Boolean(
                tabBar.value === TAB_BARS.scheduled.value &&
                  orderCountByStatus[tabBar.value][0],
              )}
            />
          )
        })}
      </View>
    )
  }

  return (
    <View style={[commonStyles.container, styles.container]}>
      <NavigationButton
        isSelected
        label={t(
          !isDrawerMinimized
            ? 'sideBar.tabBarSection.newAndCooking'
            : 'sideBar.tabBarSection.newAndCooking_short',
          {
            count: newItemsCount,
            count2: cookingItemsCount,
          },
        )}
        isMinimized={isDrawerMinimized}
        iconName={TAB_BARS.cooking.iconName}
        activeColor={TAB_BARS.new.color}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
  },
})
