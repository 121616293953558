import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { DrawerContentScrollView } from '@react-navigation/drawer'

import { HorizontalDivider, Spacer } from 'components'
import { AppSelectors } from 'store/App'

import { FilterSection } from './FilterSection'
import { InfoSection } from './InfoSection'
import { MenuSection } from './MenuSection'
import { MenuSectionMinimized } from './MenuSectionMinimized'
import { TabBarSection } from './TabBarSection'

export const SideBar = (props) => {
  const isDrawerMinimized = useSelector(AppSelectors.selectIsDrawerMinimized)

  return (
    <DrawerContentScrollView
      {...props}
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      scrollEnabled
    >
      {!isDrawerMinimized ? <MenuSection /> : <MenuSectionMinimized />}
      <TabBarSection />
      <Spacer />
      <FilterSection />
      <HorizontalDivider style={{ marginVertical: 16, opacity: 0.8 }} />
      <InfoSection />
    </DrawerContentScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 12,
  },
  contentContainer: {
    flexGrow: 1,
  },
})
