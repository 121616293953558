import { useRef } from 'react'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react-native'

import { getErrorID } from 'util/helpers'

import CrashScreen from './CrashScreen'

type SentryErrorBoundaryProps = React.ComponentProps<typeof SentryErrorBoundary>

export type ErrorBoundaryProps = Omit<
  SentryErrorBoundaryProps,
  'beforeCapture'
> & {
  beforeCapture: (
    params: Parameters<SentryErrorBoundaryProps['beforeCapture']>,
    errorUUID: string,
  ) => void
}

const ErrorBoundary = ({
  children,
  fallback,
  beforeCapture,
  ...props
}: ErrorBoundaryProps) => {
  const errorUUID = useRef(getErrorID())

  return (
    <SentryErrorBoundary
      fallback={
        fallback ??
        (({ resetError }) => {
          return (
            <CrashScreen
              errorUUID={errorUUID.current}
              resetError={resetError}
            />
          )
        })
      }
      beforeCapture={(...params) => {
        beforeCapture(params, errorUUID.current)
      }}
      {...props}
    >
      {children}
    </SentryErrorBoundary>
  )
}

export default ErrorBoundary
