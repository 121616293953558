import { createAction } from '@reduxjs/toolkit'

import type { AppNotification } from 'store/Notifications/types'
import type { DefaultFailActionPayload } from '../types'

const fetch = createAction('NOTIFICATIONS/FETCH/REQUEST')
const fetchSuccess = createAction<AppNotification[]>('NOTIFICATIONS/FETCH/SUCCESS')
const fetchFail = createAction<DefaultFailActionPayload>('NOTIFICATIONS/FETCH/FAIL')

const markAsRead = createAction<{ uuids: AppNotification['uuid'][] }>('NOTIFICATIONS/MARK_AS_READ/REQUEST')
const markAsReadSuccess = createAction<{ uuids: AppNotification['uuid'][] }>('NOTIFICATIONS/MARK_AS_READ/SUCCESS')
const markAsReadFail = createAction<DefaultFailActionPayload>('NOTIFICATIONS/MARK_AS_READ/FAIL')

export default {
  fetch,
  fetchSuccess,
  fetchFail,

  markAsRead,
  markAsReadSuccess,
  markAsReadFail,
}
