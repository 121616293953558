import type { Store } from './index'

class StoreRegistry {
  private store: Store

  getStore() {
    return this.store
  }

  register(store) {
    this.store = store
  }
}

export default new StoreRegistry()
