import { StyleProp, View, ViewStyle } from 'react-native'
import _ from 'lodash'

import colors from 'config/colors'
import Text from 'components/Text'

import { styles } from './styles'

type Props = {
  style?: StyleProp<ViewStyle>
}

export const NonCookableBadge = ({ style }: Props) => {
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: colors.white,
        },
        style,
      ]}
    >
      <View style={styles.content}>
        <Text
          color={colors.darkGray}
          font="black"
          size={12}
          style={styles.label}
          id="common.nonCookable"
        />
      </View>
    </View>
  )
}
