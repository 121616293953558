import { createSlice } from '@reduxjs/toolkit'

import { AuthActions } from 'store/Auth'
import { DeviceThunks } from 'store/Device/thunks'

import type { DeviceState } from 'store/Device/types'

const initialState: DeviceState = {
  registeredUUID: null,
  seenScreenSelection: false,
  device: null,
}

const slice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    unregisterDevice: () => initialState,
    markSeenScreenSelection: (state) => {
      state.seenScreenSelection = true
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(DeviceThunks.register.fulfilled, (state, action) => {
        const device = action.payload

        state.registeredUUID = action.payload.uuid
        state.device = device
      })
      .addCase(DeviceThunks.fetchCurrent.fulfilled, (state, action) => {
        const device = action.payload

        state.device = device
      })
      .addCase(DeviceThunks.setScreen.fulfilled, (state, action) => {
        const device = action.payload

        state.device = device
      })
      .addCase(DeviceThunks.fetchCurrent.rejected, (state, action) => {
        if (action.payload.deviceNotFound) {
          return initialState
        }
      })
      .addCase(AuthActions.signOut, () => initialState),
})

export const { reducer: DeviceReducer, actions: DeviceActions } = slice
