import _ from 'lodash'
import { createReducer } from '@reduxjs/toolkit'

import { dissectActionType } from './util/functions'

const defaultState = {
  fetching: {},
  errors: {},
}

// _.xor adds item to an array. _.difference removes it

const reducer = createReducer(defaultState, {
  REQUEST: (state, action) => {
    const { actionId, payloadId } = action
    if (payloadId) {
      state.fetching[actionId] = _.xor(state.fetching[actionId], [payloadId])
      state.errors[actionId] = _.difference(state.fetching[actionId], [
        payloadId,
      ])
    } else {
      state.fetching[actionId] = true
      state.errors[actionId] = null
    }
  },
  SUCCESS: (state, action) => {
    const { actionId, payloadId } = action
    if (payloadId) {
      state.fetching[actionId] = _.difference(state.fetching[actionId], [
        payloadId,
      ])
    } else {
      state.fetching[actionId] = false
    }
  },
  FAILURE: (state, action) => {
    const { actionId, payloadId, error } = action
    if (payloadId) {
      state.errors[actionId] = _.xor(state.fetching[actionId], [payloadId])
      state.fetching[actionId] = _.difference(state.fetching[actionId], [
        payloadId,
      ])
    } else {
      state.errors[actionId] = error
      state.fetching[actionId] = false
    }
  },
})

const requestStatusReducer = (state, action) => {
  const { status, actionId } = dissectActionType(action.type)
  const payloadId = action.payload ? action.payload.id : null
  const error =
    action.payload && typeof action.payload.error === 'string'
      ? action.payload.error
      : null

  return reducer(state, { type: status, actionId, payloadId, error })
}

export default requestStatusReducer
