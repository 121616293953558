import type {
  OrderDetailsInterfaceSettingKey,
  OrderListInterfaceSettingKey,
  SettingState,
} from 'store/Settings/types'
import type { InterfaceSettings } from 'store/User/types'

export const MAP_ORDER_LIST_FLAG_TO_STATE: Record<
  keyof InterfaceSettings['order_overview_card'],
  OrderListInterfaceSettingKey
> = {
  customer_name: 'shouldDisplayCustomerName',
  address_first_line: 'shouldDisplayCustomerAddress',
}

export const MAP_ORDER_DETAILS_FLAG_TO_STATE: Partial<
  Record<
    keyof InterfaceSettings['order_details_card'],
    OrderDetailsInterfaceSettingKey
  >
> = {
  financial_details: 'shouldDisplayFinancialInfo',
  customer_details: 'shouldDisplayCustomerAddress',
  driver_details: 'shouldDisplayDriverDetails',
  order_channel: 'shouldDisplayOrderChannel',
  item_pricing: 'shouldDisplayItemPricing',
  order_internal_notes: 'shouldDisplayInternalNotes',
}

export function resetInterfaceSettings(
  state: SettingState,
  featureFlags?: InterfaceSettings,
) {
  if (!featureFlags) return

  const { order_details_card, order_overview_card } = featureFlags

  Object.entries(order_overview_card).forEach(([key, value]) => {
    if (value) return

    const stateKey = MAP_ORDER_LIST_FLAG_TO_STATE[key]

    state.interface.orderList[stateKey] = false
  })

  Object.entries(order_details_card).forEach(([key, value]) => {
    if (value) return

    const stateKey = MAP_ORDER_DETAILS_FLAG_TO_STATE[key]

    state.interface.orderDetails[stateKey] = false
  })
}
