import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import colors from 'config/colors'
import { Button, Text } from 'components'
import { AuthBackground } from 'modules/Auth/components/AuthBackground'
import { ScreenItem } from 'modules/Device/components'
import { DeviceActions, DeviceThunks } from 'store/Device'
import { useAppDispatch } from 'store/hooks'
import { UserSelectors } from 'store/User'

// todo adjust confirm button ui
export function ScreenSelection() {
  const dispatch = useAppDispatch()
  const screens = useSelector(UserSelectors.selectAvailableScreens)
  const [selectedScreenUUID, setSelectedScreenUUID] = useState<string>(null)
  const [proceedWithoutScreen, setProceedWithoutScreen] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  const onConfirm = async () => {
    if (selectedScreenUUID) {
      setIsLoading(true)
      const thunkResult = await dispatch(
        DeviceThunks.setScreen(selectedScreenUUID),
      )
      setIsLoading(false)
      if (thunkResult.meta.requestStatus === 'fulfilled') {
        dispatch(DeviceActions.markSeenScreenSelection())
      }
    } else {
      dispatch(DeviceActions.markSeenScreenSelection())
    }
  }

  return (
    <AuthBackground>
      <View style={styles.container}>
        <Text
          id="device.selectScreen.title"
          font="black"
          size={32}
          color={colors.lightBlue}
          align={'center'}
        />
        <Text
          id="device.selectScreen.subtitle"
          style={styles.subtitle}
          size={16}
          align={'center'}
          color={colors.gray}
        />
        <ScrollView style={styles.list}>
          {screens.map((screen) => (
            <ScreenItem
              name={screen.name}
              isSelected={selectedScreenUUID === screen.uuid}
              categories={screen.default_categories}
              orderKinds={screen.default_order_kinds}
              onPress={() => {
                setSelectedScreenUUID(screen.uuid)
                setProceedWithoutScreen(false)
              }}
              key={screen.uuid}
            />
          ))}
          <Text
            id={'device.selectScreen.or'}
            align={'center'}
            font={'bold'}
            size={18}
            color={colors.lightBlue}
            style={{ marginVertical: 8 }}
          />
          <ScreenItem
            name={t('device.selectScreen.continueWithoutScreen')}
            isSelected={proceedWithoutScreen}
            onPress={() => {
              setSelectedScreenUUID(null)
              setProceedWithoutScreen(true)
            }}
          />
        </ScrollView>
        <Button
          disabled={(!selectedScreenUUID && !proceedWithoutScreen) || isLoading}
          isFetching={isLoading}
          onPress={onConfirm}
          titleId={'device.selectScreen.buttonConfirm'}
          style={{ marginTop: 50 }}
          innerStyle={{ paddingVertical: 15 }}
        />
      </View>
    </AuthBackground>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: 'white',
    padding: 32,
    borderRadius: 8,
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: 1000,
    alignSelf: 'center',
    marginTop: 16,
    overflow: 'hidden',
  },
  subtitle: {
    marginTop: 8,
  },
  list: {
    flexGrow: 1,
    flexShrink: 1,
  },
})
