import { ReactNode } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import { Text } from 'components'

import { MaterialCommunityIconName } from 'types/icons'

interface Props {
  title?: string
  titleKey?: string
  subTitle?: string
  subTitleKey?: string
  children?: ReactNode
  titleRight?: ReactNode
  iconName?: MaterialCommunityIconName
}

const Setting = (props: Props) => {
  const {
    children,
    title,
    titleKey,
    titleRight,
    subTitle,
    subTitleKey,
    iconName,
  } = props

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.headerContainer}>
        {iconName && (
          <View style={styles.iconContainer}>
            <MaterialCommunityIcons name={iconName} color="white" size={30} />
          </View>
        )}
        <View style={styles.titleContainer}>
          <View style={styles.titleLabel}>
            {Boolean(titleKey || title) && (
              <Text size={20} font={'bold'} id={titleKey}>
                {title}
              </Text>
            )}
            {titleRight}
          </View>
          {Boolean(subTitle || subTitleKey) && (
            <Text
              size={16}
              color={colors.text.dark}
              font={'light'}
              style={styles.subtitleLabel}
              id={subTitleKey}
            >
              {subTitle}
            </Text>
          )}
        </View>
      </View>
      <ScrollView>{children}</ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '89%',
    maxWidth: 900,
    alignSelf: 'flex-start',
    flex: 1,
    marginHorizontal: 20,
  },
  headerContainer: {
    margin: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  titleContainer: {},
  titleLabel: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subtitleLabel: {
    paddingTop: 2,
  },
  iconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: colors.main,
    borderRadius: 4,
    marginRight: 16,
  },
})

export default Setting
