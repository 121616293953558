import { useCallback, useMemo } from 'react'
import { ViewToken } from 'react-native'

import { useAppDispatch } from 'store/hooks'
import { NotificationActions, NotificationThunks } from 'store/Notifications'
import { AppNotification } from 'store/Notifications/types'

export const useNotificationCallbacks = (notifications: AppNotification[]) => {
  const dispatch = useAppDispatch()

  const hasUnaccepted = useMemo(() => {
    return notifications?.some((item) => !item.accepted_at) || false
  }, [notifications])

  const markAsRead = useCallback(
    (uuids: AppNotification['uuid'][]) => {
      dispatch(NotificationActions.markAsRead({ uuids }))
    },
    [dispatch],
  )

  const onViewableItemsChanged = useCallback(
    ({
      changed,
      viewableItems: _,
    }: {
      changed: ViewToken[]
      viewableItems: ViewToken[]
    }) => {
      const uuidsToRead = changed.reduce(
        (result, viewToken) =>
          viewToken.isViewable && !viewToken.item.read_at
            ? [...result, viewToken.item.uuid]
            : result,
        [],
      )

      if (uuidsToRead.length > 0) {
        markAsRead(uuidsToRead)
      }
    },
    [markAsRead],
  )

  const onPressMarkAllAsRead = useCallback(() => {
    const uuids = notifications.reduce(
      (result, notification) =>
        notification.read_at ? result : [...result, notification.uuid],
      [],
    )

    markAsRead(uuids)
  }, [notifications])

  const onPressMarkAllAsReadAndAccepted = useCallback(async () => {
    const uuids = notifications.reduce(
      (result, notification) =>
        notification.read_at && notification.accepted_at
          ? result
          : [...result, notification.uuid],
      [],
    )

    await dispatch(NotificationThunks.markAsReadAndAccepted({ uuids }))

    return
  }, [notifications, dispatch])

  return {
    hasUnaccepted,
    onViewableItemsChanged,
    onPressMarkAllAsRead,
    onPressMarkAllAsReadAndAccepted,
  }
}
