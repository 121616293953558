import { apiInstance } from 'API/instances'
import { ExtendedAxiosRequestConfig } from 'API/types'
import { executeRequest } from 'API/util/functions'
import { AppNotification } from 'store/Notifications/types'

const fetch = async (config?: Partial<ExtendedAxiosRequestConfig>) => {
  const response = await apiInstance.get('v1/notifications', {
    ...config,
    params: {
      include_account_messages: true,
      ...config?.params,
    },
  })

  return response.data
}

const markAsRead = (uuids: AppNotification['uuid'][]) =>
  executeRequest((api) => api.put('v1/notifications/read', { uuids }))

const markAsAccepted = (uuids: AppNotification['uuid'][]) =>
  executeRequest((api) => api.put('v1/notifications/accept', { uuids }))

export default {
  fetch,
  markAsRead,
  markAsAccepted,
}
