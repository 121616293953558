import { useTranslation } from 'react-i18next'
import { StatusBar } from 'react-native'
import { createDrawerNavigator } from '@react-navigation/drawer'

import colors from 'config/colors'
import { RouteNames } from 'navigation/linkingConfig'
import { useEnterScreen } from 'util/analytics'

import Categories from './Categories'
import CustomDrawer from './Drawer'
import General from './General'
import Interface from './Interface'
import OrderKinds from './OrderKinds'
// import Sound from './Sound'
import Timing from './Timing'

const Drawer = createDrawerNavigator()

const screenOptions = {
  drawerType: 'permanent' as 'permanent',
  headerShown: false,
  drawerStyle: {
    width: 200,
    backgroundColor: colors.white,
  },
  headerPosition: 'left',
  sceneContainerStyle: {
    backgroundColor: '#f5f5f5',
  },
}

const SettingsNavigator = () => {
  const { t } = useTranslation()

  useEnterScreen('settings')

  return (
    <>
      <StatusBar
        barStyle="dark-content"
        translucent
        backgroundColor="#FFFFFF00"
      />
      <Drawer.Navigator
        screenOptions={screenOptions}
        drawerContent={(props) => <CustomDrawer {...props} />}
      >
        <Drawer.Screen
          name={RouteNames.Home.Settings.General}
          component={General}
          options={() => ({ title: t('settings.general.title') })}
        />
        <Drawer.Screen
          name={RouteNames.Home.Settings.Interface}
          component={Interface}
          options={() => ({ title: t('settings.interface.title') })}
        />
        <Drawer.Screen
          name={RouteNames.Home.Settings.Timing}
          component={Timing}
          options={() => ({ title: t('settings.timing.title') })}
        />
        {/*<Drawer.Screen
        name={RouteNames.Home.Settings.Sounds}
        component={Sound}
        options={() => ({ title: t('settings.sounds.title') })}
      />*/}
        <Drawer.Screen
          name={RouteNames.Home.Settings.OrderKinds}
          component={OrderKinds}
          options={() => ({ title: t('settings.orderKinds.title') })}
        />
        <Drawer.Screen
          name={RouteNames.Home.Settings.Categories}
          component={Categories}
          options={() => ({ title: t('settings.categories.title') })}
        />
      </Drawer.Navigator>
    </>
  )
}
export default SettingsNavigator
