import { AxiosError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { DevicesAPI } from 'API/modules/Device'
import { DeviceSelectors } from 'store/Device/selectors'

import type { ExtendedError } from 'API/util/ExtendedError'
import type { Device } from 'store/Device/types'

const fetchCurrent = createAsyncThunk<
  Device,
  void,
  { rejectValue: { errorMessage: string; deviceNotFound: boolean } }
>('devices/fetchByUUID', async (_, thunkAPI) => {
  const { signal, rejectWithValue, getState } = thunkAPI

  const state = getState()
  const selectedDeviceUUID = DeviceSelectors.selectRegisteredUUID(state)

  if (!signal.aborted) {
    try {
      return await DevicesAPI.fetchByUUID(selectedDeviceUUID, {
        signal,
      })
    } catch (error) {
      const extendedError = error as ExtendedError
      const axiosError = extendedError.error as AxiosError

      return rejectWithValue({
        errorMessage: extendedError.genericUserFacingMessage,
        deviceNotFound:
          extendedError.isAxiosError && axiosError.response.status === 404,
      })
    }
  }
})

const register = createAsyncThunk<
  Device,
  void,
  { rejectValue: { errorMessage: string } }
>('devices/register', async (_, thunkAPI) => {
  const { signal, rejectWithValue } = thunkAPI

  if (!signal.aborted) {
    try {
      return await DevicesAPI.register({ signal })
    } catch (error) {
      return rejectWithValue({ errorMessage: error.genericUserFacingMessage })
    }
  }
})

const setScreen = createAsyncThunk<
  Device,
  string,
  { rejectValue: { errorMessage: string } }
>('devices/setScreen', async (screenUUID, thunkAPI) => {
  const { signal, rejectWithValue, getState } = thunkAPI

  const state = getState()
  const selectedDeviceUUID = DeviceSelectors.selectRegisteredUUID(state)

  if (!signal.aborted) {
    try {
      return await DevicesAPI.setScreen(selectedDeviceUUID, screenUUID, {
        signal,
      })
    } catch (error) {
      return rejectWithValue({ errorMessage: error.genericUserFacingMessage })
    }
  }
})

export const DeviceThunks = { fetchCurrent, setScreen, register }
