import { createAction } from '@reduxjs/toolkit'

import { FetchEnvironmentsPayload, LoginPayload, ResetPasswordPayload } from 'API/modules/Auth'
import { EnvironmentServer } from 'modules/Auth/components/SelectEnvironmentModal'

import type { UserState } from 'store/User/types'
import type { DefaultFailActionPayload } from '../types'

export const login = createAction<LoginPayload>('AUTH/LOGIN/REQUEST')
export const loginSuccess = createAction<{ response: UserState; environment?: EnvironmentServer | null }>(
  'AUTH/LOGIN/SUCCESS',
)
export const loginFailure = createAction<DefaultFailActionPayload>('AUTH/LOGIN/FAILURE')

export const resetPassword = createAction<ResetPasswordPayload>('AUTH/FORGOT_PASSWORD/REQUEST')

export const signOut = createAction('AUTH/SIGN_OUT')

export const fetchEnvironments = createAction<FetchEnvironmentsPayload>('AUTH/ENVIRONMENT_SERVER/REQUEST')
