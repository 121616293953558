import { useCallback, useEffect, useMemo, useState } from 'react'
import useBoolean from 'react-use/lib/useBoolean'
import useInterval from 'react-use/lib/useInterval'

import colors from 'config/colors'
import {
  ItemPreparationStatuses,
  OrderInternalStatuses,
} from 'store/Orders/enums'
import { getOrderInternalStatus } from 'store/Orders/utils'

import type { Order, OrderItem } from 'store/Orders/types'
import type { MaterialCommunityIconName } from 'types/icons'

export const COUNTDOWN_INTERVAL_MS = 1000

type Meta = {
  statusToUpdate?: ItemPreparationStatuses
  iconName?: MaterialCommunityIconName
  iconColor?: string
  labelKey?: string
}

export const getMetaForItem = (
  status: ItemPreparationStatuses,
  cookable: OrderItem['cookable'],
): Meta => {
  if (!cookable) {
    return {
      statusToUpdate: ItemPreparationStatuses.PREPARED,
      iconName: 'stop',
      iconColor: colors.ui.statuses.prepared,
      labelKey: 'common.actionButton.finish',
    }
  }

  switch (status) {
    case ItemPreparationStatuses.NOT_BEING_PREPARED:
      return {
        statusToUpdate: ItemPreparationStatuses.BEING_PREPARED,
        iconName: 'play',
        iconColor: colors.ui.statuses.cooking,
        labelKey: 'common.actionButton.start',
      }
    case ItemPreparationStatuses.BEING_PREPARED:
      return {
        statusToUpdate: ItemPreparationStatuses.PREPARED,
        iconName: 'stop',
        iconColor: colors.ui.statuses.prepared,
        labelKey: 'common.actionButton.finish',
      }

    default:
      return {}
  }
}

export const getMetaForOrder = (order: Order, items: OrderItem[]): Meta => {
  const combinedItemStatus = getOrderInternalStatus(order, items)

  switch (combinedItemStatus) {
    case OrderInternalStatuses.SCHEDULED:
    case OrderInternalStatuses.NOT_BEING_PREPARED:
      return {
        statusToUpdate: ItemPreparationStatuses.BEING_PREPARED,
        iconName: 'play',
        iconColor: colors.ui.statuses.cooking,
        labelKey: 'common.actionButton.startAll',
      }
    case OrderInternalStatuses.BEING_PREPARED:
      return {
        statusToUpdate: ItemPreparationStatuses.PREPARED,
        iconName: 'stop',
        iconColor: colors.ui.statuses.prepared,
        labelKey: 'common.actionButton.finishAll',
      }
    default:
      return {}
  }
}

export const useCountdown = (initialValue) => {
  const [value, setValue] = useState(null)

  const [isRunning, toggleIsRunning] = useBoolean(false)

  useEffect(() => {
    if (initialValue || isRunning) {
      setValue(initialValue)
    }
  }, [initialValue, setValue, isRunning])

  useInterval(
    () => setValue((v) => v - 1),
    isRunning ? COUNTDOWN_INTERVAL_MS : null,
  )

  const stop = useCallback(() => toggleIsRunning(false), [toggleIsRunning])
  const start = useCallback(() => toggleIsRunning(true), [toggleIsRunning])

  return [value, isRunning, useMemo(() => [start, stop], [start, stop])]
}
