import { FlatList } from 'react-native'

import { PullToRefresh } from 'components'

const DATA = []

type EmptyListProps = {
  onRefresh: () => void
  isFetching: boolean
  ListHeaderComponent?: React.ReactElement
  numColumns?: number
}

export const EmptyList = ({
  onRefresh,
  isFetching,
  ListHeaderComponent,
  numColumns = 1,
}: EmptyListProps) => {
  return (
    <FlatList
      data={DATA}
      ListEmptyComponent={
        <PullToRefresh style={{ marginTop: 10, marginBottom: 50 }} />
      }
      ListHeaderComponent={ListHeaderComponent}
      renderItem={() => null}
      keyExtractor={(item) => item.uuid}
      onRefresh={onRefresh}
      refreshing={isFetching}
      numColumns={numColumns}
    />
  )
}
