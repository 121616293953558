import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import colors from 'config/colors'
import { AlertsContainer } from 'modules/Alerts'
import { ChefScene } from 'modules/ChefScene'
import ItemDetailView from 'modules/ChefScene/ItemDetailView'
import OverviewNavigator from 'modules/OverviewScene'
import { AppSelectors } from 'store/App'
import { AnalyticsEvents, useLogEventCallback } from 'util/analytics'

import { SideBar } from './components/SideBar'
import { HideSideBarButton } from './components/SideBar/HideSideBarButton'
import { RouteNames } from './linkingConfig'

const Stack = createNativeStackNavigator()
const Tab = createMaterialTopTabNavigator()
const Drawer = createDrawerNavigator()

const StackNavigator = () => {
  return (
    <View style={{ flex: 1 }}>
      <AlertsContainer />
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          contentStyle: {
            backgroundColor: colors.lightBlue2,
          },
        }}
        initialRouteName={RouteNames.Home.Main.Drawer._}
      >
        <Stack.Screen
          name={RouteNames.Home.Main.Drawer._}
          component={DrawerWrappedNavigator}
        />
        <Stack.Screen
          name={RouteNames.Home.Main.ItemDetails}
          component={ItemDetailView}
          options={{
            presentation: 'transparentModal',
            contentStyle: { backgroundColor: 'rgba(59,58,74,0.85)' },
          }}
        />
      </Stack.Navigator>
    </View>
  )
}

const Navigator = () => {
  const logEvent = useLogEventCallback()

  const onFocus = (screen) => {
    switch (screen) {
      case RouteNames.Home.Main.Drawer.Tabs.Chef._:
        logEvent(AnalyticsEvents.chefScreenEntered())
        break
      case RouteNames.Home.Main.Drawer.Tabs.Overview._:
        logEvent(AnalyticsEvents.overviewScreenEntered())
        break
    }
  }

  return (
    <>
      <Tab.Navigator
        initialRouteName={RouteNames.Home.Main.Drawer.Tabs.Overview._}
        screenOptions={{ swipeEnabled: false }}
        sceneContainerStyle={{ backgroundColor: colors.lightBlue2 }}
        tabBar={() => null}
      >
        <Tab.Screen
          name={RouteNames.Home.Main.Drawer.Tabs.Overview._}
          component={OverviewNavigator}
          listeners={{
            focus: () => onFocus(RouteNames.Home.Main.Drawer.Tabs.Overview._),
          }}
        />
        <Tab.Screen
          name={RouteNames.Home.Main.Drawer.Tabs.Chef._}
          component={ChefScene}
          listeners={{
            focus: () => onFocus(RouteNames.Home.Main.Drawer.Tabs.Chef._),
          }}
        />
      </Tab.Navigator>
      <HideSideBarButton />
    </>
  )
}

const DrawerWrappedNavigator = () => {
  const isDrawerMinimized = useSelector(AppSelectors.selectIsDrawerMinimized)

  return (
    <Drawer.Navigator
      screenOptions={{
        drawerType: 'permanent' as 'permanent',
        headerShown: false,
        drawerStyle: {
          width: isDrawerMinimized ? 80 : 200,
          borderRightWidth: 0,
          backgroundColor: colors.darkBlue,
        },
        sceneContainerStyle: {
          backgroundColor: colors.lightBlue2,
        },
      }}
      drawerContent={(props) => <SideBar {...props} />}
      initialRouteName={RouteNames.Home.Main.Drawer.Tabs._}
    >
      <Drawer.Screen
        name={RouteNames.Home.Main.Drawer.Tabs._}
        component={Navigator}
      />
    </Drawer.Navigator>
  )
}

export default StackNavigator
