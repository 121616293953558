import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import Color from 'color'

import colors from 'config/colors'
import { Button } from 'components'
import { Notification } from 'navigation/components/SideBar/NotificationButton/Notification'
import { useNotificationState } from 'navigation/components/SideBar/NotificationButton/NotificationStateContext'
import { NotificationTypes } from 'store/Notifications/enums'
import { AppNotification } from 'store/Notifications/types'
import { AnalyticsEvents, useLogEventCallback } from 'util/analytics'

type Props = {
  notification: AppNotification
  onDismiss(): void
  options?: Alert['options']
}

export function NotificationAlert({
  notification,
  onDismiss,
  options = {},
}: Props) {
  const { onOpen: onOpenPopup } = useNotificationState()

  const logEvent = useLogEventCallback()

  return (
    <Notification
      notification={notification}
      titleSize={16}
      descriptionSize={16}
      onPress={() => {
        onDismiss()
        logEvent(
          AnalyticsEvents.notificationPressed({
            isOnAlert: true,
          }),
        )
      }}
      right={
        <View pointerEvents={'box-none'}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            {options.dismissible ? (
              <Button
                titleId={'notifications.dismiss'}
                style={styles.button}
                innerStyle={styles.buttonInner}
                font={'medium'}
                onPress={onDismiss}
                size={14}
              />
            ) : notification.notification_type === NotificationTypes.COMPANY ? (
              <Button
                titleId={'notifications.open'}
                style={[styles.button, { backgroundColor: colors.green }]}
                innerStyle={styles.buttonInner}
                font={'medium'}
                onPress={() => {
                  onOpenPopup()
                  onDismiss()
                }}
                size={14}
              />
            ) : null}
          </TouchableOpacity>
        </View>
      }
    />
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: Color(colors.gray).fade(0.3).hexa(),
    marginLeft: 50,
    borderRadius: 50,
    width: 140,
  },
  buttonInner: {
    paddingHorizontal: 0,
    paddingVertical: 10,
    borderRadius: 50,
  },
})
