import { ReactNode, useMemo, useState } from 'react'
import { FlatList, Platform, StyleSheet, View } from 'react-native'
import Color from 'color'
import * as Crypto from 'expo-crypto'

import colors from 'config/colors'
import { Button } from 'components'
import { Alert } from 'modules/Alerts/Alert'
import { ControlsProvider, useAlertControls } from 'modules/Alerts/context'
import { createCtx } from 'util/createCtx'

const [useAlerts, Provider] = createCtx<Alert[]>()

const DEFUAL_OPTIONS: Alert['options'] = {
  auto_dismiss_timeout_ms: null,
  dismissible: true,
  sound: false,
}

function AlertsProvider(props: { children: ReactNode }) {
  const { children } = props
  const [alerts, setAlerts] = useState<Alert[]>([])

  const contextValue = useMemo(() => {
    return {
      add: (alert: AlertPayloadBase) => {
        const dispatchedAt = Date.now()
        const uuid = Crypto.randomUUID()

        setAlerts((prevAlerts) => {
          return prevAlerts.concat({
            ...alert,
            options: {
              ...DEFUAL_OPTIONS,
              ...alert.options,
            },
            uuid,
            dispatchedAt,
          })
        })
        return uuid
      },
      dismiss: (uuid: string) =>
        setAlerts((prevAlerts) =>
          prevAlerts.filter((item) => item.uuid !== uuid),
        ),
      dismissAll: () =>
        setAlerts((currentAlert) =>
          currentAlert.filter((alert) => !alert.options.dismissible),
        ),
    }
  }, [])

  return (
    <ControlsProvider value={contextValue}>
      <Provider value={alerts}>{children}</Provider>
    </ControlsProvider>
  )
}

function AlertsContainer() {
  const alerts = useAlerts()
  const { dismiss, dismissAll } = useAlertControls()

  const showDismissAllButton = useMemo(() => {
    const dismissableAlerts = alerts.filter(
      (alert) => alert.options.dismissible,
    )

    return dismissableAlerts.length > 1
  }, [alerts])

  return (
    <View
      pointerEvents={'box-none'}
      style={[
        styles.container,
        Platform.select({
          web: styles.containerWeb,
          default: styles.containerNative,
        }),
      ]}
    >
      <FlatList
        pointerEvents={'box-none'}
        ListHeaderComponent={
          showDismissAllButton && (
            <Button
              onPress={dismissAll}
              iconName={'close'}
              titleId={'notifications.dismissAll'}
              style={styles.dismissAll}
              innerStyle={styles.dismissAllInner}
              font={'medium'}
              size={14}
            />
          )
        }
        data={alerts}
        style={styles.scrollContainer}
        renderItem={({ item: alert }) => (
          <Alert alert={alert} onDismiss={() => dismiss(alert.uuid)} />
        )}
        contentContainerStyle={styles.innerContainer}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    zIndex: 100,
    maxHeight: '100%',
    position: 'absolute',
    top: 40,
    bottom: 50,
  },
  scrollContainer: {
    marginBottom: 5,
    flexGrow: 0,
  },
  containerNative: {
    alignSelf: 'center',
  },
  containerWeb: {
    left: '50%',
    // @ts-ignore this is fine for web-only style
    transform: [{ translateX: '-50%' }],
  },
  innerContainer: {
    backgroundColor: '#14141466',
    borderRadius: 20,
  },
  dismissAll: {
    backgroundColor: Color(colors.veryLightBlue).desaturate(0.25).hexa(),
    marginLeft: 50,
    borderRadius: 50,
    marginVertical: 10,
    alignSelf: 'center',
    width: 200,
  },
  dismissAllInner: {
    paddingVertical: 10,
    borderRadius: 50,
  },
})

export { AlertsProvider, AlertsContainer }
