import { createEntityAdapter } from '@reduxjs/toolkit'

import type { AppNotification } from 'store/Notifications/types'

export const notificationsAdapter = createEntityAdapter<AppNotification>({
  selectId: (notification) => notification.uuid,
  sortComparer: (a, b) => {
    const aNeedsAccepting = !a.accepted_at
    const bNeedsAccepting = !b.accepted_at

    if (aNeedsAccepting && !bNeedsAccepting) {
      return -1
    } else if (bNeedsAccepting && !aNeedsAccepting) {
      return 1
    }

    if (a.read_at && !b.read_at) {
      return 1
    } else if (!a.read_at && b.read_at) {
      return -1
    }

    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  },
})
