import { View, ViewStyle } from 'react-native'
import _ from 'lodash'

import { Text } from 'components'

type OrderDetailSectionHeaderProps = {
  name: string
  textSize?: number
  style?: ViewStyle
}

export const OrderDetailSectionHeader = ({
  name,
  textSize = 14,
  style,
}: OrderDetailSectionHeaderProps) => {
  return (
    <View
      style={[
        {
          backgroundColor: '#aca8a7',
          paddingHorizontal: 4,
          paddingVertical: 8,
          justifyContent: 'space-between',
          flexDirection: 'row',
          paddingRight: 12,
        },
        style,
      ]}
    >
      <Text
        color="white"
        font="medium"
        size={textSize}
        style={{ marginHorizontal: 8, textTransform: 'uppercase', flex: 1 }}
      >
        {name}
      </Text>
    </View>
  )
}
