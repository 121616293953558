import { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import usePrevious from 'react-use/lib/usePrevious'

import colors from 'config/colors'
import { Button } from 'components'
import { NotificationActions } from 'store/Notifications'
import { selectIsFetching } from 'store/util/functions'

type Props = {
  onPressMarkAllAsRead: () => void | Promise<void>
  onPressMarkAllAsReadAndAccepted: () => void | Promise<void>
  hasUnread?: boolean
  hasUnaccepted?: boolean
}

export const ControlButtons = ({
  onPressMarkAllAsRead,
  hasUnread,
  hasUnaccepted,
  onPressMarkAllAsReadAndAccepted,
}: Props) => {
  const [isFetching, setIsFetching] = useState(false)
  const [markAcceptedFetching, setMarkAcceptedFetching] = useState(false)
  const isMarkAsReadFetching = useSelector((state) =>
    selectIsFetching(state, NotificationActions.markAsRead),
  )
  const isMarkAsReadFetchingPrev = usePrevious(isMarkAsReadFetching)

  useEffect(() => {
    if (!isMarkAsReadFetching && isMarkAsReadFetchingPrev && isFetching) {
      setIsFetching(false)
    }
  }, [isMarkAsReadFetching, isMarkAsReadFetchingPrev, isFetching])

  const markRead = async () => {
    setIsFetching(true)
    await onPressMarkAllAsRead()
  }

  const markAccepted = async () => {
    setMarkAcceptedFetching(true)
    await onPressMarkAllAsReadAndAccepted()
    setMarkAcceptedFetching(false)
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }}
    >
      {hasUnread && (
        <Button
          style={{
            marginRight: 10,
            backgroundColor: '#3bc3ed',
          }}
          titleId={'notifications.markAllAsRead'}
          font={'medium'}
          iconName={'eye'}
          isFetching={isFetching}
          disabled={isFetching || markAcceptedFetching}
          onPress={markRead}
          size={12}
        />
      )}
      {hasUnaccepted && (
        <Button
          style={{
            backgroundColor: colors.brightGreen,
          }}
          titleId={'notifications.acceptAll'}
          font={'medium'}
          iconName={'check-all'}
          isFetching={markAcceptedFetching}
          disabled={isFetching || markAcceptedFetching}
          onPress={markAccepted}
          size={12}
        />
      )}
    </View>
  )
}
export default ControlButtons
