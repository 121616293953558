import { DateTime } from 'luxon'

import {
  ItemPreparationStatuses,
  OrderInternalStatuses,
  OrderStatuses,
} from './enums'
import type { Order, OrderItem, StartPreparationStatus } from './types'

export const getStartPreparationStatusWeight = (
  startPreparationStatus: StartPreparationStatus,
): number => {
  switch (startPreparationStatus) {
    case 'late':
      return 4
    case 'risk_late':
      return 3
    case 'now':
      return 2
    case 'early':
      return 1
    default:
      return 0
  }
}

export const sortByStartPreparationStatus = (a: Order, b: Order) => {
  return (
    getStartPreparationStatusWeight(b.start_preparation_status) -
    getStartPreparationStatusWeight(a.start_preparation_status)
  )
}

type OrderAndItems = {
  order: Order
  items: OrderItem[]
}

export const getOrderInternalStatusWeight = ({
  order,
  items,
}: OrderAndItems): number => {
  const orderInternalStatus = getOrderInternalStatus(order, items)

  switch (orderInternalStatus) {
    case OrderInternalStatuses.BEING_PREPARED:
      return 4
    case OrderInternalStatuses.NOT_BEING_PREPARED:
      return 3
    case OrderInternalStatuses.PREPARED:
      return 2
    case OrderInternalStatuses.SCHEDULED:
      return 1
    case OrderInternalStatuses.CANCELLED:
    default:
      return 0
  }
}

export const sortByOrderInternalStatus = (
  a: OrderAndItems,
  b: OrderAndItems,
) => {
  return getOrderInternalStatusWeight(b) - getOrderInternalStatusWeight(a)
}

export const checkIfScheduledOrder = (order) => {
  if (!order) return false

  const scheduledAppearanceTime = DateTime.fromISO(
    order.scheduled_appearance_time,
  )

  return (
    scheduledAppearanceTime.isValid && scheduledAppearanceTime > DateTime.now()
  )
}

export const getOrderInternalStatus = (
  order: Order,
  items: OrderItem[],
): OrderInternalStatuses => {
  if (order.order_status === OrderStatuses.CANCELLED) {
    return OrderInternalStatuses.CANCELLED
  }

  const isAllPrepared = items.every((item) =>
    [
      ItemPreparationStatuses.PREPARED,
      ItemPreparationStatuses.COMPLETED,
    ].includes(item.status),
  )

  if (isAllPrepared) {
    return OrderInternalStatuses.PREPARED
  }

  const cookableItems = items.filter((item) => item.cookable)

  const isAllNotPreparing = cookableItems.some(
    (item) => item.status === ItemPreparationStatuses.NOT_BEING_PREPARED,
  )

  if (isAllNotPreparing) {
    if (checkIfScheduledOrder(order)) {
      return OrderInternalStatuses.SCHEDULED
    }

    return OrderInternalStatuses.NOT_BEING_PREPARED
  }

  return OrderInternalStatuses.BEING_PREPARED
}
