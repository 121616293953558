import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useMount from 'react-use/lib/useMount'

import { AnalyticsActions } from 'store/Analytics'
import { useAppDispatch } from 'store/hooks'

import events from './events'
import type { Payload } from 'store/Analytics/types'

type ScreenName = 'chef' | 'overview' | 'itemDetails' | 'settings' | 'crash'

const getEventPayload = (screenName: ScreenName) => {
  switch (screenName) {
    case 'chef':
      return events.chefScreenEntered()
    case 'overview':
      return events.overviewScreenEntered()
    case 'itemDetails':
      return events.itemDetailsScreenEntered()
    case 'settings':
      return events.settingsScreenEntered()
    case 'crash':
      return events.crashScreenEntered()
  }
}

export const useEnterScreen = (screenName: ScreenName) => {
  const dispatch = useAppDispatch()

  useMount(() => {
    const eventPayload = getEventPayload(screenName)

    if (!eventPayload) return

    dispatch(AnalyticsActions.logEvent(eventPayload))
  })
}

export const useLogEventCallback = () => {
  const dispatch = useAppDispatch()

  return useCallback(
    (payload: Payload) => {
      dispatch(AnalyticsActions.logEvent(payload))
    },
    [dispatch],
  )
}
