import { StyleSheet } from 'react-native'

import { ErrorText } from 'components'

export const SettingError = () => {
  return <ErrorText id={'settings.error'} style={styles.errorText} />
}

const styles = StyleSheet.create({
  errorText: {
    margin: 20,
  },
})
