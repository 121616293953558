import { StyleProp, View, ViewStyle } from 'react-native'
import _ from 'lodash'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import Text from 'components/Text'
import { OrderDeliveryStatuses } from 'store/Orders/enums'

import { DeliveryStatusInterfaces } from './constants'
import { styles } from './styles'

type DeliveryStatusBadgeProps = {
  status?: OrderDeliveryStatuses
  geoVerified?: boolean
  style?: StyleProp<ViewStyle>
}

export const DeliveryStatusBadge = ({
  status,
  geoVerified,
  style,
}: DeliveryStatusBadgeProps) => {
  const statusInterface = DeliveryStatusInterfaces[status]

  if (!statusInterface) return null

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: statusInterface.bgColor,
        },
        style,
      ]}
    >
      {geoVerified != null && (
        <View style={styles.content}>
          <MaterialCommunityIcons
            name={
              geoVerified ? 'map-marker-check' : 'map-marker-remove-variant'
            }
            size={20}
            color={geoVerified ? colors.green : colors.error}
          />
        </View>
      )}
      <View style={styles.content}>
        <Text
          color={statusInterface.color ?? colors.white}
          font="bold"
          size={12}
          style={styles.label}
          id={statusInterface.translationKey}
        />
      </View>
    </View>
  )
}
