import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import Color from 'color'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import { DEFAULT_ACTIVE_OPACITY } from 'config/constants'
import { Text } from 'components'
import { getLabelKeyForOrderKind } from 'util/helpers'

import type { Category, OrderKind } from 'store/Settings/types'

interface ScreenItemProps {
  name: string
  isSelected: boolean
  categories?: Category[]
  orderKinds?: OrderKind[]
  onPress(): void
}

export function ScreenItem(props: ScreenItemProps) {
  const { name, isSelected, orderKinds, categories, onPress } = props
  const { t } = useTranslation()

  return (
    <TouchableOpacity
      style={[styles.container, isSelected && styles.containerSelected]}
      activeOpacity={DEFAULT_ACTIVE_OPACITY}
      onPress={onPress}
    >
      <View>
        <Text font="bold" size={18} style={{ marginBottom: 10 }}>
          {name}
        </Text>
        <View style={{ paddingLeft: 15 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <MaterialCommunityIcons
              name={'food'}
              color={colors.lightBlue}
              size={30}
              style={{ marginRight: 15 }}
            />
            <View>
              <Text
                color={colors.lightBlue}
                id={'device.selectScreen.item.defaultCategories'}
                font={'bold'}
              />
              <Text>
                {categories && categories.length
                  ? categories.join(', ')
                  : t('device.selectScreen.item.none')}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 15,
            }}
          >
            <MaterialCommunityIcons
              name={'silverware-variant'}
              color={colors.lightBlue}
              size={30}
              style={{ marginRight: 15 }}
            />
            <View>
              <Text
                color={colors.lightBlue}
                id={'device.selectScreen.item.defaultOrderKinds'}
                font={'bold'}
              />
              <Text>
                {orderKinds && orderKinds.length
                  ? orderKinds
                      .map((orderKind) => t(getLabelKeyForOrderKind(orderKind)))
                      .join(', ')
                  : t('device.selectScreen.item.none')}
              </Text>
            </View>
          </View>
        </View>
      </View>
      {isSelected && (
        <MaterialCommunityIcons name="check" size={36} color={colors.green} />
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
    marginVertical: 5,
  },
  containerSelected: {
    backgroundColor: Color(colors.green).fade(0.6).hexa(),
  },
})
