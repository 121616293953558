import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import colors from 'config/colors'
import { Link, Text } from 'components'
import { AuthLayout } from 'modules/Auth/components/AuthLayout'
import { RouteNames } from 'navigation/linkingConfig'

import { ForgotPasswordForm } from './components'

export const ForgotPasswordScreen = () => {
  const { t } = useTranslation()

  const [isSuccess, setIsSuccess] = useState(false)

  return (
    <AuthLayout>
      <Text
        id="authScreen.forgotPassword.title"
        font="black"
        size={32}
        color={colors.lightBlue}
        align={'center'}
      />
      <Text
        id={
          isSuccess
            ? 'authScreen.forgotPassword.subtitleSuccess'
            : 'authScreen.forgotPassword.subtitle'
        }
        style={[styles.subtitle, { marginBottom: isSuccess ? 20 : 0 }]}
        size={18}
        align={'center'}
        color={colors.gray}
      />
      {!isSuccess && (
        <ForgotPasswordForm onSuccess={() => setIsSuccess(true)} />
      )}
      <Link to={RouteNames.Auth.Login} textStyle={styles.link}>
        {t('authScreen.login.linkLabel')}
      </Link>
    </AuthLayout>
  )
}

const styles = StyleSheet.create({
  subtitle: {
    marginTop: 8,
  },
  link: {
    textAlign: 'center',
  },
})

export default ForgotPasswordScreen
