import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionList, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import { useLinkProps } from '@react-navigation/native'
import { FlashList } from '@shopify/flash-list'

import colors from 'config/colors'
import { ItemSectionHeader } from 'modules/ChefScene/components'
import { EmptyList } from 'modules/ChefScene/components/EmptyList'
import { OrderCell } from 'modules/OverviewScene/components'
import { RouteNames } from 'navigation/linkingConfig'
import { OrderSelectors } from 'store/Orders'
import { useFormatDateTime } from 'util/helpers'
import { usePullToRefreshOrder } from 'util/hooks'

import { SelectedOrderContext } from './context'

const NavItem = (props) => {
  const { order, isScheduled } = props

  const selectedUUID = useContext(SelectedOrderContext)

  const navigationProps = useLinkProps({
    to: {
      screen: RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails,
      params: { uuid: order.uuid },
    },
  })

  return (
    <OrderCell
      order={order}
      isSelected={selectedUUID === order.uuid}
      isScheduled={isScheduled}
      {...navigationProps}
    />
  )
}

const Scheduled = () => {
  const { t } = useTranslation()

  const [today, rest] = useSelector(OrderSelectors.selectScheduledGrouped)
  const [isFetching, onRefresh] = usePullToRefreshOrder()

  const formatDateTime = useFormatDateTime()

  const sections = useMemo(() => {
    return [
      ...(!isEmpty(today)
        ? [{ data: today, date: new Date(), isToday: true }]
        : []),
      ...rest.map((group) => ({
        data: group.items,
        date: group.date,
        isToday: false,
      })),
    ]
  }, [rest])

  return (
    <View style={styles.container}>
      {!isEmpty(sections) ? (
        <SectionList
          sections={sections}
          renderItem={({ item }) => <NavItem order={item} isScheduled={true} />}
          renderSectionHeader={({ section }) => {
            return (
              <ItemSectionHeader
                label={
                  section.isToday
                    ? t('common.today')
                    : formatDateTime(section.date, {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                      })
                }
                color={colors.ui.statuses.scheduled}
              />
            )
          }}
          onRefresh={onRefresh}
          refreshing={isFetching}
          style={styles.sectionList}
        />
      ) : (
        <EmptyList onRefresh={onRefresh} isFetching={isFetching} />
      )}
    </View>
  )
}

const Prepared = () => {
  const { t } = useTranslation()

  const preparedOrders = useSelector<unknown, Order[]>(
    OrderSelectors.selectPrepared,
  )
  const [isFetching, onRefresh] = usePullToRefreshOrder()

  return (
    <View style={styles.container}>
      <View style={styles.flastListContainer}>
        <ItemSectionHeader
          label={t('overview.drawer.preparedOrders')}
          color={colors.ui.statuses.prepared}
        />
        {!isEmpty(preparedOrders) ? (
          <FlashList
            estimatedItemSize={100}
            data={preparedOrders}
            onRefresh={onRefresh}
            refreshing={isFetching}
            renderItem={({ item }) => <NavItem order={item} />}
            keyExtractor={(item) => item.uuid}
          />
        ) : (
          <EmptyList onRefresh={onRefresh} isFetching={isFetching} />
        )}
      </View>
    </View>
  )
}

const New = () => {
  const { t } = useTranslation()

  const newOrders = useSelector<unknown, Order[]>(
    OrderSelectors.selectNotBeingPrepared,
  )
  const [isFetching, onRefresh] = usePullToRefreshOrder()

  return (
    <View style={styles.container}>
      <View style={styles.flastListContainer}>
        <ItemSectionHeader
          label={t('overview.drawer.newOrders')}
          color={colors.ui.statuses.active}
        />
        {!isEmpty(newOrders) ? (
          <FlashList
            estimatedItemSize={100}
            data={newOrders}
            onRefresh={onRefresh}
            refreshing={isFetching}
            renderItem={({ item }) => <NavItem order={item} />}
            keyExtractor={(item) => item.uuid}
          />
        ) : (
          <EmptyList onRefresh={onRefresh} isFetching={isFetching} />
        )}
      </View>
    </View>
  )
}

const Cooking = () => {
  const { t } = useTranslation()

  const cookingOrders = useSelector<unknown, Order[]>(
    OrderSelectors.selectBeingPrepared,
  )
  const [isFetching, onRefresh] = usePullToRefreshOrder()

  return (
    <View style={styles.container}>
      <View style={styles.flastListContainer}>
        <ItemSectionHeader
          label={t('overview.drawer.cookingOrders')}
          color={colors.ui.statuses.cooking}
        />
        {!isEmpty(cookingOrders) ? (
          <FlashList
            estimatedItemSize={100}
            data={cookingOrders}
            onRefresh={onRefresh}
            refreshing={isFetching}
            renderItem={({ item }) => <NavItem order={item} />}
            keyExtractor={(item) => item.uuid}
          />
        ) : (
          <EmptyList onRefresh={onRefresh} isFetching={isFetching} />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    marginHorizontal: 16,
    marginTop: 12,
    marginBottom: 12,
    flexDirection: 'row',
  },
  flastListContainer: {
    flex: 1,
  },
  sectionList: {
    flex: 1,
    paddingVertical: 4,
  },
})

export { Scheduled, Prepared, New, Cooking }
