import { ActivityIndicator } from 'react-native'

import colors from 'config/colors'

export function Spinner() {
  return (
    <ActivityIndicator
      size={'large'}
      color={colors.primary}
      style={{ alignSelf: 'center', marginTop: 100 }}
    />
  )
}
