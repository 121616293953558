import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { toast } from 'components/Snackbar'
import { AppActions, AppSelectors } from 'store/App'
import { useAppDispatch } from 'store/hooks'

type Props = {
  children?: React.ReactNode
}

export function ToastErrorHandler({ children }: Props) {
  const { t } = useTranslation()
  const errors = useSelector(AppSelectors.selectErrors)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const latestError = errors.at(0)

    if (!latestError) return

    toast({
      type: 'error',
      titleKey: 'common.error',
      textBody: latestError || t('errors.unexpectedError'),
      onShow: () => {
        dispatch(AppActions.dequeueError())
      },
    })
  }, [errors])

  return <>{children}</>
}
