import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import Color from 'color'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import { Text } from 'components'
import { NotificationPriority } from 'store/Notifications/enums'

const DEFAULT_SIZE = 40

type IconProps = {
  color: string
  size?: number
}

function Low({ color, size = DEFAULT_SIZE }: IconProps) {
  return (
    <View
      style={{
        borderRadius: 1000,
        backgroundColor: colors.white,
        padding: 4,
        width: size,
        height: size,
      }}
    >
      <MaterialCommunityIcons
        name="information-variant"
        color={color}
        size={size - 8}
      />
    </View>
  )
}

function Normal({ color, size = DEFAULT_SIZE }: IconProps) {
  return (
    <View
      style={{
        borderRadius: 1000,
        backgroundColor: colors.white,
        padding: 4,
        width: size,
        height: size,
      }}
    >
      <MaterialCommunityIcons name="check" color={color} size={size - 8} />
    </View>
  )
}

function High({ color, size = DEFAULT_SIZE }: IconProps) {
  return (
    <View
      style={{
        padding: 2,
        width: size,
        height: size,
      }}
    >
      <Svg
        viewBox="-1 -1 12 12"
        width={size}
        height={size}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Path
          fill={colors.white}
          stroke={colors.white}
          strokeWidth={2}
          strokeLinejoin="round"
          d="M 0 5 L 5 0 L 10 5 L 5 10 Z"
        />
      </Svg>
      <MaterialCommunityIcons
        name="exclamation"
        color={color}
        size={size - 4}
        style={{ zIndex: 1 }}
      />
    </View>
  )
}

function Critical({ color, size = DEFAULT_SIZE }: IconProps) {
  return (
    <View style={{ padding: 2, width: size, height: size }}>
      <Svg
        viewBox="-1 -1 12 12"
        width={size}
        height={size}
        style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
      >
        <Path
          fill={colors.white}
          stroke={colors.white}
          strokeWidth={2}
          strokeLinejoin="round"
          d="M 10 10 L 5 0 L 0 10 Z"
        />
      </Svg>
      <MaterialCommunityIcons
        name="exclamation"
        color={color}
        size={size - 4}
        style={{ zIndex: 1, marginTop: 2 }}
      />
    </View>
  )
}

export const PriorityConfig: Record<
  NotificationPriority,
  {
    color: string
    Icon: React.FC<IconProps>
  }
> = {
  [NotificationPriority.LOW]: {
    color: colors.ui.notifications.low,
    Icon: Low,
  },
  [NotificationPriority.NORMAL]: {
    color: colors.ui.notifications.normal,
    Icon: Normal,
  },
  [NotificationPriority.HIGH]: {
    color: colors.ui.notifications.high,
    Icon: High,
  },
  [NotificationPriority.CRITICAL]: {
    color: colors.ui.notifications.critical,
    Icon: Critical,
  },
}

type Props = {
  priority: NotificationPriority
  count?: number
  size?: number
  isSelected?: boolean
}

export function PriorityIcon({
  priority,
  count,
  size = DEFAULT_SIZE,
  isSelected = false,
}: Props) {
  const config = PriorityConfig[priority]

  if (!config) return

  const { Icon } = config

  const isCountable = count != null

  return (
    <View
      style={{
        borderRadius: 4,
        backgroundColor: config.color,
        opacity: !isSelected ? 0.2 : 1,
        padding: 4,
        width: size,
        height: size,
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Icon size={isCountable ? size - 20 : size - 8} color={config.color} />
      {isCountable && (
        <Text color={colors.white} size={10}>
          {count}
        </Text>
      )}
    </View>
  )
}
