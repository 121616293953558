import { StyleSheet, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { WebImage } from 'components'
import { OrderKinds } from 'store/Orders/enums'
import { getIconNameForOrderKind } from 'util/helpers'

import { CountDownLabel } from './CountDownLabel'

type Props = {
  latestPickupTime?: string
  orderKind: OrderKinds
  deliveryProviderLogoURI?: string
  isBlank?: boolean
}

const Content = ({
  orderKind,
  deliveryProviderLogoURI,
  latestPickupTime,
}: Props) => {
  return (
    <View style={{ alignItems: 'center' }}>
      <View style={styles.icon}>
        <MaterialCommunityIcons
          name={getIconNameForOrderKind(orderKind)}
          color={'white'}
          size={32}
        />
      </View>
      {Boolean(deliveryProviderLogoURI) && (
        <WebImage
          uri={deliveryProviderLogoURI}
          width={40}
          height={40}
          shape={'rect'}
          borderRadius={4}
          containerStyle={styles.logo}
        />
      )}
      {Boolean(latestPickupTime) && (
        <CountDownLabel latestPickupTime={latestPickupTime} />
      )}
    </View>
  )
}

const Delivery = ({ isBlank, ...props }: Props) => {
  return (
    <View style={styles.container}>{!isBlank && <Content {...props} />}</View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'black',
    width: 60,
    alignItems: 'center',
    padding: 4,
    justifyContent: 'space-between',
  },
  icon: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    marginTop: 5,
    borderColor: 'white',
    borderWidth: StyleSheet.hairlineWidth,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default Delivery
