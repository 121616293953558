import { I18nManager } from 'react-native'
import i18n from 'i18next'
import { Settings as LuxonSettings } from 'luxon'
import { put, select, takeLatest } from 'redux-saga/effects'

import {
  convertHostNameToBaseUrl,
  DEFAULT_BASE_URL,
  setAuthenticationHeaders,
  setBaseURL,
  setDeviceHeaders,
} from 'API/util/functions'
import AnalyticsActions from 'store/Analytics/actions'
import * as AuthActions from 'store/Auth/actions'
import NotificationActions from 'store/Notifications/actions'
import OrderActions from 'store/Orders/actions'
import SettingActions from 'store/Settings/actions'
import { UserActions } from 'store/User'
import { isWeb } from 'util/helpers/constants'
import { RTL_LOCALES } from 'util/localization'

import AppActions from './actions'
import AppSelectors from './selectors'

function* resetApi() {
  setBaseURL(DEFAULT_BASE_URL)
  setAuthenticationHeaders()
  setDeviceHeaders()
}

export function* setLocale() {
  const locale = yield select(AppSelectors.selectLocale)

  if (!locale) {
    return
  }

  i18n.changeLanguage(locale)
  if (isWeb) {
    document.documentElement.lang = locale
  }

  LuxonSettings.defaultLocale = locale

  const isRTL = RTL_LOCALES.includes(locale)
  if (I18nManager.isRTL !== isRTL) {
    I18nManager.forceRTL(isRTL)
  }
}

export function* onEnvironmentSelected() {
  const environment = yield select(AppSelectors.selectEnvironment)

  if (environment) {
    setBaseURL(convertHostNameToBaseUrl(environment.hostname))

    yield put(UserActions.fetchAccount())
    yield put(SettingActions.fetch())
    yield put(AnalyticsActions.init())
    yield put(OrderActions.fetch())
    yield put(NotificationActions.fetch())
  }
}

function* rootSaga() {
  yield takeLatest(AppActions.setEnvironment, onEnvironmentSelected)
  yield takeLatest(AppActions.setLocale, setLocale)
  yield takeLatest(AuthActions.signOut, resetApi)
}

export default rootSaga
