import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { intersection, xor } from 'lodash'

import { HorizontalDivider, Text, withErrorBoundary } from 'components'
import { RouteNames } from 'navigation/linkingConfig'
import { DeviceSelectors } from 'store/Device'
import { useAppDispatch } from 'store/hooks'
import { OrderSelectors } from 'store/Orders'
import { SettingActions, SettingSelectors } from 'store/Settings'
import { AnalyticsEvents, useLogEventCallback } from 'util/analytics'

import { CheckBox, CheckBoxSection } from './components/CheckBox'
import Setting from './components/Setting'
import { SettingError } from './components/SettingError'
import type { Category } from 'store/Settings/types'

function NoCategoriesPlaceholder() {
  return (
    <View>
      <Text id={'settings.categories.noContent.title'} size={16} />
    </View>
  )
}

interface ScreenCategoriesProps {
  categories: Category[]
}

function ScreenCategories(props: ScreenCategoriesProps) {
  const { categories } = props
  const awaitingItemsByCategory = useSelector(
    OrderSelectors.selectItemsCountByCategory,
  )
  const screen = useSelector(DeviceSelectors.selectScreen)
  const { t } = useTranslation()

  return (
    <CheckBoxSection
      title={t('settings.categories.screen.title', {
        screenName: screen.name,
        amount: categories.length,
      })}
      subtitleKey={'settings.categories.screen.subtitle'}
    >
      {categories.length ? (
        categories.map((category: string, index: number) => {
          const isLast = index === categories.length - 1

          return (
            <Fragment key={category}>
              <CheckBox
                label={category}
                isSelected={true}
                isDisabled={true}
                awaitingItems={awaitingItemsByCategory[category]}
              />
              {!isLast && <HorizontalDivider style={styles.divider} />}
            </Fragment>
          )
        })
      ) : (
        <NoCategoriesPlaceholder />
      )}
    </CheckBoxSection>
  )
}

interface OtherCategoriesProps {
  categories: Category[]
  hasScreen: boolean
}

function OtherCategories(props: OtherCategoriesProps) {
  const { categories, hasScreen } = props
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const selectedCategories = useSelector(
    SettingSelectors.selectUserSelectedCategories,
  )
  const logEvent = useLogEventCallback()

  const isSelectAllEnabled = useSelector(
    SettingSelectors.selectIsSelectedAllCategories,
  )
  const awaitingItemsByCategory = useSelector(
    OrderSelectors.selectItemsCountByCategory,
  )

  const onToggle = (category: string) => {
    logEvent(AnalyticsEvents.categoriesFilterUpdated({ category }))

    if (isSelectAllEnabled) {
      dispatch(SettingActions.toggleAllCategories(false))
      dispatch(
        SettingActions.setSelectedCategories(xor(categories, [category])),
      )
    } else {
      const newSelection = xor(selectedCategories, [category])
      const isAllSelected = categories.every((item) =>
        newSelection.includes(item),
      )
      if (isAllSelected) {
        dispatch(SettingActions.toggleAllCategories(true))
        dispatch(SettingActions.setSelectedCategories([]))
      } else {
        dispatch(SettingActions.setSelectedCategories(newSelection))
      }
    }
  }

  const onToggleAll = () => {
    const newValue = !isSelectAllEnabled

    dispatch(SettingActions.toggleAllCategories(newValue))
    dispatch(SettingActions.setSelectedCategories([]))

    logEvent(
      AnalyticsEvents.categoriesFilterUpdated({
        category: newValue ? 'all' : 'none',
      }),
    )
  }

  return (
    <CheckBoxSection
      title={t(
        hasScreen
          ? 'settings.categories.other.title'
          : 'settings.categories.other.titleNoScreen',
        {
          amount: categories.length,
        },
      )}
      subtitleKey={
        hasScreen
          ? 'settings.categories.other.subtitle'
          : 'settings.categories.other.subtitleNoScreen'
      }
    >
      {categories.length > 0 ? (
        <>
          <CheckBox
            isSelected={isSelectAllEnabled}
            onPress={onToggleAll}
            label={t('common.all')}
          />
          <HorizontalDivider style={styles.divider} />
          {categories.map((category: string, index: number) => {
            const isLast = index === categories.length - 1

            return (
              <Fragment key={category}>
                <CheckBox
                  label={category}
                  isSelected={
                    isSelectAllEnabled || selectedCategories.includes(category)
                  }
                  awaitingItems={awaitingItemsByCategory[category]}
                  onPress={() => onToggle(category)}
                />
                {!isLast && <HorizontalDivider style={styles.divider} />}
              </Fragment>
            )
          })}
        </>
      ) : (
        <NoCategoriesPlaceholder />
      )}
    </CheckBoxSection>
  )
}

const CategoriesSetting = () => {
  const screenCategories = useSelector(SettingSelectors.selectScreenCategories)
  const categories = useSelector(SettingSelectors.selectAllCategories)
  const screen = useSelector(DeviceSelectors.selectScreen)

  const values = useMemo(() => {
    const screenDefaults = intersection(categories, screenCategories)
    const otherCategories = xor(categories, screenDefaults)

    return { screenDefaults, otherCategories }
  }, [screenCategories, categories])

  const hasScreen = Boolean(screen)

  return (
    <Setting
      titleKey={'settings.categories.title'}
      subTitleKey={'settings.categories.subtitle'}
      iconName="food"
    >
      <View style={{ flexDirection: 'row' }}>
        {hasScreen && <ScreenCategories categories={values.screenDefaults} />}
        <OtherCategories
          categories={values.otherCategories}
          hasScreen={hasScreen}
        />
      </View>
    </Setting>
  )
}

const styles = StyleSheet.create({
  divider: {
    marginVertical: 16,
  },
})

export default withErrorBoundary(CategoriesSetting, {
  screen: RouteNames.Home.Settings.Categories,
  key: 'settings-categories',
  fallback: <SettingError />,
})
