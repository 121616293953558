import { StyleSheet } from 'react-native'

import colors from 'config/colors'

export default StyleSheet.create({
  buttonGroup: {},
  container: {
    flex: 1,
    backgroundColor: colors.lightBlue2,
    marginHorizontal: 16,
    marginTop: 12,
    marginBottom: 12,
    flexDirection: 'row',
  },
  flashListContainer: {
    flex: 1,
  },
  sectionList: {
    flex: 1,
    paddingVertical: 4,
  },
})
