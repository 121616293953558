import { useDeferredValue, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { search } from 'fast-fuzzy'
import { isEmpty } from 'lodash'
import { FlashList } from '@shopify/flash-list'

import colors from 'config/colors'
import { Button, Input, Link, Text } from 'components'
import { AuthBackground } from 'modules/Auth/components/AuthBackground'
import { RouteNames } from 'navigation/linkingConfig'
import { AuthActions } from 'store/Auth'
import { useAppDispatch } from 'store/hooks'
import { UserActions, UserSelectors } from 'store/User'

import { Store } from './Store'
import type { Account } from 'store/User/types'

const searchThreshold = 0.9

export const SelectStoreScreen = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [selectedAccountUUID, setAccountUUID] = useState<
    Account['uuid'] | null
  >(null)
  const [query, setQuery] = useState('')

  const deferredQuery = useDeferredValue(query)

  const accounts = useSelector(UserSelectors.selectTmpAccounts)

  const filteredAccounts = useMemo(() => {
    const accountsAsArray = Object.values(accounts)

    if (!deferredQuery) return accountsAsArray

    return search(deferredQuery, accountsAsArray, {
      keySelector: (account: Account) =>
        [account.name, account.address?.line_1].filter(Boolean).join(),
      threshold: searchThreshold,
    }).slice()
  }, [accounts, deferredQuery])

  return (
    <AuthBackground>
      <View style={[styles.formContainer]}>
        <Text
          id="authScreen.selectStore.title"
          font="black"
          size={32}
          color={colors.lightBlue}
          align={'center'}
        />
        <Text
          id="authScreen.selectStore.subtitle"
          style={styles.subtitle}
          size={16}
          align={'center'}
          color={colors.gray}
        />
        <Input
          containerStyle={styles.input}
          value={query}
          onChangeText={setQuery}
          placeholder={t('common.search')}
        />
        <View style={[styles.list]}>
          {!isEmpty(filteredAccounts) ? (
            <FlashList
              nestedScrollEnabled
              estimatedItemSize={100}
              data={filteredAccounts}
              extraData={selectedAccountUUID}
              renderItem={({ item: account }) => {
                return (
                  <Store
                    {...account}
                    onPress={setAccountUUID}
                    isSelected={selectedAccountUUID === account.uuid}
                  />
                )
              }}
              contentInset={{ bottom: 75 }}
              keyExtractor={(account) => account.uuid}
            />
          ) : (
            <Text
              id="authScreen.selectStore.noData"
              style={{ marginTop: 20 }}
              size={16}
              align={'center'}
              color={colors.gray}
            />
          )}
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <Button
          onPress={() => {
            dispatch(UserActions.selectAccount(accounts[selectedAccountUUID]))
          }}
          style={styles.button}
          innerStyle={styles.buttonInner}
          titleId="authScreen.selectStore.submitButton.label"
          disabled={!selectedAccountUUID}
        />
        <Link
          onPress={() => {
            dispatch(AuthActions.signOut())
          }}
          to={RouteNames.Auth.Login}
          textStyle={styles.link}
          iconName="chevron-left"
        >
          {t('authScreen.login.linkLabel')}
        </Link>
      </View>
    </AuthBackground>
  )
}

const styles = StyleSheet.create({
  list: {
    flexGrow: 1,
    flexShrink: 1,
  },
  subtitle: {
    marginTop: 8,
  },
  input: {
    borderRadius: 0,
    borderWidth: 0,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginTop: 8,
    marginBottom: 8,
  },
  formContainer: {
    width: '100%',
    backgroundColor: 'white',
    padding: 32,
    borderRadius: 8,
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: 1000,
    alignSelf: 'center',
    marginTop: 16,
    overflow: 'hidden',
  },
  buttonContainer: {
    width: '100%',
    alignItems: 'stretch',
    maxWidth: 1000,
    alignSelf: 'center',
    marginBottom: 16,
  },
  button: { backgroundColor: colors.main, marginTop: 20 },
  buttonInner: { paddingVertical: 12, paddingHorizontal: 12 },
  link: {
    textAlign: 'center',
    marginTop: 12,
    color: 'white',
    paddingVertical: 4,
  },
})
