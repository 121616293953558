import { useTranslation } from 'react-i18next'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { ForgotPasswordScreen } from 'modules/Auth/screens/ForgotPassword'
import { LoginScreen } from 'modules/Auth/screens/Login'

import { RouteNames } from './linkingConfig'

const Stack = createNativeStackNavigator()

export const AuthenticationNavigator = () => {
  const { t } = useTranslation()

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
        animation: 'none',
      }}
      initialRouteName={RouteNames.Auth.Login}
    >
      <Stack.Screen
        name={RouteNames.Auth.Login}
        component={LoginScreen}
        options={{
          title: t('authScreen.login.title'),
        }}
      />
      <Stack.Screen
        name={RouteNames.Auth.ForgotPassword}
        component={ForgotPasswordScreen}
        options={{
          title: t('authScreen.forgotPassword.title'),
        }}
      />
    </Stack.Navigator>
  )
}
