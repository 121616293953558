import { createAction } from '@reduxjs/toolkit'

import type { DefaultFailActionPayload, Order, OrderItem } from '../types'

const fetch = createAction('ORDERS/FETCH/REQUEST')
const fetchSuccess = createAction<{ orders: Order[]; orderItems: OrderItem[] }>('ORDERS/FETCH/SUCCESS')
const fetchFailure = createAction<DefaultFailActionPayload>('ORDERS/FETCH/FAILURE')

const fetchById = createAction<{ id: Order['uuid'] }>('ORDERS/FETCH_BY_ID/REQUEST')
const fetchByIdSuccess = createAction<{ order: Order; orderItems: OrderItem[] }>('ORDERS/FETCH_BY_ID/SUCCESS')
const fetchByIdFailure = createAction<DefaultFailActionPayload>('ORDERS/FETCH_BY_ID/FAILURE')

const updateOrdersItemStatuses = createAction<{
  id: Order['uuid']
  status: OrderItem['status']
}>('ORDERS/UPDATE_ORDER_ITEMS_STATUS/REQUEST')
const updateOrdersItemStatusesSuccess = createAction('ORDERS/UPDATE_ORDER_ITEMS_STATUS/SUCCESS')
const updateOrdersItemStatusesFailure = createAction<DefaultFailActionPayload>(
  'ORDERS/UPDATE_ORDER_ITEMS_STATUS/FAILURE',
)

const printItem = createAction('ORDERS/PRINT_ITEM_LABEL')
const printOrder = createAction('ORDERS/PRINT_ORDER_LABEL')

export default {
  fetch,
  fetchSuccess,
  fetchFailure,

  fetchById,
  fetchByIdSuccess,
  fetchByIdFailure,

  updateOrdersItemStatuses,
  updateOrdersItemStatusesSuccess,
  updateOrdersItemStatusesFailure,

  printItem,
  printOrder,
}
