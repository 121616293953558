import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from 'store/hooks'
import * as UserThunks from 'store/User/thunks'

import { SUPPORTED_LOCALES } from './constants'

export function getSelectedLocale(selectedLocale: string | null) {
  return (
    SUPPORTED_LOCALES.find((locale) => locale === selectedLocale) ??
    SUPPORTED_LOCALES.find(
      (locale) => locale.split('-')[0] === selectedLocale?.split('-')[0],
    )
  )
}

export function useSyncLocale() {
  const dispatch = useAppDispatch()

  const {
    i18n: { resolvedLanguage },
  } = useTranslation()

  useEffect(() => {
    dispatch(UserThunks.update({ locale: resolvedLanguage }))
  }, [resolvedLanguage])
}
