import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import { DEFAULT_ACTIVE_OPACITY } from 'config/constants'
import { Button, ButtonProps, Modal, Spacer, Text } from 'components'
import { AppActions } from 'store/App'
import { useAppDispatch } from 'store/hooks'
import { AnalyticsEvents, useLogEventCallback } from 'util/analytics'
import { useDisclosure } from 'util/hooks'
import { SUPPORTED_LOCALES, SupportedLocale } from 'util/localization'

import { Flag } from './Flag'

type Props = {
  style?: ButtonProps['style']
}

export function LanguageSelector({ style }: Props) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const logEvent = useLogEventCallback()

  const dispatch = useAppDispatch()

  const onSelectLocale = (locale: string) => {
    dispatch(AppActions.setLocale(locale))
    logEvent(AnalyticsEvents.languageChanged(locale))
    onClose()
  }

  return (
    <>
      <Button
        onPress={onOpen}
        color={colors.veryLightGray}
        style={[styles.button, style]}
        textStyle={styles.buttonText}
        innerStyle={styles.buttonInner}
      >
        <View style={styles.valueContainer}>
          <Flag code={resolvedLanguage as SupportedLocale} />
          <Text font="bold" style={styles.label}>
            {t(`common.languages.locales.${resolvedLanguage}`)}
          </Text>
        </View>
      </Button>
      <Modal
        visible={isOpen}
        onRequestClose={onClose}
        style={styles.modal}
        contentContainerStyle={styles.container}
      >
        <Text
          id="common.languages.modal.title"
          size={24}
          align="center"
          font="bold"
          style={styles.title}
        />
        {SUPPORTED_LOCALES.map((locale) => {
          const isSelected = resolvedLanguage === locale
          return (
            <TouchableOpacity
              key={locale}
              style={[styles.item, isSelected ? styles.selectedItem : {}]}
              activeOpacity={DEFAULT_ACTIVE_OPACITY}
              onPress={() => {
                onSelectLocale(locale)
              }}
            >
              <View style={styles.valueContainer}>
                <Flag code={locale} />
                <Text
                  size={16}
                  font="medium"
                  style={[isSelected ? styles.selectedLabel : styles.label]}
                >
                  {t(`common.languages.locales.${locale}`)}
                </Text>
              </View>
              {isSelected && (
                <>
                  <Spacer />
                  <MaterialCommunityIcons
                    name="check-bold"
                    color={colors.main}
                    size={28}
                  />
                </>
              )}
            </TouchableOpacity>
          )
        })}

        {/*<TouchableOpacity
          style={[styles.item, { marginTop: 16, justifyContent: 'center' }]}
          activeOpacity={DEFAULT_ACTIVE_OPACITY}
          onPress={onClose}
        >
          <Text size={16} font="medium" align="center">
            {t(`common.close`)}
          </Text>
        </TouchableOpacity>*/}
      </Modal>
    </>
  )
}

const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
  },
  container: {
    width: 300,
    padding: 16,
  },
  title: {
    marginBottom: 8,
  },
  button: {
    alignSelf: 'stretch',
  },
  buttonInner: {
    flexGrow: 0,
    flexShrink: 0,
    paddingHorizontal: 24,
  },
  buttonText: { marginLeft: 0 },
  item: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 4,
    marginVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedItem: {
    backgroundColor: `${colors.main}33`,
  },
  selectedLabel: {
    color: colors.main,
    marginLeft: 8,
  },
  valueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginLeft: 8,
    marginTop: 2,
  },
})
