import { useMemo, useRef, useState } from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import colors from 'config/colors'
import { DEFAULT_ACTIVE_OPACITY } from 'config/constants'
import { Button, Modal, Text } from 'components'
import { SettingSelectors } from 'store/Settings'
import { useDisclosure } from 'util/hooks'

import type { InternalNote } from 'store/Orders/types'

type InternalNotesPopupProps = {
  isOpen: boolean
  onClose: () => void
  internalNotes: InternalNote[]
}

function InternalNotesPopup({
  isOpen,
  onClose,
  internalNotes,
}: InternalNotesPopupProps) {
  return (
    <Modal
      withCloseButton
      closeButtonColor="white"
      visible={isOpen}
      onRequestClose={onClose}
      contentContainerStyle={styles.internalNotesModalContent}
    >
      <View style={{ height: '100%' }}>
        <Text
          id={'overview.orderDetails.internalNotes.title'}
          style={styles.internalNotesModalTitle}
          color={'white'}
          font="bold"
          size={20}
        />
        <ScrollView style={styles.internalNotesModalScrollView}>
          {internalNotes.map(({ note }, index) => {
            return (
              <Text
                key={`${index}`}
                color={colors.darkRed}
                style={styles.internalNotesModalText}
                size={16}
              >
                {note}
              </Text>
            )
          })}
        </ScrollView>
      </View>
    </Modal>
  )
}

type InternalNotesProps = {
  internalNotes: InternalNote[]
}

function InternalNotes({ internalNotes }: InternalNotesProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const containerWidth = useRef(0)
  const fullTextWidth = useRef(Number.MAX_SAFE_INTEGER)

  const getShouldHideButton = () => {
    return fullTextWidth.current < containerWidth.current
  }

  const [isButtonHidden, setHideButton] = useState(getShouldHideButton)

  const onLayoutUpdate = () => {
    const shouldHideButton = getShouldHideButton()

    if (shouldHideButton !== isButtonHidden) {
      setHideButton(shouldHideButton)
    }
  }

  const notesMerged = useMemo(
    () => internalNotes.map((item) => item.note).join('; '),
    [internalNotes],
  )

  return (
    <>
      <TouchableOpacity
        activeOpacity={DEFAULT_ACTIVE_OPACITY}
        onPress={onOpen}
        style={styles.internalNotes}
        disabled={isButtonHidden}
      >
        <View
          key={notesMerged}
          style={styles.notesMergedContainer}
          onLayout={({ nativeEvent }) => {
            const { width } = nativeEvent.layout

            containerWidth.current = width
            onLayoutUpdate()
          }}
        >
          <View
            style={{
              position: 'absolute',
              maxWidth: null,
              opacity: 0,
            }}
          >
            <Text
              style={{
                position: 'absolute',
                maxWidth: null,
                opacity: 0,
              }}
              color={colors.darkRed}
              numberOfLines={1}
              onLayout={({ nativeEvent }) => {
                const { width } = nativeEvent.layout

                fullTextWidth.current = width
                onLayoutUpdate()
              }}
            >
              {notesMerged}
            </Text>
          </View>
          <Text color={colors.darkRed} numberOfLines={1}>
            {notesMerged}
          </Text>
        </View>
        {!isButtonHidden && (
          <Button
            titleId={'overview.orderDetails.internalNotes.buttonLabel'}
            variant="outline"
            innerColor={colors.darkRed}
            color="transparent"
            innerStyle={{
              paddingVertical: 4,
            }}
          />
        )}
      </TouchableOpacity>
      <InternalNotesPopup
        isOpen={isOpen}
        onClose={onClose}
        internalNotes={internalNotes}
      />
    </>
  )
}

type OrderNotesProps = {
  specialInstructions: string
  internalNotes: InternalNote[]
}

export function OrderNotes({
  specialInstructions,
  internalNotes,
}: OrderNotesProps) {
  const { shouldDisplayInternalNotes } = useSelector(
    SettingSelectors.selectOrderDetailsInterfaceSettings,
  )

  return (
    <>
      {!!specialInstructions && (
        <View style={styles.specialInstructions}>
          <Text color={colors.darkRed}>{specialInstructions}</Text>
        </View>
      )}
      {shouldDisplayInternalNotes && !isEmpty(internalNotes) && (
        <InternalNotes internalNotes={internalNotes} />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  specialInstructions: {
    backgroundColor: colors.veryLightRed,
    padding: 12,
  },
  internalNotes: {
    backgroundColor: colors.veryLightRed2,
    padding: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  notesMergedContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 4,
    overflow: 'hidden',
  },
  internalNotesModalContent: {
    marginHorizontal: 0,
    marginVertical: 0,
    backgroundColor: 'white',
    borderRadius: 0,
    width: '30%',
    maxWidth: 400,
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    height: '100%',
    justifyContent: 'flex-start',
  },
  internalNotesModalTitle: {
    padding: 12,
    backgroundColor: colors.veryLightRed2,
  },
  internalNotesModalScrollView: {
    marginVertical: 12,
    paddingHorizontal: 12,
  },
  internalNotesModalText: {
    marginBottom: 12,
  },
})
