import { StyleProp, View, ViewStyle } from 'react-native'
import _ from 'lodash'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import Text from 'components/Text'

import { OrderStartPreparationStatusInterfaces } from './constants'
import { styles } from './styles'

type OrderStartPreparationStatusBadgeProps = {
  status?: StartPreparationStatus
  style?: StyleProp<ViewStyle>
}

export const OrderStartPreparationStatusBadge = ({
  status,
  style,
}: OrderStartPreparationStatusBadgeProps) => {
  const statusInterface = OrderStartPreparationStatusInterfaces[status]

  if (!statusInterface) return null

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: statusInterface.color,
          paddingBottom: 3,
        },
        style,
      ]}
    >
      <View style={styles.content}>
        <MaterialCommunityIcons
          name={statusInterface.iconName}
          size={16}
          color="white"
        />
      </View>
      <View style={[styles.content]}>
        <Text
          color="white"
          font="bold"
          size={10}
          style={styles.label}
          id={statusInterface.translationKey}
        />
      </View>
    </View>
  )
}
