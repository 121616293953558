import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { AppSelectors } from 'store/App'
import { TAB_BARS, TAB_BARS_LIST } from 'store/App/constants'
import { createCtx } from 'util/createCtx'

const tabRoutes = [
  { key: TAB_BARS.new.value },
  { key: TAB_BARS.cooking.value },
  { key: TAB_BARS.scheduled.value },
  { key: TAB_BARS.prepared.value },
] as const

export type OverviewNavigationContext = {
  activeTabIndex: number
  navigationState: {
    routes: typeof tabRoutes
    index: number
  }
}

export type OverviewNavigationContextProviderProps = {
  children: React.ReactNode
}

const carouselContext = createCtx<OverviewNavigationContext>()
const Provider = carouselContext[1]
export const useOverviewNavigationContext: () => OverviewNavigationContext =
  carouselContext[0]

export const OverviewNavigationContextProvider = ({
  children,
}: OverviewNavigationContextProviderProps): React.ReactElement => {
  const selectedTabBar = useSelector(AppSelectors.selectCurrentTabBar)

  const contextValue = useMemo(() => {
    let tabIndex = TAB_BARS_LIST.findIndex(
      (tabBar) => tabBar.value === selectedTabBar,
    )

    tabIndex = tabIndex >= 0 ? tabIndex : 0

    const navigationState = { index: tabIndex, routes: tabRoutes }

    return {
      navigationState,
      activeTabIndex: tabIndex,
    }
  }, [selectedTabBar])

  return <Provider value={contextValue}>{children}</Provider>
}
