import { useMemo } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Ionicons } from '@expo/vector-icons'
import { useLinkProps } from '@react-navigation/native'

import colors from 'config/colors'
import { Text } from 'components'
import {
  ActionButtonItem,
  HasUnacceptChangesLabel,
} from 'modules/ChefScene/components/ActionButton'
import { ReprintButtonItem } from 'modules/ChefScene/components/ActionButton/ReprintButton'
import Addons from 'modules/ChefScene/components/Addons'
import { RouteNames } from 'navigation/linkingConfig'
import { NotificationsSelectors } from 'store/Notifications'
import {
  ItemPreparationStatuses,
  OrderInternalStatuses,
} from 'store/Orders/enums'
import { checkIfScheduledOrder } from 'store/Orders/utils'
import { SettingSelectors } from 'store/Settings'
import { UserSelectors } from 'store/User'
import { AnalyticsEvents, useLogEventCallback } from 'util/analytics'
import { useCurrencyString } from 'util/helpers/currency'

import type { Order, OrderItem } from 'store/Orders/types'
import type { IonIconName } from 'types/icons'

type OrderDetailStatusButtonProps = {
  order: Order
  item: OrderItem
  orderInternalStatus: OrderInternalStatuses
  hasUnacceptedNotifications?: boolean
}

const OrderDetailStatusButton = ({
  order,
  item,
  orderInternalStatus,
  hasUnacceptedNotifications,
}: OrderDetailStatusButtonProps) => {
  const orderDetailsInterfaceSettings = useSelector(
    SettingSelectors.selectOrderDetailsInterfaceSettings,
  )
  const featureFlags = useSelector(UserSelectors.selectFeatureFlags)

  const isScheduledFutureOrder = checkIfScheduledOrder(order)

  const logEvent = useLogEventCallback()

  const { color, iconName } = useMemo((): {
    color?: string
    iconName?: IonIconName
  } => {
    switch (item.status) {
      case ItemPreparationStatuses.BEING_PREPARED:
        return {
          color: '#e98400',
          iconName: 'flame',
        }
      case ItemPreparationStatuses.PREPARED:
      case ItemPreparationStatuses.COMPLETED:
        return {
          iconName: 'checkmark-circle',
          color: '#6db66b',
        }
      default:
        return {}
    }
  }, [item])

  const getStatusChangeCallback =
    (params?: any) => (statusToUpdate: ItemPreparationStatuses) => {
      logEvent(
        AnalyticsEvents.itemStatusChanged({
          isScheduled: isScheduledFutureOrder,
          screen: RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails,
          status: statusToUpdate,
          ...params,
        }),
      )
    }

  return (
    <View style={{ marginLeft: 8 }}>
      {orderInternalStatus ===
      OrderInternalStatuses.CANCELLED ? null : hasUnacceptedNotifications ? (
        <HasUnacceptChangesLabel />
      ) : orderDetailsInterfaceSettings.shouldDisplayActionButtonItems ? (
        !item.cookable ? null : item.status !==
          ItemPreparationStatuses.PREPARED ? (
          <ActionButtonItem
            includePrintButton
            order={order}
            item={item}
            isScheduled={isScheduledFutureOrder}
            onPressFinish={getStatusChangeCallback({ print: false })}
            onPressFinishAndPrint={getStatusChangeCallback({ print: true })}
          />
        ) : item.status === ItemPreparationStatuses.PREPARED &&
          featureFlags.display_finish_and_print_button ? (
          <ReprintButtonItem
            itemUUID={item.uuid}
            onPress={() => {
              logEvent(
                AnalyticsEvents.reprintButtonPressed({
                  screen:
                    RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails,
                }),
              )
            }}
          />
        ) : null
      ) : iconName ? (
        <Ionicons name={iconName} size={40} color={color} />
      ) : null}
    </View>
  )
}

type OrderDetailItemProps = {
  order: Order
  item: OrderItem
  index: number
  shouldDifferentiateLayout?: boolean
  orderInternalStatus: OrderInternalStatuses
}

export const OrderDetailItem = ({
  order,
  item,
  index,
  shouldDifferentiateLayout,
  orderInternalStatus,
}: OrderDetailItemProps) => {
  const logEvent = useLogEventCallback()
  const getCurrencyString = useCurrencyString()

  const { shouldDisplayItemPricing } = useSelector(
    SettingSelectors.selectOrderDetailsInterfaceSettings,
  )
  const hasUnacceptedNotifications = useSelector((state) =>
    // @ts-ignore
    NotificationsSelectors.selectOrderItemHasUnattended(state, item.uuid),
  )

  const navigationProps = useLinkProps({
    to: {
      screen: RouteNames.Home.Main.ItemDetails,
      params: {
        uuid: item.uuid,
      },
    },
  })

  const backgroundColor = hasUnacceptedNotifications
    ? colors.ui.notifications.unread
    : shouldDifferentiateLayout
    ? index % 2
      ? colors.alternateWhite
      : colors.white
    : colors.white

  return (
    <TouchableOpacity
      {...navigationProps}
      onPress={(event) => {
        navigationProps.onPress(event)

        logEvent(
          AnalyticsEvents.itemCellPressed({
            screen: RouteNames.Home.Main.Drawer.Tabs.Overview._,
          }),
        )
      }}
      activeOpacity={0.75}
      style={{
        padding: 12,
        flexDirection: 'row',
        backgroundColor,
        alignItems: 'flex-start',
      }}
    >
      <View style={{ justifyContent: 'center' }}>
        <Text font="medium" size={16} color={colors.text.dark}>
          {`${Number.isInteger(item.quantity) ? item.quantity : 1} -`}{' '}
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Text font="medium" size={16} color={colors.text.dark}>
            {item.name}
            {shouldDisplayItemPricing &&
              item.price != null &&
              ` - ${getCurrencyString(item.price)}`}
          </Text>
        </View>
        {item.addons && (
          <Addons addons={item.addons} addonsType={item.addons_type} />
        )}
        {!!item.special_instructions && (
          <View
            style={{
              marginTop: 10,
              backgroundColor: '#ffbec0',
              borderRadius: 5,
              padding: 5,
              flexDirection: 'row',
            }}
          >
            <Text numberOfLines={1} size={14}>
              {item.special_instructions}
            </Text>
          </View>
        )}
      </View>
      <OrderDetailStatusButton
        order={order}
        item={item}
        orderInternalStatus={orderInternalStatus}
        hasUnacceptedNotifications={hasUnacceptedNotifications}
      />
    </TouchableOpacity>
  )
}
