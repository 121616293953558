import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import usePrevious from 'react-use/lib/usePrevious'

import colors from 'config/colors'
import { HorizontalDivider, InputSpinner, withErrorBoundary } from 'components'
import Setting from 'modules/Settings/components/Setting'
import { RouteNames } from 'navigation/linkingConfig'
import { SettingActions, SettingSelectors } from 'store/Settings'
import { UserActions, UserSelectors } from 'store/User'
import { AnalyticsEvents, useLogEventCallback } from 'util/analytics'

import { Section, SectionContainer } from './components/Section'
import { SettingError } from './components/SettingError'

const ESTIMATED_PREPARATION_TIME_CONFIG = {
  MIN: 1,
  MAX: 999,
}

const CHANGE_DELAY_CONFIG = {
  MIN: 2,
  MAX: 10,
}

const BLINKING_THRESHOLD_CONFIG = {
  MIN: 1,
  MAX: 60,
}

const EstimatedPreparationTime = () => {
  const dispatch = useDispatch()
  const logEvent = useLogEventCallback()

  const deliveryEta = useSelector(UserSelectors.selectETA)

  const [value, setValue] = useState(deliveryEta)
  const valuePrev = usePrevious(value)

  useEffect(() => {
    if (value && valuePrev && value !== valuePrev) {
      dispatch(UserActions.updateEstimatedPreparationTime(value))

      logEvent(AnalyticsEvents.estimatedPreparationTimeSettingUpdate({ value }))
    }
  }, [value, valuePrev, dispatch])

  return (
    <SectionContainer>
      <Section
        titleKey={'settings.timing.estimatedPreparationTime.title'}
        subtitleKey={'settings.timing.estimatedPreparationTime.subtitle'}
      >
        <InputSpinner
          value={value}
          onChange={setValue}
          min={ESTIMATED_PREPARATION_TIME_CONFIG.MIN}
          max={ESTIMATED_PREPARATION_TIME_CONFIG.MAX}
          color={colors.main}
          size={70}
          iconSize={16}
          labelSize={15}
          labelKey={'common.minutes'}
        />
      </Section>
    </SectionContainer>
  )
}

const OrderStatusChangeDelay = () => {
  const dispatch = useDispatch()
  const logEvent = useLogEventCallback()

  const isItemCookDelayEnabled = useSelector(
    SettingSelectors.selectItemCookDelayEnabled,
  )
  const itemCookDelayInSeconds = useSelector(
    SettingSelectors.selectItemCookDelayInSeconds,
  )

  const onPressCheckbox = (value) => {
    dispatch(SettingActions.toggleItemCookDelayEnabled(value))

    if (value) {
      logEvent(
        AnalyticsEvents.delayBeforeStartSettingUpdate({
          value: itemCookDelayInSeconds,
        }),
      )
    } else {
      logEvent(AnalyticsEvents.delayBeforeStartSettingUpdate({ value: false }))
    }
  }

  const updateValue = (value) => {
    dispatch(SettingActions.setItemCookDelay(value))

    logEvent(AnalyticsEvents.delayBeforeStartSettingUpdate({ value }))
  }

  return (
    <SectionContainer>
      <Section
        titleKey="settings.timing.delayBeforeStart.title"
        subtitleKey="settings.timing.delayBeforeStart.subtitle"
        onPressCheckbox={onPressCheckbox}
        isChecked={isItemCookDelayEnabled}
      >
        <InputSpinner
          value={itemCookDelayInSeconds}
          onChange={updateValue}
          min={CHANGE_DELAY_CONFIG.MIN}
          max={CHANGE_DELAY_CONFIG.MAX}
          color={colors.main}
          size={70}
          disabled={!isItemCookDelayEnabled}
          iconSize={16}
          labelSize={15}
          labelKey={'common.seconds'}
        />
      </Section>
    </SectionContainer>
  )
}

const CountDownTimerEnable = () => {
  const dispatch = useDispatch()
  const logEvent = useLogEventCallback()

  const isCountDownTimerEnabled = useSelector(
    SettingSelectors.selectCountDownTimerEnable,
  )

  const updateValue = (value) => {
    dispatch(SettingActions.toggleCountDownTimer(value))

    logEvent(
      AnalyticsEvents.countDownBlinkingThresholdSettingUpdate({
        value,
      }),
    )
  }

  return (
    <>
      <Section
        titleKey="settings.timing.enableCountDown.title"
        subtitleKey="settings.timing.enableCountDown.subtitle"
        onPressCheckbox={updateValue}
        isChecked={isCountDownTimerEnabled}
      />
      <HorizontalDivider style={styles.divider} />
    </>
  )
}

const CountDownTimerBlinkingThreshold = () => {
  const dispatch = useDispatch()
  const logEvent = useLogEventCallback()

  const isCountDownTimerEnabled = useSelector(
    SettingSelectors.selectCountDownTimerEnable,
  )
  const isCountDownTimerBlinkingThreshold = useSelector(
    SettingSelectors.selectCountDownTimerBlinkingEnabled,
  )
  const countDownTimerBlinkingThreshold = useSelector(
    SettingSelectors.selectCountDownTimerBlinkingThreshold,
  )

  const updateValue = (value) => {
    dispatch(SettingActions.setCountDownTimerBlinkingThreshold(value))

    logEvent(AnalyticsEvents.countDownBlinkingThresholdSettingUpdate({ value }))
  }

  const onPressCheckbox = (value) => {
    dispatch(SettingActions.toggleCountDownBlinkingThresholdEnabled(value))

    if (value) {
      logEvent(
        AnalyticsEvents.countDownBlinkingThresholdSettingUpdate({
          value: countDownTimerBlinkingThreshold,
        }),
      )
    } else {
      logEvent(
        AnalyticsEvents.countDownBlinkingThresholdSettingUpdate({
          value: false,
        }),
      )
    }
  }

  return (
    <Section
      titleKey="settings.timing.countDownBlinkingThreshold.title"
      subtitleKey="settings.timing.countDownBlinkingThreshold.subtitle"
      onPressCheckbox={onPressCheckbox}
      isChecked={isCountDownTimerBlinkingThreshold}
      isDisabled={!isCountDownTimerEnabled}
    >
      <InputSpinner
        value={countDownTimerBlinkingThreshold}
        onChange={updateValue}
        min={BLINKING_THRESHOLD_CONFIG.MIN}
        max={BLINKING_THRESHOLD_CONFIG.MAX}
        color={colors.main}
        size={70}
        disabled={
          !isCountDownTimerEnabled || !isCountDownTimerBlinkingThreshold
        }
        iconSize={16}
        labelSize={15}
        labelKey={'common.minutes'}
      />
    </Section>
  )
}

const CountDownSection = () => {
  return (
    <SectionContainer>
      <CountDownTimerEnable />
      <CountDownTimerBlinkingThreshold />
    </SectionContainer>
  )
}

const Timing = () => {
  const { t } = useTranslation()

  const featureFlags = useSelector(UserSelectors.selectFeatureFlags)

  const {
    display_estimated_preparation_time: isPromisedTimeSettingEnabled,
    kds_enabled_traffic_light_system: isTrafficLightSystemEnabled,
    kds_enabled: isKdsEnabled,
  } = featureFlags

  return (
    <Setting title={t('settings.timing.title')} iconName="clock-outline">
      {isPromisedTimeSettingEnabled && <EstimatedPreparationTime />}
      <OrderStatusChangeDelay />
      {isTrafficLightSystemEnabled && isKdsEnabled && <CountDownSection />}
    </Setting>
  )
}

const styles = StyleSheet.create({
  divider: {
    marginVertical: 20,
  },
})

export default withErrorBoundary(Timing, {
  screen: RouteNames.Home.Settings.Timing,
  key: 'settings-timing',
  fallback: <SettingError />,
})
