import { Circle, Rect } from 'react-native-svg'

import colors from 'config/colors'

import { ContentLoader } from './ContentLoader'

const LoadingPlaceholder = (props) => {
  const { children } = props
  return (
    <ContentLoader
      foregroundColor={colors.main}
      style={{ position: 'absolute', zIndex: 100 }}
      backgroundColor={colors.lightBlue2}
    >
      {children}
    </ContentLoader>
  )
}

interface CircleLoadingPlaceholderProps {
  size: number
}

const CircleLoadingPlaceholder = (props: CircleLoadingPlaceholderProps) => {
  const { size } = props
  return (
    <LoadingPlaceholder>
      <Circle r={size / 2} cx={size / 2} cy={size / 2} />
    </LoadingPlaceholder>
  )
}

interface RectLoadingPlaceholderProps {
  height: number
  width: number
  borderRadius?: number
}

const RectLoadingPlaceholder = (props: RectLoadingPlaceholderProps) => {
  const { height, width, borderRadius } = props
  return (
    <LoadingPlaceholder>
      <Rect x={0} y={0} rx={borderRadius} width={width} height={height} />
    </LoadingPlaceholder>
  )
}

export { CircleLoadingPlaceholder, RectLoadingPlaceholder }
