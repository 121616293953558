import { call, put, takeLatest } from 'redux-saga/effects'

import API from 'API/modules/Categories'

// import AppActions from 'store//App/actions'

import { InternalErrors } from '../types'
import SettingActions from './actions'

function* fetch() {
  try {
    const { items, error } = yield call(API.fetch)
    if (error) {
      yield put(SettingActions.fetchFailure({ error }))
      // yield put(
      //   AppActions.dispatchMessageForUser({
      //     type: error,
      //     additionalInfo: 'get categories',
      //   }),
      // )
    } else {
      yield put(SettingActions.fetchSuccess({ items }))
    }
  } catch (error) {
    yield put(
      SettingActions.fetchFailure({
        error: InternalErrors.SAGA_ERROR,
        meta: { error },
      }),
    )
  }
}

function* rootSaga() {
  yield takeLatest(SettingActions.fetch, fetch)
}

export default rootSaga
