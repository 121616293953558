import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { groupBy } from 'lodash'
import { FlashList } from '@shopify/flash-list'

import colors from 'config/colors'
import { DEFAULT_ACTIVE_OPACITY } from 'config/constants'
import { PDFView } from 'components/PDFView'
import { useNotificationCallbacks } from 'modules/Notifications/hooks'
import { NotificationsSelectors } from 'store/Notifications'
import { NotificationPriority } from 'store/Notifications/enums'
import { CompanyNotification } from 'store/Notifications/types'
import { AnalyticsEvents, useLogEventCallback } from 'util/analytics'

import { ListHeader } from '../ListHeader'
import { NoNotifications } from '../Placeholder'
import { PriorityIcon } from '../PriorityIcon'
import { Notification } from './Notification'

type ListHeaderProps = {
  criticalPriorityCount?: number
  highPriorityCount?: number
  normalPriorityCount?: number
  lowPriorityCount?: number
  currentFilter: NotificationPriority
  onFilterChange: (priority: NotificationPriority) => void
}

function ListHeaderComponent({
  criticalPriorityCount = 0,
  highPriorityCount = 0,
  normalPriorityCount = 0,
  lowPriorityCount = 0,
  currentFilter,
  onFilterChange,
}: ListHeaderProps) {
  const { t } = useTranslation()
  const logEvent = useLogEventCallback()

  const items = useMemo(() => {
    return [
      {
        priority: NotificationPriority.CRITICAL,
        count: criticalPriorityCount,
      },
      {
        priority: NotificationPriority.HIGH,
        count: highPriorityCount,
      },
      {
        priority: NotificationPriority.NORMAL,
        count: normalPriorityCount,
      },
      {
        priority: NotificationPriority.LOW,
        count: lowPriorityCount,
      },
    ]
  }, [
    criticalPriorityCount,
    highPriorityCount,
    normalPriorityCount,
    lowPriorityCount,
  ])

  return (
    <ListHeader
      title={t('notifications.company.title')}
      color={colors.ui.notifications.company}
    >
      <>
        {items.map(({ priority, count }) => {
          const isSelected = !currentFilter || currentFilter === priority

          return (
            <TouchableOpacity
              key={priority}
              activeOpacity={DEFAULT_ACTIVE_OPACITY}
              onPress={() => {
                const newValue = currentFilter === priority ? null : priority

                onFilterChange(newValue)

                logEvent(
                  AnalyticsEvents.notificationCompanyChangeFilter({
                    priority: newValue,
                  }),
                )
              }}
            >
              <PriorityIcon
                count={count}
                priority={priority}
                isSelected={isSelected}
              />
            </TouchableOpacity>
          )
        })}
      </>
    </ListHeader>
  )
}

type Props = {
  onDismiss: () => void
}

export function CompanyNotifications({}: Props) {
  const [selectedFileUri, selectFile] = useState<string | null>()
  const [filter, setFilter] = useState<NotificationPriority | null>()

  const notifications = useSelector(
    NotificationsSelectors.selectCompanyNotifications,
  )

  const { onViewableItemsChanged } = useNotificationCallbacks(notifications)

  const groupedNotifications = useMemo(() => {
    return groupBy(
      notifications,
      (notification) => notification.priority,
    ) as Record<NotificationPriority, CompanyNotification[]>
  }, [notifications])

  if (!notifications.length) return null

  const criticalPriorityCount =
    groupedNotifications[NotificationPriority.CRITICAL]?.length
  const highPriorityCount =
    groupedNotifications[NotificationPriority.HIGH]?.length
  const normalPriorityCount =
    groupedNotifications[NotificationPriority.NORMAL]?.length
  const lowPriorityCount =
    groupedNotifications[NotificationPriority.LOW]?.length

  return (
    <View style={{ width: 400 }}>
      <ListHeaderComponent
        criticalPriorityCount={criticalPriorityCount}
        highPriorityCount={highPriorityCount}
        normalPriorityCount={normalPriorityCount}
        lowPriorityCount={lowPriorityCount}
        currentFilter={filter}
        onFilterChange={(priority) =>
          setFilter((current) => (current !== priority ? priority : null))
        }
      />
      <FlashList
        estimatedItemSize={100}
        data={filter ? groupedNotifications[filter] ?? [] : notifications}
        contentContainerStyle={{
          paddingHorizontal: 8,
        }}
        renderItem={({ item }) => (
          <Notification
            notification={item}
            isReadOnly={Boolean(item.file_url)}
            onPressFile={selectFile}
          />
        )}
        keyExtractor={(item) => item.uuid}
        ListEmptyComponent={NoNotifications}
        onViewableItemsChanged={onViewableItemsChanged}
      />
      <PDFView
        isOpen={Boolean(selectedFileUri)}
        onClose={() => selectFile(null)}
        uri={selectedFileUri}
      />
    </View>
  )
}
