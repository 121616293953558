import axios, { AxiosError } from 'axios'
import { t } from 'i18next'

export interface ExtendedError {
  error: Error | AxiosError
  isAxiosError: boolean
  isCancelled: boolean
  genericUserFacingMessage: string
}

enum ErrorClarifications {
  NON_AXIOS_ERROR = 'ERR_NA1',
  CONFIGURATION_ERROR = 'ERR_C1',
  BAD_REQUEST_ERROR = 'ERR_BR1',
  UNIDENTIFIED_ERROR = 'ERR_U1',
}

export class RequestError implements ExtendedError {
  public readonly error: ExtendedError['error']
  public readonly isAxiosError: boolean
  public readonly isCancelled: boolean

  constructor(error: AxiosError) {
    this.error = error
    this.isAxiosError = axios.isAxiosError(error)
    this.isCancelled = axios.isCancel(error)
  }

  get genericUserFacingMessage(): string {
    let errorMessage = ''

    if (!this.isAxiosError) {
      errorMessage = t('errors.genericWithClarification', {
        value: ErrorClarifications.NON_AXIOS_ERROR,
      })
    } else {
      const error = this.error as AxiosError

      switch (error?.code) {
        case AxiosError.ECONNABORTED:
        case AxiosError.ETIMEDOUT:
        case AxiosError.ERR_NETWORK: {
          errorMessage = t('errors.connection')
          break
        }
        case AxiosError.ERR_BAD_OPTION:
        case AxiosError.ERR_BAD_OPTION_VALUE:
        case AxiosError.ERR_DEPRECATED:
        case AxiosError.ERR_NOT_SUPPORT:
        case AxiosError.ERR_INVALID_URL: {
          errorMessage = t('errors.genericWithClarification', {
            value: ErrorClarifications.CONFIGURATION_ERROR,
          })
          break
        }
        case AxiosError.ERR_FR_TOO_MANY_REDIRECTS:
        case AxiosError.ERR_BAD_RESPONSE: {
          errorMessage = t('errors.serverError')
          break
        }
        case AxiosError.ERR_BAD_REQUEST: {
          errorMessage = t('errors.genericWithClarification', {
            value: ErrorClarifications.BAD_REQUEST_ERROR,
          })
          break
        }
        case AxiosError.ERR_CANCELED: {
          errorMessage = t('errors.cancelledByUser')
          break
        }
        default: {
          errorMessage = t('errors.genericWithClarification', {
            value: ErrorClarifications.UNIDENTIFIED_ERROR,
          })
          break
        }
      }
    }

    return errorMessage
  }
}
