import { SupportedLocale } from 'util/localization'

import { SvgFr } from './Flags/fr'
import { SvgUs } from './Flags/us'

const FLAGS: Record<SupportedLocale, React.FC> = {
  'en-US': SvgUs,
  'fr-CA': SvgFr,
}

type Props = {
  code: SupportedLocale
}

export function Flag({ code }: Props) {
  const Flag = FLAGS[code]

  if (!Flag) return

  return <Flag />
}
