import {
  Checkbox as RNPCheckBox,
  RadioButton as RNPRadioButton,
} from 'react-native-paper'

import colors from 'config/colors'

interface Props {
  value?: any
  isChecked: boolean
  onPress: (currentValue: Props['value']) => void
  isDisabled?: boolean
  children?: React.ReactNode
}

export const Checkbox = (props: Props) => {
  const { isChecked, onPress, isDisabled } = props
  return (
    <RNPCheckBox.Android
      status={isChecked ? 'checked' : 'unchecked'}
      onPress={() => onPress(!isChecked)}
      color={colors.main}
      disabled={isDisabled}
    />
  )
}

export const RadioButton = (props: Props) => {
  const { value, onPress, isDisabled, isChecked } = props

  return (
    <RNPRadioButton.Android
      value={value}
      status={isChecked ? 'checked' : 'unchecked'}
      onPress={() => onPress(value)}
      color={colors.main}
      disabled={isDisabled}
    />
  )
}
