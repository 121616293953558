import { ReactNode } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import Color from 'color'

import colors from 'config/colors'
import { Text } from 'components'
import AcceptButton from 'modules/Notifications/components/AcceptButton'
import {
  getLabelKeyForNotificationType,
  useGetTimeLabel,
  useNotificationLinkProps,
} from 'modules/Notifications/helpers'
import { AppNotification } from 'store/Notifications/types'

interface Props {
  notification: AppNotification
  children: ReactNode
  navigationProps?: ReturnType<typeof useNotificationLinkProps>
  isLast?: boolean
  onPressAccept?: () => void
  onPress?: () => void
}

const NotificationContainer = (props: Props) => {
  const {
    notification,
    children,
    navigationProps,
    isLast,
    onPressAccept,
    onPress,
  } = props

  const getTimeLabel = useGetTimeLabel()

  return (
    <TouchableOpacity
      disabled={!navigationProps}
      {...(navigationProps ? navigationProps : {})}
      onPress={(event) => {
        navigationProps?.onPress && navigationProps.onPress(event)

        onPress && onPress()
      }}
      style={{
        backgroundColor: notification.read_at
          ? 'white'
          : Color('#3bc3ed').fade(0.8).hexa(),
        borderBottomWidth: isLast ? 0 : StyleSheet.hairlineWidth,
        borderBottomColor: '#efefef',
        paddingVertical: 8,
        paddingHorizontal: 12,
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
        <View style={{ marginRight: 20 }}>
          <Text size={14} font={'normal'} color={'#9b9b9b'}>
            {getTimeLabel(notification.created_at)}
          </Text>
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            id={getLabelKeyForNotificationType(notification.notification_type)}
            size={14}
            color={colors.text.dark}
          />
        </View>
        <AcceptButton
          notificationUUID={notification.uuid}
          acceptedAt={notification.accepted_at}
          onPress={onPressAccept}
        />
      </View>
      {!!children && (
        <View
          style={{
            marginLeft: 15,
            paddingTop: 5,
            paddingLeft: 10,
            borderLeftWidth: 5,
            borderLeftColor: '#d9d9d9',
          }}
        >
          {children}
        </View>
      )}
    </TouchableOpacity>
  )
}

export default NotificationContainer
