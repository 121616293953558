import i18n from 'i18next'
import { intersection, sortBy, uniq } from 'lodash'
import { createSelector } from '@reduxjs/toolkit'

import { ORDER_KINDS } from 'config/constants'
import { DeviceSelectors } from 'store/Device'
import UserSelectors from 'store/User/selectors'
import { getLabelKeyForOrderKind } from 'util/helpers'

import type { SettingState } from 'store/Settings/types'

const selectSlice = (state: any) => state.categories as SettingState

const selectAllCategories = createSelector(selectSlice, (slice) =>
  sortBy(slice.allCategories, (category) => category && category.toLowerCase()),
)

const selectUserSelectedCategories = createSelector(selectSlice, (slice) =>
  intersection(slice.allCategories, slice.selectedCategories),
)

// BE doesn't guarantee that screens default categories actually intersect with all categories, so we better sanitize them
const selectScreenCategories = createSelector(
  [selectAllCategories, DeviceSelectors.selectScreenCategories],
  (allCategories, deviceCategories) => intersection(allCategories, deviceCategories),
)

const selectSelectedCategories = createSelector(
  [selectUserSelectedCategories, selectScreenCategories],
  (userSelected, screenSelected) => uniq([...userSelected, ...screenSelected]),
)

const selectIsSelectedAllCategories = createSelector(
  selectSlice,
  (slice) => slice.isSelectedAllCategories,
)

const selectSelectedCategoriesCount = createSelector(
  [selectSlice, selectSelectedCategories],
  (slice, selectedCategories) =>
    slice.isSelectedAllCategories ? slice.allCategories.length : selectedCategories.length,
)

// does it need to be a selector?
const selectAllOrderKinds = createSelector([UserSelectors.selectFeatureFlags], (featureFlags) =>
  sortBy(
    featureFlags.kds_displayed_order_kinds
      ? ORDER_KINDS.filter((orderKind) => featureFlags.kds_displayed_order_kinds[orderKind])
      : ORDER_KINDS,
    (orderKind) => i18n.t(getLabelKeyForOrderKind(orderKind), { lng: 'en' }).toLowerCase(),
  ),
)

const selectUserSelectedOrderKinds = createSelector(
  [selectSlice, selectAllOrderKinds],
  (slice, orderKinds) => intersection(orderKinds, slice.selectedOrderKinds),
)

const selectSelectedOrderKinds = createSelector(
  [selectUserSelectedOrderKinds, DeviceSelectors.selectScreenOrderKinds],
  (userSelected, screenSelected) => uniq([...userSelected, ...screenSelected]),
)

const selectIsSelectedAllOrderKinds = createSelector(
  selectSlice,
  (slice) => slice.isSelectedAllOrderKinds,
)

const selectSelectedOrderKindsCount = createSelector(
  [selectSlice, selectAllOrderKinds, selectSelectedOrderKinds],
  (slice, orderKinds, selectedOrderKinds) =>
    slice.isSelectedAllOrderKinds ? orderKinds.length : selectedOrderKinds.length,
)

const selectItemCookDelayEnabled = createSelector(
  selectSlice,
  (slice) => slice.isItemCookDelayEnabled,
)

const selectItemCookDelayInSeconds = createSelector(
  selectSlice,
  (slice) => slice.itemCookDelayInSeconds,
)

const selectShouldLoopSound = createSelector(selectSlice, (slice) => slice.loopNotificationSound)

const selectOrdersSortingOrder = createSelector(selectSlice, (slice) => slice.ordersSortingOrder)

const selectCountDownTimerEnable = createSelector(
  selectSlice,
  (slice) => slice.isCountDownTimerEnabled,
)

const selectCountDownTimerBlinkingEnabled = createSelector(
  selectSlice,
  (slice) => slice.isCountDownTimerBlinkingThresholdEnabled,
)

const selectCountDownTimerBlinkingThreshold = createSelector(
  selectSlice,
  (slice) => slice.countDownTimerBlinkingThreshold,
)

const selectItemListInterfaceSettings = createSelector(
  selectSlice,
  (slice) => slice.interface.itemList,
)

const selectItemDetailsInterfaceSettings = createSelector(
  selectSlice,
  (slice) => slice.interface.itemDetails,
)

const selectOrderListInterfaceSettings = createSelector(
  selectSlice,
  (slice) => slice.interface.orderList,
)

const selectOrderDetailsInterfaceSettings = createSelector(
  selectSlice,
  (slice) => slice.interface.orderDetails,
)

export default {
  selectAllCategories,
  selectScreenCategories,
  selectUserSelectedCategories,
  selectSelectedCategories,
  selectIsSelectedAllCategories,
  selectSelectedCategoriesCount,

  selectAllOrderKinds,
  selectUserSelectedOrderKinds,
  selectSelectedOrderKinds,
  selectIsSelectedAllOrderKinds,
  selectSelectedOrderKindsCount,

  selectItemCookDelayEnabled,
  selectItemCookDelayInSeconds,
  selectShouldLoopSound,
  selectOrdersSortingOrder,
  selectCountDownTimerEnable,
  selectCountDownTimerBlinkingEnabled,
  selectCountDownTimerBlinkingThreshold,

  selectItemListInterfaceSettings,
  selectItemDetailsInterfaceSettings,
  selectOrderListInterfaceSettings,
  selectOrderDetailsInterfaceSettings,
}
