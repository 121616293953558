import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigation, useNavigationState } from '@react-navigation/native'

import { RouteNames } from 'navigation/linkingConfig'
import { AppSelectors } from 'store/App'
import { OrderSelectors } from 'store/Orders'
import { getRouteName } from 'util/helpers'

export function useSetTitleEffect() {
  const { t } = useTranslation()

  const routeName = useNavigationState(getRouteName)
  const navigation = useNavigation()

  const selectedTabBarValue = useSelector(AppSelectors.selectCurrentTabBar)

  const newItemsCount = useSelector(OrderSelectors.selectNotPreparedItemsCount)
  const cookingItemsCount = useSelector(
    OrderSelectors.selectBeingPreparedItemsCount,
  )
  const preparedItemsCount = useSelector(
    OrderSelectors.selectPreparedItemsCount,
  )
  const scheduleItemsCount = useSelector(
    OrderSelectors.selectScheduledItemsCount,
  )

  useEffect(() => {
    if (routeName !== RouteNames.Home.Main.Drawer.Tabs.Chef._) return

    navigation.setOptions({
      title: t('sideBar.tabBarSection.chef', {
        count: newItemsCount,
        count2: cookingItemsCount,
      }),
    })
    // switch (selectedTabBarValue) {
    //   case TAB_BARS.newAndCooking.value:
    //     navigation.setOptions({
    //       title: I18n.t(TAB_BARS.newAndCooking.labelKey, {
    //         count: newItemsCount,
    //         count2: cookingItemsCount,
    //       }),
    //     })
    //     break
    //   case TAB_BARS.scheduled.value:
    //     navigation.setOptions({
    //       title: I18n.t(TAB_BARS.scheduled.labelKey, {
    //         count: preparedItemsCount,
    //       }),
    //     })
    //     break
    //   case TAB_BARS.prepared.value:
    //     navigation.setOptions({
    //       title: I18n.t(TAB_BARS.prepared.labelKey, {
    //         count: scheduleItemsCount,
    //       }),
    //     })
    //     break
    // }
  }, [
    navigation,
    routeName,
    selectedTabBarValue,
    newItemsCount,
    cookingItemsCount,
    preparedItemsCount,
    scheduleItemsCount,
  ])
}
