import { Image, StyleSheet } from 'react-native'

import assets from 'assets'

export const CaptainLogo = () => {
  return (
    <Image
      source={assets.icons.logo}
      style={styles.container}
      resizeMode="contain"
    />
  )
}

const styles = StyleSheet.create({
  container: {
    width: 200,
    height: 100,
  },
})
