import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import colors from 'config/colors'
import { Link, Text } from 'components'
import { AuthLayout } from 'modules/Auth/components/AuthLayout'
import { RouteNames } from 'navigation/linkingConfig'

import { LoginForm } from './components'

export const LoginScreen = () => {
  const { t } = useTranslation()
  return (
    <AuthLayout>
      <Text
        id="authScreen.login.title"
        font="black"
        size={40}
        color={colors.lightBlue}
        align={'center'}
      />
      <Text
        id="authScreen.login.subtitle"
        style={styles.subtitle}
        size={16}
        align={'center'}
        color={colors.gray}
      />
      <LoginForm />
      <Link to={RouteNames.Auth.ForgotPassword} textStyle={styles.link}>
        {t('authScreen.forgotPassword.linkLabel')}
      </Link>
    </AuthLayout>
  )
}

const styles = StyleSheet.create({
  subtitle: {
    marginTop: 8,
  },
  link: {
    textAlign: 'center',
  },
})
