import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { StyleSheet, TextInput, View } from 'react-native'
import { zodResolver } from '@hookform/resolvers/zod'

import { convertHostNameToBaseUrl, DEFAULT_BASE_URL, setBaseURL } from 'API'
import colors from 'config/colors'
import { Button, FormControl, Input } from 'components'
import {
  EnvironmentServer,
  SelectEnvironmentModal,
} from 'modules/Auth/components/SelectEnvironmentModal'
import { AuthThunks } from 'store/Auth'
import { useAppDispatch } from 'store/hooks'
import { UserActions } from 'store/User'

import { FormValue, schema } from './schema'

const defaultValues = {
  email: '',
  password: '',
}

type Props = {
  onLoginSuccess: (data: FormValue) => void
}

export const LoginForm = () => {
  const [authError, setAuthError] = useState<string | null>(null)
  const [environmentServerData, setEnvironmentServerData] = useState<
    EnvironmentServer[]
  >([])
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()

  const passwordInputRef = useRef<TextInput>()

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues,
    resolver: zodResolver(schema),
  })

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting, errors },
  } = methods

  const onLogin = async (data: FormValue, environment?: EnvironmentServer) => {
    const { payload } = await dispatch(
      AuthThunks.login({ ...data, environment }),
    )

    if (payload.error) {
      setAuthError(payload.error)
    }

    if (payload.response) {
      const { account } = payload.response

      if (account) {
        dispatch(UserActions.selectAccount(account))
      }
    }

    setIsLoading(false)
  }

  const onEnvironmentCheck = async (environment: EnvironmentServer) => {
    setBaseURL(convertHostNameToBaseUrl(environment.hostname))

    setEnvironmentServerData([])

    await onLogin(getValues(), environment)
  }

  const onSubmit = handleSubmit(async (data: FormValue) => {
    if (isSubmitting) {
      return
    }

    const { payload } = await dispatch(AuthThunks.fetchEnvironments(data))

    if (!payload?.found_environments) {
      setAuthError('UNKNOWN')

      return
    }

    if (payload.found_environments.length > 1) {
      setEnvironmentServerData(payload.found_environments)

      setIsLoading(true)

      return
    }

    const environment = payload.found_environments[0]

    setBaseURL(
      environment
        ? convertHostNameToBaseUrl(environment.hostname)
        : DEFAULT_BASE_URL,
    )

    await onLogin(data, environment)
  })

  return (
    <>
      <View style={styles.container}>
        <FormControl
          control={control}
          name="email"
          defaultValue={defaultValues.email}
          labelKey="common.form.email.label"
          errorKey={errors.email?.message}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <Input
                autoCapitalize="none"
                keyboardType="email-address"
                placeholderKey="common.form.email.placeholder"
                textContentType="username"
                value={value}
                onChangeText={onChange}
                onBlur={onBlur}
                onSubmitEditing={() => {
                  passwordInputRef.current?.focus()
                  onBlur()
                }}
                blurOnSubmit={false}
              />
            )
          }}
        />
        <FormControl
          control={control}
          name="password"
          defaultValue={defaultValues.password}
          labelKey="common.form.password.label"
          errorKey={
            errors.password?.message || (authError ? `errors.${authError}` : '')
          }
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <Input
                ref={passwordInputRef}
                autoCapitalize="none"
                secureTextEntry
                placeholderKey="common.form.password.placeholder"
                textContentType="password"
                value={value}
                onChangeText={onChange}
                onBlur={onBlur}
                onSubmitEditing={onSubmit}
              />
            )
          }}
        />
        <Button
          onPress={() => onSubmit()}
          style={{ backgroundColor: colors.main, marginTop: 20 }}
          innerStyle={{ paddingVertical: 12, paddingHorizontal: 12 }}
          isFetching={isSubmitting || isLoading}
          titleId="authScreen.login.submitButton.label"
        />
      </View>
      <SelectEnvironmentModal
        isVisible={environmentServerData.length > 1}
        onConfirm={onEnvironmentCheck}
        onClose={() => {
          if (environmentServerData.length > 1) {
            setEnvironmentServerData([])
            setIsLoading(false)
          }
        }}
        data={environmentServerData}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
})
