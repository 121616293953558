import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withSpring,
} from 'react-native-reanimated'
import { useSelector } from 'react-redux'
import useInterval from 'react-use/lib/useInterval'
import { DateTime } from 'luxon'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import { DEFAULT_SPRING_CONFIG } from 'config/constants'
import { Text } from 'components'
import { AppSelectors } from 'store/App'
import { useFormatDateTime } from 'util/helpers'

const TIME_TO_APPEAR = 1000 * 60 * 2 // 2 minutes

const BACKGROUND_COLOR_SPRING_CONFIG = {
  ...DEFAULT_SPRING_CONFIG,
  mass: 4,
  damping: 30,
}

export const NoConnectionBanner = () => {
  const { t } = useTranslation()
  const isShowRef = useRef(false)

  const updatedAt = useSelector(AppSelectors.selectUpdatedAt)

  const sharedAnimatedValue = useSharedValue(0)
  const sharedAnimatedBackgroundValue = useSharedValue(colors.error)

  const intervalCallback = () => {
    const lastUpdatedAtDuration = DateTime.now().diff(
      DateTime.fromJSDate(updatedAt),
    ).milliseconds

    if (lastUpdatedAtDuration > TIME_TO_APPEAR && !isShowRef.current) {
      sharedAnimatedValue.value = withSpring(1, DEFAULT_SPRING_CONFIG)
      sharedAnimatedBackgroundValue.value = withRepeat(
        withSequence(
          withSpring(colors.error, BACKGROUND_COLOR_SPRING_CONFIG),
          withSpring(colors.red, BACKGROUND_COLOR_SPRING_CONFIG),
        ),
        -1,
        true,
      )

      isShowRef.current = true
      return
    }

    if (lastUpdatedAtDuration <= TIME_TO_APPEAR && isShowRef.current) {
      sharedAnimatedValue.value = withSpring(0, DEFAULT_SPRING_CONFIG)
      sharedAnimatedBackgroundValue.value = withSpring(
        colors.error,
        BACKGROUND_COLOR_SPRING_CONFIG,
      )

      isShowRef.current = false
      return
    }
  }

  useInterval(intervalCallback, 1000 * 60)

  const animatedContainerStyle = useAnimatedStyle(() => {
    const opacity = sharedAnimatedValue.value
    const backgroundColor = sharedAnimatedBackgroundValue.value

    return {
      opacity,
      transform: [{ translateX: -350 }, { translateY: (1 - opacity) * -180 }],
      backgroundColor,
    }
  }, [])

  useEffect(() => {
    if (isShowRef.current) {
      intervalCallback()
    }
  }, [updatedAt])

  const formatDateTime = useFormatDateTime()

  return (
    <Animated.View style={[styles.container, animatedContainerStyle]}>
      <View style={styles.icon}>
        <MaterialCommunityIcons name="web-off" size={48} color="white" />
      </View>
      <View style={styles.textContainer}>
        <Text color="white" font="bold" size={18} style={{ flexWrap: 'wrap' }}>
          {t('components.noConnectionBanner.title')}
        </Text>
        <Text color="white" size={16}>
          {t('components.noConnectionBanner.message', {
            dateTime: updatedAt
              ? formatDateTime(updatedAt, DateTime.TIME_SIMPLE)
              : 'N/A',
          })}
        </Text>
      </View>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    flexDirection: 'row',
    top: 40,
    left: '50%',
    width: 700,
    zIndex: 100,
    borderRadius: 12,
    padding: 16,
    alignItems: 'center',
  },
  icon: {
    padding: 12,
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 16,
  },
})
