import { createAsyncThunk } from '@reduxjs/toolkit'

import API from 'API/modules/Orders'

import Actions from './actions'
import type { Order, OrderItem } from './types'

const updateItemStatus = createAsyncThunk(
  'ORDERS/UPDATE_ITEM_STATUS',
  async (
    payload: { id: OrderItem['uuid']; status: OrderItem['status'] },
    { signal, rejectWithValue },
  ) => {
    const { id, status } = payload

    if (!signal.aborted) {
      try {
        return await API.updateItem(id, { status })
      } catch (error) {
        return rejectWithValue({ errorMessage: error.genericUserFacingMessage })
      }
    }
  },
)

const updateItemStatuses = createAsyncThunk(
  'ORDERS/UPDATE_ITEM_STATUSES',
  async (
    payload: {
      uuids: OrderItem['uuid'][]
      status: OrderItem['status']
    },
    { signal, rejectWithValue },
  ) => {
    const { uuids, status } = payload

    if (!signal.aborted) {
      try {
        return await API.updateItemStatuses(uuids, status)
      } catch (error) {
        return rejectWithValue({ errorMessage: error.genericUserFacingMessage })
      }
    }
  },
)

const printItem = createAsyncThunk(
  Actions.printItem.toString(),
  async ({ id }: { id: OrderItem['uuid'] }, { signal, rejectWithValue }) => {
    API.printItem(id, {
      'axios-retry': {
        retries: 3,
      },
    }).catch((e) => console.error(Actions.printItem.toString(), e))
  },
)

const printOrder = createAsyncThunk(
  Actions.printOrder.toString(),
  async (payload: { id: Order['uuid']; itemUUIDs: OrderItem['uuid'][] }) => {
    const { id, itemUUIDs } = payload

    API.printOrder(id, itemUUIDs, {
      'axios-retry': {
        retries: 3,
      },
    }).catch((e) => console.error(Actions.printOrder.toString(), e))
  },
)

export default {
  updateItemStatus,
  updateItemStatuses,
  printItem,
  printOrder,
}
