import { StyleSheet } from 'react-native'

import { Text } from 'components'

type ContentProps = {
  titleKey: string
  descriptionKey: string
}

export function Content({ titleKey, descriptionKey }: ContentProps) {
  return (
    <>
      <Text id={titleKey} font="bold" style={styles.title} />
      <Text id={descriptionKey} style={styles.description} />
    </>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    textAlign: 'center',
    marginVertical: 12,
  },
  description: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 12,
  },
})
