import AsyncStorage from '@react-native-async-storage/async-storage'

import { initialSettingsState } from 'store/Settings/reducer'

export const settingsMigrations = {
  1: async (settingsState) => {
    try {
      const appAsJSON = await AsyncStorage.getItem('persist:app')
      const app = JSON.parse(appAsJSON)

      return {
        ...settingsState,
        itemCookDelayInSeconds: app.itemCookDelayInSeconds
          ? JSON.parse(app.itemCookDelayInSeconds)
          : settingsState.itemCookDelayInSeconds,
        ordersSortingOrder: app.ordersSortingOrder
          ? JSON.parse(app.ordersSortingOrder)
          : settingsState.ordersSortingOrder,
        loopNotificationSound: app.loopNotificationSound
          ? JSON.parse(app.loopNotificationSound)
          : settingsState.loopNotificationSound,
      }
    } catch (e) {}

    return settingsState
  },
  2: async (settingsState) => {
    try {
      const categoriesAsJSON = await AsyncStorage.getItem('persist:categories')
      const categories = JSON.parse(categoriesAsJSON)

      return {
        ...settingsState,
        allCategories: categories.all
          ? JSON.parse(categories.all)
          : settingsState.allCategories,
        selectedCategories: categories.selected
          ? JSON.parse(categories.shouldPreventScreenLock)
          : settingsState.selectedCategories,
      }
    } catch (e) {}

    return settingsState
  },
  4: async (settingsState) => {
    try {
      return {
        ...settingsState,
        selectedOrderKinds: settingsState.selectedOrderSources,
        isSelectedAllOrderKinds: settingsState.isSelectedAllOrderSources,
        selectedOrderSources: undefined,
        isSelectedAllOrderSources: undefined,
      }
    } catch (e) {}

    return settingsState
  },
  7: async (settingsState) => {
    try {
      return {
        ...settingsState,
        interface: {
          ...initialSettingsState.interface,
          ...settingsState?.interface,
        },
      }
    } catch (e) {}

    return settingsState
  },
}
