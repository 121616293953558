import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import API from 'API/modules/Orders'

// import AppActions from '../App/actions'
import { InternalErrors } from '../types'
import OrderActions from './actions'
import OrderSelectors from './selectors'
import OrderThunks from './thunks'

function* fetch() {
  try {
    const { error, orders, orderItems } = yield call(API.fetch)
    if (error) {
      yield put(OrderActions.fetchFailure({ error }))
      // yield put(
      //   AppActions.dispatchMessageForUser({
      //     type: error,
      //     additionalInfo: 'get orders',
      //   }),
      // )
    } else {
      yield put(OrderActions.fetchSuccess({ orders, orderItems }))
    }
  } catch (error) {
    yield put(
      OrderActions.fetchFailure({
        error: InternalErrors.SAGA_ERROR,
        meta: { error },
      }),
    )
  }
}

function* fetchById({ payload }) {
  try {
    const { id } = payload
    const { error, orders, orderItems } = yield call(API.fetchById, id)
    if (error) {
      OrderActions.fetchByIdFailure({ error })
      // yield put(
      //   AppActions.dispatchMessageForUser({
      //     type: error,
      //     additionalInfo: `get order - ${id}`,
      //   }),
      // )
    } else {
      yield put(OrderActions.fetchByIdSuccess({ order: orders[0], orderItems }))
    }
  } catch (error) {
    yield put(
      OrderActions.fetchByIdFailure({
        error: InternalErrors.SAGA_ERROR,
        meta: { error },
      }),
    )
  }
}

function* updateOrdersItemStatuses({ payload }) {
  try {
    const { id, status } = payload
    const order = yield select((state) => OrderSelectors.selectById(state, id))
    const ids = order.item_uuids

    const thunkEffects = yield all(
      ids.map((itemId) =>
        call(OrderThunks.updateItemStatus, { id: itemId, status }),
      ),
    )
    yield all(thunkEffects.map((effect) => put(effect)))

    yield put(OrderActions.updateOrdersItemStatusesSuccess())
    // todo
    yield put(OrderActions.fetchById({ id }))
  } catch (error) {
    yield put(
      OrderActions.updateOrdersItemStatusesFailure({
        error: InternalErrors.SAGA_ERROR,
        meta: { error },
      }),
    )
  }
}

function* rootSaga() {
  yield takeLatest(OrderActions.fetch, fetch)
  yield takeLatest(OrderActions.fetchById, fetchById)

  yield takeEvery(
    OrderActions.updateOrdersItemStatuses,
    updateOrdersItemStatuses,
  )
}

export default rootSaga
