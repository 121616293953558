import 'expo-dev-client'
import 'react-native-gesture-handler'

import { LogBox, Platform } from 'react-native'
import * as Expo from 'expo'
import * as SplashScreen from 'expo-splash-screen'

import { init as initSentry } from 'util/sentry'

import App from './src/App'

initSentry()

SplashScreen.preventAutoHideAsync().catch(console.warn)

if (Platform.OS !== 'web') {
  LogBox.ignoreLogs(['Require cycle:'])
}

Expo.registerRootComponent(App)
