import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useLinkProps } from '@react-navigation/native'

import { SortingOrders } from 'config/constants'
import { Picker } from 'components'
import { RouteNames } from 'navigation/linkingConfig'
import { AppSelectors } from 'store/App'
import { SettingActions, SettingSelectors } from 'store/Settings'
import { AnalyticsEvents, useLogEventCallback } from 'util/analytics'

import { NavigationButtonSecondary } from './NavigationButtons'
import { commonStyles } from './styles'

const SORT_OPTIONS = [
  {
    label: 'sideBar.filterSection.optionByOrderAge',
    value: SortingOrders.BY_PLACED_AT_ASC,
  },
  {
    label: 'sideBar.filterSection.optionByPromisedTime',
    value: SortingOrders.BY_PROMISED_TIME_AT_ASC,
  },
  {
    label: 'sideBar.filterSection.optionByPriorityTime',
    value: SortingOrders.BY_START_PREPARATION_STATUS_DESC,
    featureFlagKey: 'kds_enabled_traffic_light_system',
  },
]

function Ordering() {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const logEvent = useLogEventCallback()

  const isDrawerMinimized = useSelector(AppSelectors.selectIsDrawerMinimized)

  const sortingOrder = useSelector(SettingSelectors.selectOrdersSortingOrder)

  const setSelectedSortingOrder = (order) => {
    dispatch(SettingActions.setOrdersSorting(order))
    logEvent(AnalyticsEvents.orderSortingUpdated({ sortBy: order }))
  }

  return (
    <Picker
      value={sortingOrder}
      onChange={setSelectedSortingOrder}
      options={SORT_OPTIONS}
      renderTrigger={({ onOpen, selectedOption }) => (
        <NavigationButtonSecondary
          onPress={onOpen}
          iconName="sort"
          isMinimized={isDrawerMinimized}
          label={t(
            !isDrawerMinimized
              ? selectedOption.label
              : `${selectedOption.label}_short`,
          )}
        />
      )}
    />
  )
}

function Categories() {
  const { t } = useTranslation()

  const isDrawerMinimized = useSelector(AppSelectors.selectIsDrawerMinimized)

  const categories = useSelector(SettingSelectors.selectAllCategories)
  const selectedCategories = useSelector(
    SettingSelectors.selectSelectedCategories,
  )
  const isSelectedAllCategories = useSelector(
    SettingSelectors.selectIsSelectedAllCategories,
  )

  const label = useMemo(() => {
    let translationKey = 'sideBar.filterSection.categories'

    if (isDrawerMinimized) {
      translationKey = 'sideBar.filterSection.categories_short'
    }

    return t(translationKey, {
      count: isSelectedAllCategories
        ? categories.length
        : selectedCategories.length,
      total: categories.length,
    })
  }, [
    categories,
    selectedCategories,
    isSelectedAllCategories,
    isDrawerMinimized,
  ])

  const navigate = useLinkProps({
    to: {
      screen: RouteNames.Home.Settings._,
      params: {
        screen: RouteNames.Home.Settings.Categories,
      },
    },
  })

  return (
    <NavigationButtonSecondary
      iconName="food"
      label={label}
      onPress={navigate.onPress}
      isMinimized={isDrawerMinimized}
    />
  )
}

function OrderKinds() {
  const { t } = useTranslation()

  const isDrawerMinimized = useSelector(AppSelectors.selectIsDrawerMinimized)

  const orderKinds = useSelector(SettingSelectors.selectAllOrderKinds)
  const selectedOrderKinds = useSelector(
    SettingSelectors.selectSelectedOrderKinds,
  )
  const isSelectedAllOrderKinds = useSelector(
    SettingSelectors.selectIsSelectedAllOrderKinds,
  )

  const label = useMemo(() => {
    let translationKey = 'sideBar.filterSection.orderKinds'

    if (isDrawerMinimized) {
      translationKey = 'sideBar.filterSection.orderKinds_short'
    }

    return t(translationKey, {
      count: isSelectedAllOrderKinds
        ? orderKinds.length
        : selectedOrderKinds.length,
      total: orderKinds.length,
    })
  }, [selectedOrderKinds, isSelectedAllOrderKinds, isDrawerMinimized])

  const navigate = useLinkProps({
    to: {
      screen: RouteNames.Home.Settings._,
      params: {
        screen: RouteNames.Home.Settings.OrderKinds,
      },
    },
  })

  return (
    <NavigationButtonSecondary
      iconName="silverware-variant"
      label={label}
      onPress={navigate.onPress}
      isMinimized={isDrawerMinimized}
    />
  )
}

export function FilterSection() {
  return (
    <View style={commonStyles.container}>
      <OrderKinds />
      <Categories />
      <Ordering />
    </View>
  )
}
