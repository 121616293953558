import colors from 'config/colors'

import { MaterialCommunityIconName } from 'types/icons'

type TabBar = {
  labelKey: string
  value: string
  iconName: MaterialCommunityIconName
  color?: string
}

export type TabBarKey = 'new' | 'cooking' | 'scheduled' | 'prepared'

export const TAB_BARS: Record<TabBarKey, TabBar> = {
  new: {
    labelKey: 'sideBar.tabBarSection.new',
    value: 'new',
    iconName: 'new-box',
    color: colors.ui.statuses.active,
  },
  cooking: {
    labelKey: 'sideBar.tabBarSection.cooking',
    value: 'cooking',
    iconName: 'fire',
    color: colors.ui.statuses.cooking,
  },
  scheduled: {
    labelKey: 'sideBar.tabBarSection.scheduled',
    value: 'scheduled',
    iconName: 'calendar-blank',
    color: colors.ui.statuses.scheduled,
  },
  prepared: {
    labelKey: 'sideBar.tabBarSection.prepared',
    value: 'prepared',
    iconName: 'text-box-check',
    color: colors.ui.statuses.prepared,
  },
} as const

export const TAB_BARS_LIST = Object.values(TAB_BARS)
