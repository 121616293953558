import { useSelector } from 'react-redux'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { SelectStoreScreen } from 'modules/Auth/screens/SelectStore'
import { DeviceNavigator } from 'navigation/DeviceNavigator'

import { UserSelectors } from '../store/User'
import { AuthenticationNavigator } from './AuthenticationNavigator'
import { RouteNames } from './linkingConfig'

const Stack = createNativeStackNavigator()

const RootRouter = () => {
  const isAuthenticated = useSelector(UserSelectors.selectIsAuthenticated)
  const isAuthenticationInProgress = useSelector(
    UserSelectors.selectIsAuthenticationInProgress,
  )
  const account = useSelector(UserSelectors.selectAccount)

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {isAuthenticated ? (
        <Stack.Screen name={RouteNames.Home._} component={DeviceNavigator} />
      ) : isAuthenticationInProgress && !account ? (
        <Stack.Screen
          name={RouteNames.SelectStore._}
          component={SelectStoreScreen}
        />
      ) : (
        <Stack.Screen
          name={RouteNames.Auth._}
          component={AuthenticationNavigator}
          options={{
            animationTypeForReplace: !isAuthenticated ? 'pop' : 'push',
          }}
        />
      )}
    </Stack.Navigator>
  )
}

export default RootRouter
