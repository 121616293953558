import { useEffect } from 'react'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'
import { useNavigationState } from '@react-navigation/native'

import { DEFAULT_SPRING_CONFIG } from 'config/constants'
import { getRouteName } from 'util/helpers'

import Active from './Active'
import { useSetTitleEffect } from './utils'

// import { useMemo } from 'react'
// import { useWindowDimensions } from 'react-native'
// import { SceneMap, TabView } from 'react-native-tab-view'
// import { useDispatch, useSelector } from 'react-redux'

// import colors from 'config/colors'
// import { AppActions, AppSelectors } from 'store/App'
// import { TAB_BARS, TAB_BARS_LIST } from 'store/App/constants'
// import Completed from './Completed'
// import Scheduled from './Scheduled'

export const ChefScene = () => {
  const routeName = useNavigationState(getRouteName)

  const sharedOpacity = useSharedValue(0)

  useEffect(() => {
    if (routeName === 'chef') {
      sharedOpacity.value = withSpring(1, DEFAULT_SPRING_CONFIG)
    } else {
      sharedOpacity.value = withSpring(0, DEFAULT_SPRING_CONFIG)
    }
  }, [routeName])

  useSetTitleEffect()

  const animatedContainerStyle = useAnimatedStyle(() => {
    return {
      opacity: sharedOpacity.value,
    }
  }, [])

  return (
    <Animated.View style={[animatedContainerStyle, { flex: 1 }]}>
      <Active />
    </Animated.View>
  )
}

// const tabRoutes = [
//   { key: TAB_BARS.newAndCooking.value },
//   { key: TAB_BARS.scheduled.value },
//   { key: TAB_BARS.prepared.value },
// ]

// const renderScene = SceneMap({
//   [TAB_BARS.newAndCooking.value]: Active,
//   [TAB_BARS.scheduled.value]: Scheduled,
//   [TAB_BARS.prepared.value]: Completed,
// })

// const Navigator = () => {
//   const dispatch = useDispatch()
//   const { width } = useWindowDimensions()

//   const selectedTabBar = useSelector(AppSelectors.selectCurrentTabBar)

//   const navigationState = useMemo(() => {
//     const tabIndex =
//       TAB_BARS_LIST.findIndex((tabBar) => tabBar.value === selectedTabBar) ?? 0

//     const navigationState = { index: tabIndex, routes: tabRoutes }

//     return navigationState
//   }, [selectedTabBar])

//   const onIndexChange = (index: number) => {
//     dispatch(AppActions.setTabBar(TAB_BARS_LIST[index]))
//   }

//   useSetTitleEffect()

//   return (
//     <TabView
//       navigationState={navigationState}
//       onIndexChange={onIndexChange}
//       renderScene={renderScene}
//       initialLayout={{
//         width,
//       }}
//       renderTabBar={() => null}
//       style={{
//         backgroundColor: colors.lightBlue2,
//       }}
//     />
//   )
// }
