import { createSelector } from '@reduxjs/toolkit'

import type { UserState } from 'store/User/types'

const selectSlice = (state: any) => state.user as UserState

const selectEmail = createSelector(selectSlice, (slice) => slice.email)

const selectToken = createSelector(selectSlice, (slice) => slice.token)
const selectTmpToken = createSelector(selectSlice, (slice) => slice._token)

const selectIsAuthenticated = createSelector(selectToken, (token) => !!token)
const selectIsAuthenticationInProgress = createSelector(selectTmpToken, (token) => !!token)

const selectAccounts = createSelector(selectSlice, (slice) => slice.accounts)
const selectTmpAccounts = createSelector(selectSlice, (slice) => slice._accounts)
const selectAccountUUID = createSelector(selectSlice, (slice) => slice.selectedAccountUUID)
const selectAccount = createSelector(
  selectAccounts,
  selectAccountUUID,
  (accounts, accountUuid) => accounts[accountUuid],
)

const selectAvailableScreens = createSelector(selectAccount, (account) => account.screens ?? [])

const selectHasAvailableScreens = createSelector(
  selectAvailableScreens,
  (screens) => screens.length > 0,
)

const selectHubName = createSelector(selectAccount, (account) => account.name)

const selectCompany = createSelector(selectAccount, (account) => account.company)
const selectBusiness = createSelector(selectAccount, (account) => account.business)
const selectAddress = createSelector(selectAccount, (account) => account.address)
const selectLogo = createSelector(selectAccount, (account) => account.logo_url)
const selectCurrencyCode = createSelector(selectAccount, (account) => account.currency_code)

const selectPusherConfig = createSelector(selectAccount, (account) => {
  if (!account) return null

  const { pusher_channel, pusher_key, pusher_cluster } = account

  if (!pusher_channel || !pusher_key || !pusher_cluster) return null

  return {
    channel: pusher_channel,
    key: pusher_key,
    cluster: pusher_cluster,
  }
})

const selectETA = createSelector(selectAccount, (account) => account.estimated_preparation_time)

const selectFeatureFlags = createSelector(selectAccount, (account) => account.feature_flags)

const selectInterfaceSettings = createSelector(
  selectAccount,
  (account) => account.interface_settings,
)

export default {
  selectAccount,
  selectAccounts,
  selectAvailableScreens,
  selectHasAvailableScreens,
  selectTmpAccounts,
  selectAccountUUID,
  selectIsAuthenticated,
  selectIsAuthenticationInProgress,
  selectEmail,
  selectToken,
  selectHubName,
  selectCompany,
  selectBusiness,
  selectPusherConfig,
  selectETA,
  selectFeatureFlags,
  selectAddress,
  selectLogo,
  selectInterfaceSettings,
  selectCurrencyCode,
}
