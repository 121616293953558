import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu } from 'react-native-paper'

import { FontFamilies } from 'components/Text'

interface Props {
  options: {
    label: string
    value: any
  }[]
  onChange: (value: any) => void
  value: any
  renderTrigger: (props: {
    onOpen: () => void
    selectedOption: { value: any; label: string }
  }) => React.ReactNode
}

const Picker = ({ options, onChange, value, renderTrigger }: Props) => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  const onOpen = () => setVisible(true)

  const onClose = () => setVisible(false)

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === value)
  }, [options, value])

  return (
    <Menu
      visible={visible}
      onDismiss={onClose}
      anchor={renderTrigger({ onOpen, selectedOption })}
      anchorPosition={'top'}
    >
      {options.map((option) => (
        <Menu.Item
          onPress={() => {
            onChange(option.value)
            onClose()
          }}
          title={t(option.label)}
          titleStyle={{ fontFamily: FontFamilies.bold, fontSize: 14 }}
          key={option.value}
        />
      ))}
    </Menu>
  )
}

export default Picker
