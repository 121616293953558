import { useTranslation } from 'react-i18next'
import { StyleProp, TextStyle, TouchableOpacity } from 'react-native'
import { useLinkProps } from '@react-navigation/native'

import Colors from 'config/colors'
import { Text } from 'components'
import { RouteNames } from 'navigation/linkingConfig'

type RemakeOrderDescriptionLinkProps = {
  orderUUID: string
  description: string
  prefix?: string
  color?: string
  style?: StyleProp<TextStyle>
  fontSize?: number
}

export const RemakeOrderDescriptionLink = (
  props: RemakeOrderDescriptionLinkProps,
) => {
  const { orderUUID } = props

  const linkProps = useLinkProps({
    to: {
      screen: RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails,
      params: { uuid: orderUUID },
    },
  })

  return (
    <TouchableOpacity {...linkProps} activeOpacity={0.75}>
      <RemakeOrderDescription {...props} />
    </TouchableOpacity>
  )
}

type RemakeOrderDescriptionProps = Omit<
  RemakeOrderDescriptionLinkProps,
  'orderUUID'
>

export const RemakeOrderDescription = ({
  prefix,
  color = Colors.orange,
  description,
  style,
  fontSize = 14,
}: RemakeOrderDescriptionProps) => {
  const { t } = useTranslation()

  return (
    <Text
      font="bold"
      color={color}
      size={fontSize}
      style={style}
      align={'center'}
    >
      ({prefix ?? t('overview.orderDetails.remakeFor')}#{description})
    </Text>
  )
}
