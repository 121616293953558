import { createSelector } from '@reduxjs/toolkit'

import type { AppState } from './reducer'

const select = (state) => state.app as AppState
const selectEnvironment = createSelector(select, (app) => app.environment)
const selectLocale = createSelector(select, (app) => app.locale)
const selectUpdatedAt = createSelector(select, (slice) => slice.updatedAt)
const selectCurrentTabBar = createSelector(select, (slice) => slice.selectedTabBar)
const selectIsDrawerMinimized = createSelector(select, (slice) => slice.isDrawerMinimized)
const selectErrors = createSelector(select, (slice) => slice.errors)

export default {
  selectUpdatedAt,
  selectCurrentTabBar,
  selectEnvironment,
  selectIsDrawerMinimized,
  selectLocale,
  selectErrors,
}
