import { createAction } from '@reduxjs/toolkit'

import type { Category } from 'store/Settings/types'
import type { DefaultFailActionPayload } from '../types'

const fetch = createAction('CATEGORIES/FETCH/REQUEST')
const fetchSuccess = createAction<{ items: Category[] }>('CATEGORIES/FETCH/SUCCESS')
const fetchFailure = createAction<DefaultFailActionPayload>('CATEGORIES/FETCH/FAILURE')

export default {
  fetch,
  fetchSuccess,
  fetchFailure,
}
