import { createCtx } from 'util/createCtx'
import { useDisclosure, UseDisclosureReturn } from 'util/hooks'

type NotificationStateContext = UseDisclosureReturn

const [useNotificationState, NotificationStateProvider] =
  createCtx<NotificationStateContext>()

type Props = {
  children?: React.ReactNode
}

function NotificationStateContextProvider({ children }: Props) {
  const disclosure = useDisclosure()

  return (
    <NotificationStateProvider value={disclosure}>
      {children}
    </NotificationStateProvider>
  )
}

export {
  NotificationStateContext,
  NotificationStateContextProvider,
  useNotificationState,
}
