import { View } from 'react-native'

import colors from 'config/colors'
import Text, { Props as TextProps } from 'components/Text'

type Props = Partial<TextProps>

export function HasUnacceptChangesLabel({ style, ...props }: Props) {
  return (
    <View
      style={[
        {
          justifyContent: 'center',
          alignItems: 'center',
          width: 84,
          height: 84,
          marginHorizontal: 4,
        },
        style,
      ]}
    >
      <Text
        font={'bold'}
        align={'center'}
        color={colors.text.dark}
        id={'notifications.hasUnacceptedChanges'}
        size={12}
        {...props}
      />
    </View>
  )
}
