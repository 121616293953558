import { useTranslation } from 'react-i18next'
import { useWindowDimensions, View } from 'react-native'

import colors from 'config/colors'
import { Modal } from 'components/Modal'
import Text from 'components/Text'

import { styles } from './styles'
import { Props } from './types'

export function Container({
  isOpen,
  onClose,
  children,
  totalPage,
}: Omit<Props, 'uri'> & { children: React.ReactNode; totalPage?: number }) {
  const { t } = useTranslation()
  const { height } = useWindowDimensions()

  return (
    <Modal
      visible={isOpen}
      onRequestClose={onClose}
      withCloseButton
      contentContainerStyle={{
        height: height - 40,
      }}
    >
      <View style={styles.container}>{children}</View>
      {totalPage > 1 && (
        <Text
          style={{ position: 'absolute', bottom: 4, right: 4 }}
          color={colors.lightGray}
        >
          {t('common.totalPage', { value: totalPage })}
        </Text>
      )}
    </Modal>
  )
}
