import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import useUpdateEffect from 'react-use/lib/useUpdateEffect'

import { useAppDispatch } from 'store/hooks'
import { OrderActions } from 'store/Orders'
import { selectIsFetching } from 'store/util/functions'

export function usePullToRefresh(isFetching) {
  const [isPulled, setPulled] = useState(true)

  useUpdateEffect(() => {
    if (isPulled && !isFetching) {
      setPulled(false)
    }
  }, [isFetching, isPulled])

  const onPulled = useCallback(() => {
    setPulled(true)
  }, [setPulled])

  return [isPulled && isFetching, onPulled]
}

export function usePullToRefreshOrder() {
  const dispatch = useAppDispatch()

  const isFetching = useSelector((state) =>
    selectIsFetching(state, OrderActions.fetch),
  )
  const [isPulled, onPulled] = usePullToRefresh(isFetching)
  const onRefresh = () => {
    dispatch(OrderActions.fetch())
    onPulled()
  }

  return [isPulled, onRefresh]
}
