import { useEffect } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { ErrorText, Modal, withErrorBoundary } from 'components'
import { RouteNames } from 'navigation/linkingConfig'
import { AppSelectors } from 'store/App'
import { useAppDispatch } from 'store/hooks'
import { NotificationActions } from 'store/Notifications'
import { AnalyticsEvents, useLogEventCallback } from 'util/analytics'

import { CompanyNotifications } from './CompanyNotifications'
import { OrderNotifications } from './OrderNotifications'

export const HEADER_BUTTON_SIZE = 30

type Props = {
  onDismiss: () => void
}

const NotificationPopupContent = (props: Props) => {
  const { onDismiss } = props

  return (
    <View style={{ flexDirection: 'row', height: '100%' }}>
      <OrderNotifications onDismiss={onDismiss} />
      <CompanyNotifications onDismiss={onDismiss} />
    </View>
  )
}

const NotificationPopupContentWithErrorBoundary = withErrorBoundary(
  NotificationPopupContent,
  {
    screen: RouteNames.Home.Main._,
    key: 'header-notifications-popup',
    fallback: <ErrorText id="notifications.error" />,
  },
)

export const NotificationPopup = (
  props: Props & {
    left?: number
    isVisible: boolean
  },
) => {
  const { isVisible, onDismiss, left } = props

  const isDrawerMinimized = useSelector(AppSelectors.selectIsDrawerMinimized)

  const dispatch = useAppDispatch()

  const logEvent = useLogEventCallback()

  useEffect(() => {
    if (isVisible) {
      dispatch(NotificationActions.fetch())
      logEvent(AnalyticsEvents.notificationsViewEntered())
    }
  }, [dispatch, isVisible])

  return (
    <Modal
      visible={isVisible}
      onRequestClose={onDismiss}
      contentContainerStyle={{
        marginHorizontal: 0,
        marginVertical: 0,
        backgroundColor: 'white',
        borderRadius: 0,
        position: 'absolute',
        ...(left
          ? { left: left + HEADER_BUTTON_SIZE + (isDrawerMinimized ? 24 : 10) }
          : {}),
        top: 0,
        height: '100%',
      }}
    >
      <NotificationPopupContentWithErrorBoundary
        {...props}
        onDismiss={onDismiss}
      />
    </Modal>
  )
}
