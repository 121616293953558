import { TabView } from 'react-native-tab-view'

import colorsConfig from 'config/colors'
import { renderScene } from 'modules/OverviewScene/Drawer/scene'

import { SelectedOrderContext } from './context'

const initialLayout = { width: 400 }

export const Drawer = ({
  state,
  descriptors,
  navigationState,
  onIndexChange,
}) => {
  const routeKey = state.history[state.index].key
  const selectedUUID = descriptors[routeKey].route.params?.uuid

  return (
    <SelectedOrderContext.Provider value={selectedUUID}>
      <TabView
        navigationState={navigationState}
        renderScene={renderScene}
        onIndexChange={onIndexChange}
        initialLayout={initialLayout}
        renderTabBar={() => null}
        style={{
          backgroundColor: colorsConfig.lightBlue2,
        }}
      />
    </SelectedOrderContext.Provider>
  )
}
